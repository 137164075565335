import React from 'react';
import Input from "../Input/Input"

const FormElement = (props) => {
    const { element, formData, errors, confirm } = props;
    return element.hidden ? '' : <React.Fragment>
        <Input
            label={element.label}
            type={element.type}
            placeholder={element.placeholder}
            name={element.name}
            value={formData[element.name]}
            error={errors[element.name]}
            onChange={props.onChange}
            options={element.options}
            checked={element.checked}
            isSearchable={element.isSearchable}
            disabled={element.disabled || confirm}
            mandatory={element.mandatory}
            formType={'drawer'}
            info={element.info}
            heading={element.heading}
        />
    </React.Fragment>
}

export default FormElement;