import React, {createContext,useState, useEffect} from 'react';
import Firebase from './helpers/Firebase';
import {userService} from './services/userService';
import { userActions } from './redux/actions/userActions';
export const AuthContext= createContext({userPresent:false,user:null})
export default function FirebaseAuthContext(props){
    
   
    let [state,changeState] = useState({
        userDataPresent:false,
        
        user:null,
        listener:null
    })

    useEffect(()=>{
        if(state.listener==null){
        changeState({...state,listener:Firebase.auth.onAuthStateChanged((user)=>{
           if(user){
                changeState(oldState=>({...oldState,userDataPresent:true,user:user}));
           } else {
            changeState(oldState=>({...oldState,userDataPresent:true,user:null}));
           }
           userActions.setEmailVerified(user ? user.emailVerified : false);
        })});
        
    }
    return ()=>{
      if(state.listener)
        state.listener()
    }
    
    },[])
  
  
    return (
        <AuthContext.Provider value={state}>
            {props.children}
        </AuthContext.Provider>
    )
}