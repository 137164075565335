import React from "react";
import './CheckBox.css';
import WhiteTick from '../../../images/WhiteTick.svg';
import Checkbox from '@material-ui/core/Checkbox';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

  const theme = createMuiTheme({
    palette: {
      primary: { 
          main : '#048041'
      },
      secondary : {
        main : '#048041'
      },
      text:{
          secondary: '#535765'
      }
    }
  });

const CheckBox = (props) => {
    return <ThemeProvider theme={theme}><Checkbox defaultChecked={false} checked={props.checked} onChange={props.onChange}/></ThemeProvider>
}

export default CheckBox;

    // return (
    //     <div
    //         className={`check-box ${props.checked ? ' check-box-checked' : ' check-box-unchecked'}`}
    //         onClick={props.onClick}>
    //     <img className={`check-mark`} src={WhiteTick} alt='check mark'/>
    //     </div>
    // );