import React from 'react';
import Switch from "react-switch";
import './Switch.css';
class SwitchExample extends React.Component {
    render() {
      const { label, name, onChange, checked } = this.props;
      return (
        <div className="switch-container">
          <div className="label-container"><p>{label}</p></div>
          <div className="switch-box">
            <Switch 
            onChange={(isChecked)=>{onChange(name, isChecked)}} 
            checked={checked} 
            checkedIcon={false}
            onHandleColor="#FFFFFF"
            onColor="#048041"
            uncheckedIcon={false}
            />
            <div className={`switch-value ${checked ? 'switch-checked' : 'switch-unchecked'}`}>{checked ? 'Yes' : 'No'}</div>
          </div>
        </div>
      );
    }
  }

  export default SwitchExample;