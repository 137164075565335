import React from 'react';
import './PasswordInput.css';
import EyeOpen from '../../../images/EyeOpen.svg';
import EyeClose from '../../../images/EyeClose.svg';
class PasswordInput extends React.Component{
constructor(props){
    super(props);
    this.state = {
        shown : false
    }
}

onEyeClick = ()=>{
    const { shown } = this.state;
    this.setState({ shown : !shown });
}

render(){
    const { shown } = this.state;
    const { value, className, placeholder,onKeyDown,onChange, name } = this.props;
    return <div className={className}>
            <input 
            name={name} 
            onKeyDown={onKeyDown} 
            onChange={(e)=>{
                const { name, value} = e.target;
                onChange(name, value);
            }}
            className='pass-input' placeholder={placeholder} type={`${shown ? 'text' : 'password'}`} value={value}/>
            <img className='eye-icon' onClick={this.onEyeClick} src={shown ? EyeClose : EyeOpen} alt='show password'/>
        </div>
}

}

export default PasswordInput;