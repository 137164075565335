import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../../redux/actions/userActions';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../firebaseAuthContext';
import FrontendLayout from '../../Common/FrontendLayout';
import MaintenanceIcon from '../../../images/MaintenanceIcon.svg';
import Button from '../../Common/Button/Button';
import './MaintenancePage.css';

class MaintenancePage extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      forgotPasswordModalOpen: false
    }
  }



  render() {
    return <FrontendLayout wrapperClass={'notfound-wrapper'} showHeader={true}><div className="notfound-container">
      {/* <div className="logo"/> */}
      <img src={MaintenanceIcon} alt='broken link'/>
      <h2>We are temporarily unavailable</h2>
      <p>We are currently performing scheduled maintenance and will be back soon.</p>
      <p>We apologize for any inconvenience</p>
      <p>In case of emergencies, you can email us at <a href="mailto:support@esbprogram.ca">support@esbprogram.ca</a></p>
    </div></FrontendLayout>
  }
}

function mapState(state) {
    const { loggingIn, errors, email, password, passwordResetting, passwordReset, passwordResetError  } = state.authentication;
    return { loggingIn, errors, email, password, passwordResetting, passwordReset, passwordResetError };
}

const actionCreators = {
  login: userActions.login,
  setError: userActions.setLoginError,
  setField: userActions.setLoginField,
  sendPasswordReset: userActions.passwordReset,
  clearPasswordReset: userActions.clearPasswordReset
}

export default connect(mapState, actionCreators)(MaintenancePage);
