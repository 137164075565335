
import InternalHeader from './InternalHeader/InternalHeader';
import Footer from './Footer/Footer';

function InternalLayout(props) {
    let showFooter = true;
    if(typeof props.showFooter !== 'undefined'){ showFooter = props.showFooter; }
    return(
       <div>
          <InternalHeader history={props.history}/>
          <div className="inner-content">{ props.children }</div>
          {showFooter ? <Footer /> : null }
       </div>
    );
}

export default InternalLayout;