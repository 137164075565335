import {userActionTypes} from '../actions/userActionTypes';

const initialState = { userEmailVerified:false, errors : {}};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userActionTypes.SET_EMAIL_VERIFIED:
      return {...state,
        userEmailVerified: action.isVerified
      };
    case userActionTypes.LOGIN_SET_FIELD:
      return {...state,
        [action.fieldName] : action.fieldVal,
        errors : {
          ...state.errors,
          [action.fieldName] : null
        }
      };
    case userActionTypes.LOGIN_SET_ERROR:
      return {...state,
        errors: action.errors
      };
    case userActionTypes.LOGIN_REQUEST:
      return {...state,
        loggingIn: true,
        user: action.user
      };
    case userActionTypes.LOGIN_SUCCESS:
      return {...state,
        loggedIn: true
      };
    case userActionTypes.LOGIN_FAILURE:
      return {...state,
        errors: {...state.errors, ...action.errors}
      };
    case userActionTypes.GET_USER_DETAILS_SUCCESS:
      return {...state,
        userInfo : action.userDetails
      };
    case userActionTypes.CHANGE_PASSWORD_REQUEST:
      return {...state,
        passwordChanging : true
    }; 
    case userActionTypes.CHANGE_PASSWORD_SUCCESS:
      return {...state,
        passwordChanging : false,
        passwordChanged: true
    }; 
    case userActionTypes.CHANGE_PASSWORD_FAILURE:
      return {...state,
        passwordChanging : false,
        passwordChanged : false,
        changePasswordError : action.error
    }; 
    case userActionTypes.CHANGE_PASSWORD_CLEAR:
      return {...state,
        passwordChanging : false,
        passwordChanged : false,
        changePasswordError: undefined
    };  
    case userActionTypes.LOGIN_FORM_RESET_CLEAR:
      return {...state,
        email : '',
        password : '',
        errors:  {}
    };    
    case userActionTypes.PASSWORD_RESET_REQUEST:
      return {...state,
        passwordResetting : true
    }; 
    case userActionTypes.PASSWORD_RESET_SUCCESS:
      return {...state,
        passwordReset : true,
        passwordResetting:false
    };    
    case userActionTypes.PASSWORD_RESET_FAILURE:
      return {...state,
        passwordResetError : action.error,
        passwordReset : false,
        passwordResetting:false
    };  
    case userActionTypes.PASSWORD_RESET_CLEAR:
      return {...state,
        passwordResetError : null,
        passwordReset : false,
        passwordResetting:false
    };    
    case userActionTypes.EMAIL_VERIFICATION_REQUEST:
      return {...state,
        resendingEmail : true
    }; 
    case userActionTypes.EMAIL_VERIFICATION_SUCCESS:
      return {...state,
        emailResent : true,
        resendingEmail:false
    };    
    case userActionTypes.EMAIL_VERIFICATION_FAILURE:
      return {...state,
        resendingEmailError : action.error,
        emailResent : false,
        resendingEmail:false
    };   
    case userActionTypes.GET_ADMIN_CONFIG_REQUEST:
      return {...state,
        loadingConfigData : true
    }; 
    case userActionTypes.GET_ADMIN_CONFIG_SUCCESS:
      return {...state,
        configDataLoaded : true,
        loadingConfigData:false,
        configData : action.data
    };    
    case userActionTypes.GET_ADMIN_CONFIG_FAILURE:
      return {...state,
        configDataError : action.error,
        configDataLoaded : false,
        loadingConfigData:false
    };   
    case userActionTypes.SHOW_LOGOUT_CONFIRMATION_MODAL:
      return { ...state,
        logoutConfirmationModalShown : action.show 
      };    
    case userActionTypes.SHOW_SUBMIT_PROFILE_MODAL:
      return { ...state,
        submitProfileModalShown : action.show 
      };    
    case userActionTypes.SHOW_SUBMIT_SUCCESS_MODAL:
      return { ...state,
        submittedProfileSuccessfully : action.show 
      };    
    case userActionTypes.LOGOUT:
      return {...state,
        loggedIn : false,
        email : undefined,
        password : undefined
    };  
    case userActionTypes.SUBMIT_PROFILE_REQUEST:
      return {...state, submittingProfile: true };
    case userActionTypes.SUBMIT_PROFILE_SUCCESS:
      return {...state, submitProfileModalShown:false, submittingProfile : false, submittedProfileSuccessfully: true };
    case userActionTypes.SUBMIT_PROFILE_FAILURE:
      return {...state, submittingProfileError: action.error, submittingProfile: false };
    default:
      return state
  }
}