import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { userActions } from "../../../redux/actions/userActions";
import { appActions } from "../../../redux/actions/appActions";
import OverviewCard from "../../Common/OverviewCard/OverviewCard";
import ApplicationOverviewCard from "../../Common/ApplicationOverviewCard/ApplicationOverviewCard";
import Modal from "../../Common/Modal/Modal";
import Button from "../../Common/Button/Button";
import { AuthContext } from "../../../firebaseAuthContext";
import Input from "../../Common/Input/Input";
import { utils } from "../../../helpers/utils";
import InternalLayout from "../../Common/InternalLayout";
import CheckBoxList from "../../Common/CheckboxList/CheckboxList";
import "./AccountOverview.css";
import Loader from "../../Common/Loader/Loader";
import { userService } from "../../../services/userService";
import firebase from "../../../helpers/Firebase";
import PostProjectReview from "../../Common/PostProjectReview/PostProjectReview";
import FloatingAppButton from "../../Common/FloatingAppButton/FloatingAppButton";
import { ReactComponent as ViewIcon } from "../../../images/ViewIcon.svg";
import { applicationService } from "../../../services/applicationService";

import { isThisQuarter } from "date-fns";
import CheckBox from "../../Common/CheckBox/CheckBox";
class AccountOverview extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      currentStage: "Pre-Project",
      changePasswordModalOpen: false,
      applicationModalOpen: false,
      openPostApplicationModal: false,
      openNAICSModal : false,
      passwordValid: false,
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      user: null,
      userInfo: null,
      errors: {},
      companyLegalNoticeCheckboxChecked: false,
      agreedToCompanyNotice: false,
      changeRequestAppIdSelection: null,
      openChangeRequestModal: false,
      filteredPreaprovedApps: [],
      showAppPaymentPinModalOpen: false,
      appPaymentPin: ["*", "*", "*", "*", "*", "*"],
      selectedAppId: "",
      selectedAppData : {},
      selectedAppStatus: "",
      viewPaymentPin: false,
      agreeToViewPaymentPinTerms: false,
      confirmShowPaymentPin: false,
      viewPinAcceptanceCheckList: {
        viewPinAcceptance1: false,
        viewPinAcceptance2: false,
        viewPinAcceptance3: false,
        viewPinAcceptance4: false,
      },
      bankingDetailsModalOpen: false,
      requestEFTPayment: false,
      shareBankDetailsAcceptanceCheckList: {
        shareBankDetailsAcceptance1: false,
      },
      shareBankDetailsPayeeAcceptanceCheckList: {
        shareBankDetailsPayeeAcceptance1: false,
        shareBankDetailsPayeeAcceptance2: false,
        shareBankDetailsPayeeAcceptance3: false,
        shareBankDetailsPayeeAcceptance4: false,
        shareBankDetailsPayeeAcceptance5: false,
        shareBankDetailsPayeeAcceptance6: false,
      },
      bankDetailsReviewerNote : '',
      bankDetailsReviewerReason : '',
      bankAccountNumber: '',
      bankTransitCode: {},
      bankInstitutionCode: {},
      bankVoidCheque: {},
      modalTransition: 0,
      confirmSubmittedApp: false,
      transitCodesLoading:false,
      bankTransitCodeOptions: [],
      bankInstitutionCodeOptions: this.props.configData || {},
      bankDetailsLoaded: false,
      payeeName: "",
      differentNameOnBankAccount: false,
      stream: "esb",
    };
  }

  handleNotesChange = (name, value) => {
    this.setState({
      bankDetailsReviewerNote: value,
    });
  };

  componentDidMount() {
    const { uid } = firebase.auth.currentUser;
    const { selectedAppId } = this.state
    this.props.loadConfig();
    this.props.loadUserDetails(uid);
    userService.getUserDetails(uid, (success, data) => {
      if(data){
        let participantNum = data ? (data.accountCategory.toLowerCase() === "contractor" ? `CON-${data.userNo}` : `CUS-${data.userNo}`) : "";
        if(data.accountCategory.toLowerCase() === "customer"){
          this.props.getPreapprovedApps(uid);
        }
        window.clarity("set", "participantId", participantNum);
        this.props.loadApplications(data ? data.accountCategory.toLowerCase() : "", uid, this.state.currentStage);
        this.props.loadApplicationsByStatus(data ? data.accountCategory.toLowerCase() : "", uid, "Pre-Approval", "Application Submitted");
        this.setState({
          dataLoaded: true,
          user: firebase.auth.currentUser,
          userInfo: data,
        });
      }
    });
  }

  onNAICSChange = (name, arg) => {
    const { errors } = this.state;
    delete errors['naicsCode'];
    this.setState({
        naicsCode: arg,
        errors
    });
  }

  onBusinessOwnerChange = (name, arg) => {
    const { errors } = this.state;
    delete errors['isOwnedBySomeoneFromURG'];
    this.setState({
        isOwnedBySomeoneFromURG: arg,
        errors
    });
  }
  validateBusinessOwnerChange = () => {
    const { isOwnedBySomeoneFromURG, errors } = this.state;
    let errorCount = 0;

    if (utils.isNullOrEmpty(isOwnedBySomeoneFromURG)) {
      errors.isOwnedBySomeoneFromURG = "Please select an answer.";
      errorCount++;
    }
    if (errorCount > 0) {
      this.setState({ errors });
    }

    return errorCount <= 0;
  }
  updateBusinessOwner = () => {
    const { isOwnedBySomeoneFromURG } = this.state;
    const { userInfo } = this.props;
    if (this.validateBusinessOwnerChange()) {
      userService.updateUserDetails(userInfo.uid, {isOwnedBySomeoneFromURG: isOwnedBySomeoneFromURG}).then((data) => {
        window.location.reload(false);
      })
    }
  }

  validateNAICSCode = () => {
    const { naicsCode, errors } = this.state;
    let errorCount = 0;

    if (utils.isNullOrEmpty(naicsCode)) {
      errors.naicsCode = "NAICs Code is required.";
      errorCount++;
    }
    if (errorCount > 0) {
      this.setState({ errors });
    }

    return errorCount <= 0;
  }

  updateNAICSCode = () => {
    const { naicsCode } = this.state;
    const { userInfo } = this.props;
    if (this.validateNAICSCode()) {
      let updateObj = {
        companyDetails : {
          ...userInfo.companyDetails,
          naicsCode: naicsCode
        }
      };
      userService.updateUserDetails(userInfo.uid, updateObj).then((data) => {
        window.location.reload(false);
      })
    }
  }
  handleChangeRequestAppIdChange = (name, data) => {
    //alert(JSON.stringify(data));
    this.setState({
      changeRequestAppIdSelection: data,
    });
  };
  // checkIfPreApprovedAppsLessThanThirty=(app)=>{
  //   if(utils.getDaysFromToday(app["Project Completion Deadline"])<30){ return app};

  // }
  setOpenChangeRequestModal = (status) => {
    let updateObj = { openChangeRequestModal: status };
    //let preapprovedApps=this.props.preapprovedApps;
    // if(status===true){
    //   if(this.state.filteredPreaprovedApps.length===0){
    //     this.setState({loadingPreapprovedApps:true});
    //     updateObj['filteredPreaprovedApps']=preapprovedApps && preapprovedApps.filter(this.checkIfPreApprovedAppsLessThanThirty);
    //     updateObj['loadingPreapprovedApps']=false;
    //   }
    //   updateObj['changeRequestAppIdSelection']=null
    // }
    if (status === false) {
      updateObj["changeRequestAppIdSelection"] = null;
    }
    this.setState(updateObj);
  };
  setOpenPOSTApplicationModal = (status) => {
    this.setState({
      openPostApplicationModal: status,
    });
  };

  toggleApplicationModal = (open) => {
    this.setState({
      applicationModalOpen: open,
    });
  };

  clearChangePassword = () => {
    this.props.clearChangePassword();
    this.setState({
      errors: {},
      oldPassword: "",
      newPassword: "",
      passwordValid: false,
      confirmNewPassword: "",
    });
  };

  openChangePassword = (status) => {
    this.setState({ changePasswordModalOpen: status });
  };

  validateForm = () => {
    const { oldPassword, newPassword, confirmNewPassword, errors } = this.state;
    let errorCount = 0;

    if (utils.isNullOrEmpty(oldPassword)) {
      errors.oldPassword = "Current Password is required.";
      errorCount++;
    }

    if (!utils.isNullOrEmpty(newPassword)) {
      if (!utils.validatePassword(newPassword)) {
        errors.newPassword = "Password does not meet requirements.";
        errorCount++;
      }
      if (newPassword === oldPassword) {
        errors.newPassword = "Cannot re-use same password as before.";
        errorCount++;
      }
    } else {
      errors.newPassword = "New Password is required.";
    }

    if (!utils.isNullOrEmpty(confirmNewPassword)) {
      if (newPassword !== confirmNewPassword) {
        errors.confirmNewPassword = "Passwords do not match.";
        errorCount++;
      }
    } else {
      errors.confirmNewPassword = "Please confirm password.";
      errorCount++;
    }

    if (errorCount > 0) {
      this.setState({ errors });
    }

    return errorCount <= 0;
  };

  submitPasswordChange = () => {
    if (this.validateForm()) {
      //TODO: Change Password
      userService.unblockUserAccount({email: this.context.user.email, program: "esb"});
      const { oldPassword, newPassword } = this.state;
      this.props.changePassword(this.context.user.email, oldPassword, newPassword);
    }
  };

  onChangeHandler = (name, value) => {
    const { errors } = this.state;
    delete errors[name];
    this.setState({
      [name]: value,
      errors,
    });
  };

  handleCheckbox = (name, value) => {
    this.setState({
      differentNameOnBankAccount: value
    })
  }

  handleChangeTransitCode = (name, value) => {
    const { errors } = this.state;
    delete errors[name];
    this.setState({
      [name]: value,
      errors,
    });
  };

  onPasswordChangeHandler = (name, value) => {
    const { newPassword, confirmNewPassword, errors } = this.state;
    delete errors[name];
    if (utils.isNullOrEmpty(value)) {
      let obj = {
        [name]: value,
      };
      if (name === "newPassword") {
        obj.passwordValid = false;
      }
      obj.errors = errors;
      this.setState(obj);
    } else {
      if (name === "newPassword") {
        let passwordValid = false;
        if (value === confirmNewPassword) {
          delete errors.confirmNewPassword;
        } else {
          if (!utils.isNullOrEmpty(confirmNewPassword)) {
            errors.confirmNewPassword = "Password does not match";
          }
        }
        if (utils.validatePassword(value)) {
          passwordValid = true;
        } else {
          errors.newPassword = "Password does not meet requirements.";
        }
        this.setState({
          [name]: value,
          errors,
          passwordValid,
        });
      } else {
        if (value === newPassword) {
        } else {
          errors.confirmNewPassword = "Password does not match";
        }
        this.setState({
          [name]: value,
          errors,
        });
      }
    }
  };

  resendEmail = () => {
    const { user } = this.context;
    if (user) {
      this.props.verifyEmail(user.email);
    }
  };

  onStageChange = (stage) => {
    this.setState(
      {
        currentStage: stage,
      },
      () => {
        this.props.loadApplications(this.state.userInfo.accountCategory.toLowerCase(), this.state.userInfo.uid, stage);
      }
    );
  };

  showMessage = () => {
    const { userInfo } = this.state;
    let message = null;
    if (userInfo) {
      const { status, accountCategory } = userInfo;
      if (accountCategory === "contractor") {
        switch (status) {
          case "Pending":
          case "Details Submission Pending":
            message = (
              <p className="subtitle-message">
                Your profile is incomplete. Click on <span>Complete Details</span> below to complete your profile and become eligible as a
                Contractor under the Energy Savings for Business program.
              </p>
            );
            break;
          case "Pending Approval":
            message = (
              <React.Fragment>
                {/* <p className="subtitle-message">Please see the updated code of conduct <span><a target="_blank" rel="noreferrer" href="https://firebasestorage.googleapis.com/v0/b/dolphin-live-a906a.appspot.com/o/ERA_Contractor%20Code%20of%20Conduct_Feb%201%2C%202021.pdf?alt=media&token=d962c78f-19a9-47eb-8c0c-06864d5df9f2">here</a></span></p> */}
                <p className="subtitle-message">
                  Your account is currently under review to be registered as an <span>Eligible Contractor</span> under the Energy Savings
                  for Business program. You will receive an email once the review is complete.
                </p>
              </React.Fragment>
            );
            break;
          case "Flagged for QA/QC":
            message = (
              <p className="subtitle-message">
                Your account is currently under review by the ESB Program team. You can still edit and review applications for new projects
                assigned to you. Reach out to <a href="mailto:support@esbprogram.ca">support@esbprogram.ca</a> for more information.
              </p>
            );
            break;
          case "Suspended":
            message = (
              <p className="subtitle-message">
                Your account has been suspended by ESB Program team. You will not be able to edit any applications at the moment. Please reach out to <a href="mailto:support@esbprogram.ca">support@esbprogram.ca</a> to request a review of the suspension.
              </p>
            );
            break;
          case "Inactive":
            message = (
              <p className="subtitle-message">
                Your profile has been marked as inactive by the ESB Program Team. If you wish to activate the account, please reach out to <a href="mailto:support@esbprogram.ca">support@esbprogram.ca</a> to request a review of your account.
              </p>
            );
            break;
          case "Participating":
            // message = <p className="subtitle-message">Please see the updated code of conduct <span><a target="_blank" rel="noreferrer" href="https://firebasestorage.googleapis.com/v0/b/dolphin-live-a906a.appspot.com/o/ERA_Contractor%20Code%20of%20Conduct_Feb%201%2C%202021.pdf?alt=media&token=d962c78f-19a9-47eb-8c0c-06864d5df9f2">here</a></span></p>;
            break;
          default:
            message = null;
        }
      } else {
        switch (status) {
          case "Pending":
          case "Details Submission Pending":
            message = (
              <p className="subtitle-message">
                Your profile is incomplete. Click on <span>Complete Details</span> below to complete your profile to be able to start
                submiting new applications.
              </p>
            );
            break;
          case "Flagged for QA/QC":
            message = (
              <p className="subtitle-message">
                Your account is currently under review by the ESB Program team. You can still create and submit applications for new
                projects. Reach out to <a href="mailto:support@esbprogram.ca">support@esbprogram.ca</a> for more information.
              </p>
            );
            break;
          case "Suspended":
            message = (
              <p className="subtitle-message">
                Your account has been suspended by ESB Program team. You will not be able to edit any applications at the moment. Please reach out to <a href="mailto:support@esbprogram.ca">support@esbprogram.ca</a> to request a review of the suspension.
              </p>
            );
            break;
          case "Inactive":
            message = (
              <p className="subtitle-message">
                Your profile has been marked as inactive by the ESB Program Team. If you wish to activate the account, please reach out to <a href="mailto:support@esbprogram.ca">support@esbprogram.ca</a> to request a review of your account.
              </p>
            );
            break;
          case "Participating":
            break;
          default:
            message = null;
        }
      }
    }
    return message;
  };

  onChecklistChangeHandler = (name, value) => {
    this.setState({
      companyLegalNoticeCheckboxChecked: value,
    });
  };

  agreeToCompanyLegalNotice = async () => {
    const { uid } = firebase.auth.currentUser;
    await userService.updateUserDetails(uid, {
      "terms.companyLegalNameNotice": true,
      "termTimeStamps.companyLegalNotice": firebase.getTimestamp(),
    });
    this.setState({
      agreedToCompanyNotice: true,
    });
  };
  onViewPaymentPinChecklistChangeHandler = (name, value) => {
    let { viewPinAcceptanceCheckList, errors } = this.state;
    viewPinAcceptanceCheckList[name] = value;
    delete errors[name];
    this.setState({
      viewPinAcceptanceCheckList: {
        ...viewPinAcceptanceCheckList,
      },
      errors,
    });
  };
  onShareBankingDetailsPayeeChangeHandler = (name, value) => {
    let { shareBankDetailsPayeeAcceptanceCheckList, errors } = this.state;
    shareBankDetailsPayeeAcceptanceCheckList[name] = value;
    delete errors[name];
    this.setState({
      shareBankDetailsPayeeAcceptanceCheckList: {
        ...shareBankDetailsPayeeAcceptanceCheckList,
      },
      errors,
    });
  };
  onShareBankingDetailsChangeHandler = (name, value) => {
    let { shareBankDetailsAcceptanceCheckList, errors } = this.state;
    shareBankDetailsAcceptanceCheckList[name] = value;
    delete errors[name];
    this.setState({
      shareBankDetailsAcceptanceCheckList: {
        ...shareBankDetailsAcceptanceCheckList,
      },
      errors,
    });
  };

  verifyViewPaymentPinAcceptance = () => {
    const { viewPinAcceptanceCheckList } = this.state;
    let errorCount = 0;
    let errors = {};
    for (let key in viewPinAcceptanceCheckList) {
      if (!viewPinAcceptanceCheckList[key]) {
        errors[key] = true;
        errorCount++;
      }
    }
    if (errorCount > 0) {
      this.setState({ errors }, () => {
        let allErrorRefs = document.querySelectorAll(".input-box-error,.error-box");
        if (allErrorRefs.length > 0) {
          let firstErrorRef = allErrorRefs[0];
          firstErrorRef.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
      });
    }
    return errorCount === 0;
  };

  showPaymentPin = () => {
    if (this.verifyViewPaymentPinAcceptance()) {
      userService.getPaymentPin(this.state.selectedAppId, (success, paymentPinData) => {
        if (success === true) {
          this.setState({ confirmShowPaymentPin: true, appPaymentPin: paymentPinData.pin.toString().split("") });
        } else {
        }
      });
    }
  };

  verifyShareBankDetailsPayeeAcceptance = () => {
    const { shareBankDetailsPayeeAcceptanceCheckList } = this.state;
    let errorCount = 0;
    let errors = {};
    for (let key in shareBankDetailsPayeeAcceptanceCheckList) {
      if (!shareBankDetailsPayeeAcceptanceCheckList[key]) {
        errors[key] = true;
        errorCount++;
      }
    }
    if (errorCount > 0) {
      this.setState({ errors }, () => {
        let allErrorRefs = document.querySelectorAll(".input-box-error,.error-box");
        if (allErrorRefs.length > 0) {
          let firstErrorRef = allErrorRefs[0];
          firstErrorRef.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
      });
    }
    return errorCount === 0;
  };
  verifyShareBankDetailsAcceptance = () => {
    const { shareBankDetailsAcceptanceCheckList } = this.state;
    let errorCount = 0;
    let errors = {};
    for (let key in shareBankDetailsAcceptanceCheckList) {
      if (!shareBankDetailsAcceptanceCheckList[key]) {
        errors[key] = true;
        errorCount++;
      }
    }
    if (errorCount > 0) {
      this.setState({ errors }, () => {
        let allErrorRefs = document.querySelectorAll(".input-box-error,.error-box");
        if (allErrorRefs.length > 0) {
          let firstErrorRef = allErrorRefs[0];
          firstErrorRef.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
      });
    }
    return errorCount === 0;
  };
  handleDocumentUpload = (name, fileObj) => {
    let { errors } = this.state;
    delete errors['bankVoidCheque'];
    if ((fileObj && fileObj.name)) {
      fileObj.applicationId = this.state.selectedAppId;
      fileObj.appStage = this.state.selectedAppData.stage;
      this.props.createDocument(fileObj);
    }
    this.setState({bankVoidCheque: fileObj, errors});
  };
  handleChangeInstitutionCode = (name, val) => {
    const { errors } = this.state;
    delete errors.bankInstitutionCode;
    this.setState({bankInstitutionCode: val, errors});
    if(val && val.value){
      this.setState({
        transitCodesLoading:true,
        bankTransitCode:{},
        bankTransitCodeOptions : []
      },()=>{
        applicationService.getBankBranches(val.value).then((data)=>{
          this.setState({
            transitCodesLoading:false,
            bankTransitCodeOptions : data
          });
        });
      })
    }
  }

  verifyBankForm = () => {
    const { bankAccountNumber, payeeName ,bankTransitCode, bankInstitutionCode, bankVoidCheque, errors} = this.state;
    let errorCount = 0;
    if (utils.isNullOrEmpty(bankAccountNumber)) {
      errors.bankAccountNumber = "Bank Account Number is required.";
      errorCount++;
    }
    if (utils.isNullOrEmpty(payeeName)) {
      errors.payeeName = "Name on Bank Account is required.";
      errorCount++;
    }
    if (utils.isNullOrEmpty(bankTransitCode)) {
      errors.bankTransitCode = "Transit Code is required.";
      errorCount++;
    }
    if (utils.isNullOrEmpty(bankInstitutionCode.value)) {
      errors.bankInstitutionCode = "Institution Code is required.";
      errorCount++;
    }
    if (utils.isNullOrEmpty(bankVoidCheque)) {
      errors.bankVoidCheque = "Void Cheque is required.";
      errorCount++;
    }
    if (errorCount > 0) {
      this.setState({ errors });
    }

    return errorCount <= 0;
  }
  showRequestEFTPayment = () => {
    const { selectedAppStatus } = this.state;
    let allErrorRefs = document.querySelectorAll(".scroll-top");
    if (allErrorRefs.length > 0) {
      let firstErrorRef = allErrorRefs[0];
      firstErrorRef.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    }
    if (selectedAppStatus === "Revise Banking Details") {
      this.setState({requestEFTPayment: true, modalTransition: 1});
    }
    else if(this.verifyShareBankDetailsPayeeAcceptance()) {
      this.setState({requestEFTPayment: true, modalTransition: 1});
    }
  }
  refreshPage = () => {
    window.location.reload(false)
  }
  handleConfirmBankDetails = (modalTransition) => {
    const { selectedAppStatus } = this.state;
    const { userInfo } = this.props;
    let allErrorRefs = document.querySelectorAll(".scroll-top");
    if (allErrorRefs.length > 0) {
      let firstErrorRef = allErrorRefs[0];
      firstErrorRef.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    }
    if(selectedAppStatus === "Revise Banking Details" ? this.verifyBankForm() : (this.verifyBankForm() && this.verifyShareBankDetailsAcceptance())) {
      if (modalTransition === 1) {
        this.setState({modalTransition: modalTransition + 1});
      } else if(modalTransition === 2) {
        const {
          selectedAppData, 
          selectedAppId,
          shareBankDetailsPayeeAcceptanceCheckList, 
          shareBankDetailsAcceptanceCheckList, 
          bankVoidCheque, 
          bankAccountNumber, 
          bankTransitCode, 
          bankInstitutionCode,
          payeeName,
          differentNameOnBankAccount,
        } = this.state;
        let oldPayeeName = selectedAppData.payeeInfo ? selectedAppData.payeeInfo.payeeName : '';
        let checkDifferentPayeeName = differentNameOnBankAccount ? (oldPayeeName.toUpperCase() === payeeName.toUpperCase()) ? false : true : false;
        let applicationObj = { 
          status: "Banking Details In Review",
          reviewer: selectedAppData.paymentReviewer ? selectedAppData.paymentReviewer : selectedAppData.postProjectReviewer,
          nameOnBankAccount: payeeName, 
          bankAccountNumber: bankAccountNumber, 
          branchInfo: bankTransitCode.data, 
          bankInstitutionCode: bankInstitutionCode,
          bankVoidCheque: bankVoidCheque,
          differentNameOnBankAccount: checkDifferentPayeeName,
          shareBankDetailsPayeeAcceptanceCheckList: shareBankDetailsPayeeAcceptanceCheckList,
          shareBankDetailsAcceptanceCheckList: shareBankDetailsAcceptanceCheckList,
          actionTaker: `${userInfo.firstName} ${userInfo.lastName}`,
          actionNote: "",
        };
        applicationService.updateBankDetailsInApp(selectedAppId, applicationObj).then((res) => {
          this.setState({confirmSubmittedApp: true, modalTransition:0},
            ()=>{
              setTimeout(()=>{this.setBankingDetailsModalOpen(false); this.refreshPage()},3000)
            });
        })
      }
    }
  }
  handleGoBack = (modalTransition) => {
    let allErrorRefs = document.querySelectorAll(".scroll-top");
    if (allErrorRefs.length > 0) {
      let firstErrorRef = allErrorRefs[0];
      firstErrorRef.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    }
    if (modalTransition === 1) {
      this.setState({requestEFTPayment: false, modalTransition: modalTransition-1});
    } else if(modalTransition === 2) {
      this.setState({confirmSubmittedApp: false, modalTransition: modalTransition-1});
    }
  }
  setShowAppPaymentPinModalOpen = (state, app = null) => {
    var updateObj = {
      showAppPaymentPinModalOpen: state,
      viewPaymentPin: false,
      appPaymentPin: ["*", "*", "*", "*", "*", "*"],
      confirmShowPaymentPin: false,
      viewPinAcceptanceCheckList: {
        viewPinAcceptance1: false,
        viewPinAcceptance2: false,
        viewPinAcceptance3: false,
        viewPinAcceptance4: false,
      },
    };
    if(app) {
      updateObj["selectedAppId"] = app.id;
      updateObj["selectedAppData"] = app;
    }
    this.setState(updateObj);
  };
  setBankingDetailsModalOpen = (state, app = null, status="") => {
    var updateObj = {
      bankingDetailsModalOpen: state,
      requestEFTPayment: false,
      shareBankDetailsPayeeAcceptanceCheckList: {
        shareBankDetailsPayeeAcceptance1: false,
        shareBankDetailsPayeeAcceptance2: false,
        shareBankDetailsPayeeAcceptance3: false,
        shareBankDetailsPayeeAcceptance4: false,
        shareBankDetailsPayeeAcceptance5: false,
        shareBankDetailsPayeeAcceptance6: false,
      },
      shareBankDetailsAcceptanceCheckList: {
        shareBankDetailsAcceptance1: false,
      },
      bankAccountNumber: '',
      bankTransitCode: {},
      bankInstitutionCode: {},
      bankVoidCheque: {},
      errors: {}
    };
    if(app) {
      updateObj["selectedAppId"] = app.id;
      updateObj["selectedAppData"] = app;
      updateObj["payeeName"] = app.payeeInfo ? app.payeeInfo.payeeName : '';
      updateObj["selectedAppStatus"] = status;
      updateObj['modalTransition'] = 0;
      updateObj['differentNameOnBankAccount'] = app.differentNameOnBankAccount ? app.differentNameOnBankAccount : false;

      if (status === 'Revise Banking Details') {
        applicationService.getAppBankDetails(app.id).then((data)=> {
          updateObj.payeeName = data.nameOnBankAccount
          updateObj.bankAccountNumber = data.bankAccountNumber;
          updateObj.bankInstitutionCode = data.bankInstitutionCode;
          let bankTransitCode = {
            data : data.branchInfo,
            label: data.branchInfo.transitCode,
            value: data.branchInfo.transitCode
          };
          updateObj.bankTransitCode = bankTransitCode;
          updateObj.bankVoidCheque = data.bankVoidCheque;
          applicationService.getBankBranches( data.bankInstitutionCode.value).then((transitCodeOptions)=>{
            applicationService.getAppBankDetailsReview(app.id).then((reviewObj) => {
              updateObj.bankDetailsReviewerNote = reviewObj.informationRequest || '';
              updateObj.bankDetailsReviewerReason = reviewObj.reason;
              updateObj.transitCodesLoading = false;
              updateObj.bankTransitCodeOptions = transitCodeOptions;
              updateObj.bankDetailsLoaded = true;
              this.setState(updateObj);
            })
          });
        })
        this.setState(updateObj);
      } else {
        updateObj.bankDetailsLoaded = true;
        this.setState(updateObj);
      } 
    }else {
      this.setState(updateObj);
      // this.refreshPage();
    };
  };
  viewDocument = (document) => {
    window.open(document.url);
  };
  redirectToEditDetails = () => {
    window.location.href = "/editdetails";
  };
  triggerChangeRequestModal = () => {
    let appId = this.state.changeRequestAppIdSelection.value;
    this.setOpenChangeRequestModal(false);
    this.applicationOverviewCardChild.setRequestChangeOpenModal(true, appId);
  };
  startNewApplication = (stream) => {
    if (stream === "esb") {
      this.toggleApplicationModal(false);
      window.location = `/application/esb`;
    } 
    else {
      this.toggleApplicationModal(false);
      window.location = `/application/ces`;
    }
  }
  setStream= (stream) => {
    this.setState({
      stream: stream
    })
  }
  handleRadioOption = (e) => {
    this.setState({
      stream : e.target.value
    })
  }
  render() {
    const {
      user,
      dataLoaded,
      errors,
      oldPassword,
      newPassword,
      confirmNewPassword,
      passwordValid,
      companyLegalNoticeCheckboxChecked,
      agreedToCompanyNotice,
      openPostApplicationModal,
      applicationModalOpen,
      changeRequestAppIdSelection,
      openChangeRequestModal,
      showAppPaymentPinModalOpen,
      appPaymentPin,
      confirmShowPaymentPin,
      viewPinAcceptanceCheckList,
      selectedAppData,
      selectedAppStatus,
      bankingDetailsModalOpen,
      requestEFTPayment,
      shareBankDetailsPayeeAcceptanceCheckList,
      shareBankDetailsAcceptanceCheckList,
      bankAccountNumber,
      bankTransitCode,
      bankInstitutionCode,
      bankVoidCheque,
      modalTransition,
      confirmSubmittedApp,
      bankDetailsReviewerNote,
      bankDetailsReviewerReason,
      bankTransitCodeOptions,
      transitCodesLoading,
      bankDetailsLoaded,
      naicsCode,
      payeeName,
      differentNameOnBankAccount,
      isOwnedBySomeoneFromURG,
      stream
    } = this.state;
    const {
      resendingEmail,
      emailResent,
      passwordChanging,
      passwordChanged,
      changePasswordError,
      applicationList,
      loadingApplications,
      configData,
      userInfo,
      applicationListByStatus,
      preapprovedApps,
      loadingPreapprovedApps,
      documentList
    } = this.props;
    let noticeConfigShown = false;
    let noticeConfig = configData ? configData.noticeConfig : null;
    let appStatuses = configData ? configData.appStatuses[this.state.currentStage].map((item) => item.status) : [];
    if (noticeConfig) {
      noticeConfigShown = noticeConfig.shown;
    }
    let companyLegalNameNoticeChecked = false;
    let showLegalNotice = false;
    if (userInfo && userInfo.terms) {
      showLegalNotice = userInfo.accountCategory.toLowerCase() === "customer";
      companyLegalNameNoticeChecked = userInfo.terms.companyLegalNameNotice;
    }
    let banks = configData ? configData.banks : null;
    let institutionCodes = banks ? banks.institutionCodes : {};
    let lastDate = new Date("2021-11-22");
    let accountDate = userInfo ? userInfo.createdAt.toDate() : '';
    let showNAICS = accountDate ? (accountDate.getTime() < lastDate.getTime()) : '';
    return (
      <InternalLayout history={this.props.history}>
        {dataLoaded ? (
          <div className="account-container">
            {configData && configData.naicsCodes ? (
              <Modal
                HeaderText={"NAICS CODE OF THE BUSINESS"}
                IsOpen={
                  userInfo && userInfo.companyDetails && showNAICS
                    ? userInfo.companyDetails.naicsCode &&
                      userInfo.companyDetails.naicsCode.label &&
                      userInfo.companyDetails.naicsCode.value
                      ? false
                      : true
                    : false
                }
                // handleCloseModal={this.handleCloseNAICSModal}
              >
                <div className="update-naics">
                  <div className="container">
                    <p>
                      It looks like the NAICS code for the business is missing from your profile.
                      <br />
                      <br />
                      Please select an appropriate NAICS code below.
                    </p>
                    <Input
                      label={"NAICs Code"}
                      type="dropdown"
                      name="naicsCode"
                      error={errors["naicsCode"]}
                      onChange={this.onNAICSChange}
                      value={
                        naicsCode
                          ? {
                              value: naicsCode.value,
                              label: naicsCode.label,
                            }
                          : null
                      }
                      options={configData.naicsCodes.options.map((item) => {
                        return { label: item.display, value: item.code };
                      })}
                    />
                  </div>
                  <Button title={"Update Profile"} uppercase onClick={this.updateNAICSCode} />
                </div>
              </Modal>
            ) : (
              ""
            )}
            {userInfo && !userInfo.isOwnedBySomeoneFromURG && (
              <Modal HeaderText="Update Details" IsOpen={userInfo ? (userInfo.isOwnedBySomeoneFromURG ? false : true) : false}>
                <div className="question-update">
                  <div className="container">
                    <p>
                      It looks like the answer to a question is missing from your profile.
                      <br />
                      <br />
                      Please select an appropriate answer below.
                    </p>
                    <Input
                      label={"Is your business owned by someone from an under-represented group?"}
                      type="dropdown"
                      name="isOwnedBySomeoneFromURG"
                      error={errors["isOwnedBySomeoneFromURG"]}
                      onChange={this.onBusinessOwnerChange}
                      value={
                        isOwnedBySomeoneFromURG
                          ? {
                              value: isOwnedBySomeoneFromURG.value,
                              label: isOwnedBySomeoneFromURG.label,
                            }
                          : null
                      }
                      options={[
                        { value: "Yes", label: "Yes" },
                        { value: "No", label: "No" },
                        { value: "Prefer not to Answer", label: "Prefer not to Answer" },
                      ]}
                    />
                  </div>
                  <Button title={"Update Profile"} uppercase onClick={this.updateBusinessOwner} />
                </div>
              </Modal>
            )}
            <Modal
              modalSize={"large"}
              HeaderText={"Company Legal Name Notice"}
              IsOpen={showLegalNotice && !companyLegalNameNoticeChecked}
              handleCloseModal={
                agreedToCompanyNotice
                  ? () => {
                      this.props.loadUserDetails(userInfo.uid);
                      this.setState({
                        companyLegalNameNoticeChecked: true,
                      });
                    }
                  : null
              }
            >
              <div className="legal-notice-modal-content">
                {agreedToCompanyNotice ? (
                  <React.Fragment>
                    <p>Successfully agreed to company Notice</p>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <p>
                      We have updated our profile terms and conditions. We request you to confirm the following condition before you proceed
                      further:
                    </p>
                    <div className="checkboxes-section">
                      <CheckBoxList
                        onChange={this.onChecklistChangeHandler}
                        errors={errors}
                        items={[
                          {
                            checked: companyLegalNoticeCheckboxChecked,
                            name: "companyLegalNameNotice",
                            title: (
                              <p>
                                The <span>Company Name</span> is the full legal name of the Applicant that will be a party to the
                                Participant Acceptance, when executed. Unless otherwise assigned to a third party, the Company Name is the
                                name on the bank account to which incentive payments may be made when so approved. The Company Name will
                                also be used to assess compliance with 6.1 (e) i.e. the total of all Project Incentives payable and paid to
                                all direct and indirect <span>affiliates</span> (as defined in the Alberta Business Corporations Act) of the
                                Participant as at the time of calculating the Project Incentive.
                              </p>
                            ),
                          },
                        ]}
                      />
                    </div>
                    <p>
                      If the Company Name provided in your account does not meet the requirement stated above, please amend the Company Name
                      by clicking on 'Edit Company Name’ below and come back to this page and agree to the condition.
                    </p>
                    <div className="button-set">
                      <Button
                        title={"I agree to the above condition"}
                        disabled={!companyLegalNoticeCheckboxChecked}
                        uppercase
                        onClick={this.agreeToCompanyLegalNotice}
                      />
                      <Button title={"Edit Company Name"} uppercase className="inversed-btn" onClick={this.redirectToEditDetails} />
                    </div>
                  </React.Fragment>
                )}
              </div>
            </Modal>
            <Modal
              HeaderText={"Initiate Change Request"}
              IsOpen={openChangeRequestModal}
              handleCloseModal={() => this.setOpenChangeRequestModal(false)}
            >
              {!loadingPreapprovedApps ? (
                <React.Fragment>
                  <p>
                    Change Requests can only be initiated for applications that have been submitted after receiving pre-approval and have
                    over 30 days left to their Project Completion Deadline.
                  </p>
                  <div className="change-request-app-selection-container">
                    <div className="form-inner-section">
                      <div className="form-label">Select a submitted application</div>
                      <div className="form-element">
                        <Input
                          placeholder={"Select"}
                          type="dropdown"
                          name="changRequestAppIDSelection"
                          onChange={this.handleChangeRequestAppIdChange}
                          value={utils.isNullOrEmpty(changeRequestAppIdSelection) ? undefined : changeRequestAppIdSelection}
                          options={
                            preapprovedApps &&
                            preapprovedApps.length > 0 &&
                            preapprovedApps.map((app) => {
                              return { label: `${app.stream === "ces" ? "#CES" : "#ESB"}-${app.appNo}`, value: app.id };
                            })
                          }
                          isSearchable={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="button-set">
                    <Button className="inversed-btn" title={"GO BACK"} uppercase onClick={() => this.setOpenChangeRequestModal(false)} />
                    <Button
                      onClick={() => {
                        this.triggerChangeRequestModal();
                      }}
                      title={"Proceed"}
                      disabled={!changeRequestAppIdSelection}
                      uppercase
                    />
                  </div>
                </React.Fragment>
              ) : (
                <Loader />
              )}
            </Modal>

            <Modal
              HeaderText={"VERIFICATION EMAIL SENT TO YOUR EMAIL ID!"}
              IsOpen={!firebase.auth.currentUser.emailVerified}
              handleCloseModal={this.handleCloseEmailSentModal}
              loading={resendingEmail}
            >
              {emailResent ? (
                <div>
                  <p>
                    The verification email has been re-sent to <span>{user.email}</span>
                  </p>
                  <p>If you haven’t received the email in your primary inbox, please check your spam and promotions folders.</p>
                  <div className="button-set">
                    <Button title={"LOG OUT"} uppercase className="inversed-btn" onClick={this.props.logout} />
                    <Button title={"Re-Send Email"} uppercase onClick={this.resendEmail} />
                  </div>
                </div>
              ) : (
                <div>
                  <p>
                    Please visit your email inbox and <span>click on the verification link</span> to access your account page.
                  </p>
                  <p>If you haven’t received the email in your primary inbox, please check your spam and promotions folders.</p>
                  <p className="modal-question">Did not receive the verification email?</p>
                  <div className="button-set">
                    <Button title={"LOG OUT"} uppercase className="inversed-btn" onClick={this.props.logout} />
                    <Button title={"Re-Send Email"} uppercase onClick={this.resendEmail} />
                  </div>
                </div>
              )}
            </Modal>
            <Modal
              HeaderText={"Payment Pin"}
              modalSize={confirmShowPaymentPin ? "small" : "large"}
              IsOpen={showAppPaymentPinModalOpen}
              handleCloseModal={() => this.setShowAppPaymentPinModalOpen(false)}
            >
              <div className="payment-pin-modal">
                {!confirmShowPaymentPin ? (
                  <React.Fragment>
                    <div className="payment-pin-confirmation">
                      <p>
                        In order to facilitate incentive payments for this application,{" "}
                        <span>{selectedAppData.payeeInfo ? selectedAppData.payeeInfo.payeeName : ""}</span> (“PAYEE”) is required to enter
                        this Payment PIN as an answer to the security question on a third-party payment processing website of Plooto Inc.
                        and provide (among other things) its address and the name, address, transit number, branch number, account number
                        and/or SWIFT code of its financial institution (bank).{" "}
                      </p>
                      <p>
                        <span className="green-text">{selectedAppData.payeeInfo ? selectedAppData.payeeInfo.payeeName : ""}</span> may also
                        be required to agree with any terms, conditions and/or consents required by Plooto and should review these in
                        detail.
                      </p>
                      <div className="checkboxes-section">
                        <CheckBoxList
                          onChange={this.onViewPaymentPinChecklistChangeHandler}
                          errors={errors}
                          items={[
                            {
                              checked: viewPinAcceptanceCheckList["viewPinAcceptance1"],
                              name: "viewPinAcceptance1",
                              title: <p>The PAYEE acknowledges that the use of Plooto’s website is at the sole risk of the PAYEE</p>,
                            },
                            {
                              checked: viewPinAcceptanceCheckList["viewPinAcceptance2"],
                              name: "viewPinAcceptance2",
                              title: (
                                <p>
                                  I confirm that, as agent for the PAYEE, I am responsible for keeping this Payment PIN secure and for any
                                  and all uses of it by any employees, delegates, consultants, subcontractors, agents or representatives of
                                  <b> {selectedAppData.payeeInfo ? selectedAppData.payeeInfo.payeeName : ""}</b>
                                </p>
                              ),
                            },
                            {
                              checked: viewPinAcceptanceCheckList["viewPinAcceptance3"],
                              name: "viewPinAcceptance3",
                              title: (
                                <p>
                                  The PAYEE agrees and confirms that Emissions Reduction Alberta [and its service provider, Enerva Energy
                                  Solutions Inc.] may share the PAYEE’s name and address and the amount of the Incentive Payment with
                                  Plooto. The PAYEE further agrees that any PAYEE information provided to Plooto, including but not limited
                                  to any payment records may be shared with Emissions Reduction Alberta [and its service provider, Enerva
                                  Energy Solutions Inc.]. All PAYEE information received from Plooto will be maintained in confidence but
                                  may be disclosed to third parties (a) if required by law, (b) to assess compliance with the Energy Savings
                                  for Business Program, or (c) in accordance with section 10.2 of the{" "}
                                  <span>
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href="https://firebasestorage.googleapis.com/v0/b/dolphin-live-a906a.appspot.com/o/ERA_Participant%20Terms%20and%20Conditions_Feb%201%2C%202021.pdf?alt=media&token=c714fd13-c117-48fe-ace6-40857353a647"
                                    >
                                      Participant Terms and Conditions
                                    </a>
                                  </span>
                                </p>
                              ),
                            },
                            {
                              checked: viewPinAcceptanceCheckList["viewPinAcceptance4"],
                              name: "viewPinAcceptance4",
                              title: (
                                <p>
                                  I represent and warrant that I have authority to bind{" "}
                                  <b>{selectedAppData.payeeInfo ? selectedAppData.payeeInfo.payeeName : ""}</b>
                                </p>
                              ),
                            },
                          ]}
                        />
                      </div>
                    </div>
                    <div className="button-set">
                      <Button
                        className="inversed-btn"
                        title={"Close"}
                        uppercase
                        onClick={() => this.setShowAppPaymentPinModalOpen(false)}
                      />
                      <Button
                        onClick={() => {
                          this.showPaymentPin();
                        }}
                        title={"View PIN"}
                        uppercase
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="pin-blocks">
                      <div className="block">{appPaymentPin[0]}</div>
                      <div className="block">{appPaymentPin[1]}</div>
                      <div className="block">{appPaymentPin[2]}</div>
                      <div className="block">{appPaymentPin[3]}</div>
                      <div className="block">{appPaymentPin[4]}</div>
                      {appPaymentPin[5] ? <div className="block">{appPaymentPin[5]}</div> : ""}
                    </div>
                    <div className="button-set ">
                      <Button
                        className="close-viewpin"
                        title={"Close"}
                        uppercase
                        onClick={() => this.setShowAppPaymentPinModalOpen(false)}
                      />
                    </div>
                  </React.Fragment>
                )}
              </div>
            </Modal>
            <Modal
              headerClass="share-bank-details-heading"
              HeaderText={
                bankDetailsLoaded
                  ? !confirmSubmittedApp
                    ? requestEFTPayment
                      ? "Request EFT Payment"
                      : selectedAppStatus === "Share Banking Details"
                      ? "Share Banking Details"
                      : "Revise Banking Details"
                    : "Banking Details"
                  : ""
              }
              modalSize={requestEFTPayment ? (modalTransition === 1 ? "large" : "small") : "large"}
              IsOpen={bankingDetailsModalOpen}
              handleCloseModal={() => this.setBankingDetailsModalOpen(false)}
            >
              {bankDetailsLoaded ? (
                <div className="payment-pin-modal">
                  {!requestEFTPayment ? (
                    selectedAppStatus === "Share Banking Details" ? (
                      <React.Fragment>
                        <div className="container-for-banking">
                          <div className="payment-pin-confirmation">
                            <p className="scroll-top">
                              In order to facilitate Project Incentive payments for this application,{" "}
                              <span>{selectedAppData.payeeInfo ? selectedAppData.payeeInfo.payeeName : ""}</span> (“PAYEE”) is required to
                              provide the following banking information: the name on the account, account number, bank address (including
                              institution number), and the branch/transit number(“PAYMENT INFORMATION”).
                            </p>
                            <p className="scroll-top">
                              To receive the Project Incentive, PAYEE may also be required to agree with additional terms, conditions and/or
                              consents required by Enerva Energy Solutions Inc. (“Enerva”) required to facilitate payment of the Project
                              Incentive (the “Enerva Terms”). The PAYEE should review the Enerva Terms in detail as Emissions Reduction
                              Alberta (“ERA”) makes no representation or warranty in connection with same.
                            </p>
                            <p className="scroll-top">By clicking on the checkboxes below, the PAYEE:</p>
                            <div className="checkboxes-section">
                              <CheckBoxList
                                onChange={this.onShareBankingDetailsPayeeChangeHandler}
                                errors={errors}
                                items={[
                                  {
                                    checked: shareBankDetailsPayeeAcceptanceCheckList["shareBankDetailsPayeeAcceptance1"],
                                    name: "shareBankDetailsPayeeAcceptance1",
                                    title: (
                                      <p>
                                        Agrees and acknowledges that the PAYEE is solely responsible for providing correct and complete
                                        PAYMENT INFORMATION. ERA is not responsible for any component of the Participant Incentive as a
                                        result of incomplete or inaccurate Payment Information.
                                      </p>
                                    ),
                                  },
                                  {
                                    checked: shareBankDetailsPayeeAcceptanceCheckList["shareBankDetailsPayeeAcceptance2"],
                                    name: "shareBankDetailsPayeeAcceptance2",
                                    title: (
                                      <p>
                                        Agrees and acknowledges that payment of the Participant Incentive to the PAYEE as specified in the
                                        PAYMENT INFORMATION satisfies and discharges in full ERA’s obligation to pay the Participant
                                        Incentive with respect to application <span>#ESB-{selectedAppData.appNo}</span>.
                                      </p>
                                    ),
                                  },
                                  {
                                    checked: shareBankDetailsPayeeAcceptanceCheckList["shareBankDetailsPayeeAcceptance3"],
                                    name: "shareBankDetailsPayeeAcceptance3",
                                    title: (
                                      <p>
                                        Confirms responsibility for the accuracy of all information provided by any employees, delegates,
                                        consultants, subcontractors, agents or representatives of the PAYEE.
                                      </p>
                                    ),
                                  },
                                  {
                                    checked: shareBankDetailsPayeeAcceptanceCheckList["shareBankDetailsPayeeAcceptance4"],
                                    name: "shareBankDetailsPayeeAcceptance4",
                                    title: (
                                      <p>
                                        Agrees and confirms that ERA and its service provider, Enerva, may share the PAYEE’s name, address
                                        and the amount of the Project Incentive with third party platforms for processing the payment.
                                      </p>
                                    ),
                                  },
                                  {
                                    checked: shareBankDetailsPayeeAcceptanceCheckList["shareBankDetailsPayeeAcceptance5"],
                                    name: "shareBankDetailsPayeeAcceptance5",
                                    title: (
                                      <p>
                                        Agrees that any PAYEE information provided to third party platforms, including but not limited to
                                        any payment records, may be shared with ERA and Enerva.
                                      </p>
                                    ),
                                  },
                                  {
                                    checked: shareBankDetailsPayeeAcceptanceCheckList["shareBankDetailsPayeeAcceptance6"],
                                    name: "shareBankDetailsPayeeAcceptance6",
                                    title: (
                                      <p>
                                        Acknowledges that PAYEE information, including but not limited to banking information, may be
                                        disclosed to third parties: (a) if required by law, (b) to assess compliance with the ESB Program,
                                        or (c) in accordance with section 10.2 of the{" "}
                                        <span>
                                          <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://firebasestorage.googleapis.com/v0/b/dolphin-live-a906a.appspot.com/o/ERA_Participant%20Terms%20and%20Conditions_Feb%201%2C%202021.pdf?alt=media&token=c714fd13-c117-48fe-ace6-40857353a647"
                                          >
                                            Participant Terms and Conditions.
                                          </a>
                                        </span>
                                      </p>
                                    ),
                                  },
                                ]}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="request-button">
                          <Button
                            onClick={() => {
                              this.showRequestEFTPayment();
                            }}
                            title={
                              <div className="request-button-title">
                                {" "}
                                <div>Request EFT Payment</div> <span>(Share Banking Account Details)</span>
                              </div>
                            }
                            uppercase
                          />
                        </div>
                        <div>
                          <span>Payment Timeline:</span> 5-7 business days from the day of receiving the final approval of incentive
                          recommendation.
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className="payment-pin-confirmation">
                          <div className="revise-details-container">
                            <p>
                              The banking details that you have shared for the application #ESB-{selectedAppData.appNo} have been reviewed
                              by the ESB-Program Team. However, the payment cannot be processed at this time and requires corrective action
                              by you.
                            </p>
                            <p className="reason-heading">Reason for inability to process payment:</p>
                            <p>{bankDetailsReviewerReason.label}</p>
                            <div className="form-inner-section">
                              <div className="form-label bold full">Reviewer Note:</div>
                            </div>
                            <div className="form-inner-section">
                              <div className="form-element full">
                                <Input
                                  type="richtext"
                                  disabled={bankDetailsReviewerNote}
                                  value={bankDetailsReviewerNote}
                                  onChange={this.handleNotesChange}
                                  name="notes"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="request-button">
                          <Button
                            onClick={() => {
                              this.showRequestEFTPayment();
                            }}
                            title={<div className="request-button-title">Revise Banking Details</div>}
                            uppercase
                          />
                        </div>
                      </React.Fragment>
                    )
                  ) : !confirmSubmittedApp ? (
                    <React.Fragment>
                      <div className={`request-eft-payment-${modalTransition === 1 ? "large" : "small"}`}>
                        <p className="scroll-top">
                          Enter your Payment Information below for processing payment of the Participant Incentive for Post-Project
                          Application <span>#ESB-{selectedAppData.appNo}</span>.
                        </p>
                        <p className="scroll-top">
                          Please confirm that the PAYMENT INFORMATION you submit is complete and accurate. For example, if the Name on the
                          Bank Account does not match the void cheque or direct deposit form, the banking details will be rejected. If the
                          Name on Bank Account is different from the Payee Name on the Application, selecting the 'Name on Bank Account is
                          Different' checkbox will allow you to edit the name to ensure it matches the verification document. Inaccurate or
                          incomplete Payment Information may cause delays, non-payment, or misdirected payments. ERA is not responsible for
                          any component of the Participant Incentive as a result of incomplete or inaccurate Payment Information.
                        </p>
                        <p className="scroll-top">
                          Change requests to the banking details will not be accepted once the details are submitted.
                        </p>
                        {modalTransition === 1 ? (
                          <div className="form-inner-section">
                            <div className="form-inner-elements">
                              <div className="bank-details-form-label">Name on Bank Account:</div>
                              <div className="bank-details-form-element">
                                {differentNameOnBankAccount ? (
                                  <Input
                                    // disabled={selectedAppData.payeeInfo && selectedAppData.payeeInfo.payeeName}
                                    onChange={this.onChangeHandler}
                                    name="payeeName"
                                    value={payeeName}
                                    error={errors["payeeName"]}
                                  />
                                ) : (
                                  <Input
                                    disabled={selectedAppData.payeeInfo && selectedAppData.payeeInfo.payeeName}
                                    value={selectedAppData.payeeInfo ? selectedAppData.payeeInfo.payeeName : ""}
                                    error={errors["payeeName"]}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="form-inner-elements">
                              <div className="bank-details-form-label"> </div>
                              <div className="bank-details-form-element">
                                <CheckBox onChange={this.handleCheckbox} checked={differentNameOnBankAccount} />
                                <label className="checkbox-label" for="differentNameOnBankAccount">
                                  Name on Bank Account is Different
                                </label>
                              </div>
                            </div>
                            <div className="form-inner-elements">
                              <div className="bank-details-form-label">Bank Account Number:</div>
                              <div className="bank-details-form-element">
                                <Input
                                  name="bankAccountNumber"
                                  type="integer"
                                  placeholder={"Type Bank Account Number"}
                                  value={bankAccountNumber}
                                  error={errors["bankAccountNumber"]}
                                  onChange={this.onChangeHandler}
                                />
                              </div>
                            </div>
                            <div className="form-inner-elements">
                              <div className="bank-details-form-label">Institution Code:</div>
                              <div className="bank-details-form-element">
                                <Input
                                  placeholder={"Select Institution Code"}
                                  type="dropdown"
                                  name="bankInstitutionCode"
                                  error={errors["bankInstitutionCode"]}
                                  onChange={this.handleChangeInstitutionCode}
                                  value={utils.isNullOrEmpty(bankInstitutionCode) ? undefined : bankInstitutionCode}
                                  options={institutionCodes.map((code) => {
                                    return { label: code.label, value: code.value };
                                  })}
                                  isSearchable={false}
                                />
                              </div>
                            </div>
                            <div className="form-inner-elements">
                              <div className="bank-details-form-label">Transit Code:</div>
                              <div className="bank-details-form-element">
                                <Input
                                  placeholder={"Select/Type Transit Code"}
                                  type="dropdown"
                                  loading={transitCodesLoading}
                                  disabled={bankTransitCodeOptions.length === 0}
                                  name="bankTransitCode"
                                  error={errors["bankTransitCode"]}
                                  onChange={this.handleChangeTransitCode}
                                  value={utils.isNullOrEmpty(bankTransitCode) ? undefined : bankTransitCode}
                                  options={
                                    bankTransitCodeOptions
                                      ? bankTransitCodeOptions.map((branch) => {
                                          return {
                                            label: branch.transitCode,
                                            value: branch.transitCode,
                                            data: branch,
                                          };
                                        })
                                      : ""
                                  }
                                  note={bankTransitCode.data ? bankTransitCode.data.civicAddress : ""}
                                  isSearchable={false}
                                />
                              </div>
                            </div>
                            <div className="form-inner-elements">
                              <div className="bank-details-form-label">Upload Verification Document:</div>
                              <div className={!errors["bankVoidCheque"] ? "bank-details-form-element" : "bank-details-form-empty"}>
                                <Input
                                  type={"file"}
                                  name="bankVoidCheque"
                                  folderPath="verification-document"
                                  error={errors["bankVoidCheque"]}
                                  value={bankVoidCheque ? bankVoidCheque : ""}
                                  acceptedFileTypes={".jpg,.jpeg,.png,.webp,.heic,.pdf"}
                                  placeholder={
                                    <p className="file-upload-prompt">
                                      Select from computer (or) Drag and Drop to upload void cheque or direct deposit form (first page
                                      only).
                                    </p>
                                  }
                                  onChange={this.handleDocumentUpload}
                                />
                                <span className="accepted-file-type">*Accepted files types: .png, .jpeg, .webp, .heic, .jpg, .pdf</span>
                              </div>
                            </div>
                            {selectedAppStatus === "Share Banking Details" ? (
                              <CheckBoxList
                                onChange={this.onShareBankingDetailsChangeHandler}
                                errors={errors}
                                items={[
                                  {
                                    checked: shareBankDetailsAcceptanceCheckList["shareBankDetailsAcceptance1"],
                                    name: "shareBankDetailsAcceptance1",
                                    title: <p>I represent and warrant that I have authority to bind the PAYEE.</p>,
                                  },
                                  // {
                                  //   checked: shareBankDetailsAcceptanceCheckList["shareBankDetailsAcceptance2"],
                                  //   name: "shareBankDetailsAcceptance2",
                                  //   title: <p>I acknowledge that the Project Incentive payment will be available to the PAYEE for a period of six (6) months from the date of payment. In the event the Project Incentive payment is not claimed within six (6) months, the payment may be forfeited.</p>,
                                  // },
                                ]}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          <div className="bank-details">
                            <div className="detail">
                              <label>Name on Bank Account:</label>
                              <span>
                                {differentNameOnBankAccount
                                  ? payeeName
                                  : selectedAppData.payeeInfo
                                  ? selectedAppData.payeeInfo.payeeName
                                  : ""}
                              </span>
                            </div>
                            <div className="detail">
                              <label>Bank Account Number:</label>
                              <span>{bankAccountNumber}</span>
                            </div>
                            <div className="detail">
                              <label>Institution Code:</label>
                              <span>{bankInstitutionCode.label}</span>
                            </div>
                            <div className="detail">
                              <label>Transit Code:</label>
                              <span>
                                {bankTransitCode.label} - {bankTransitCode.data.civicAddress}
                              </span>
                            </div>
                            <div className="detail">
                              <label>Upload Verification Document:</label>
                              <span>
                                {
                                  <div className="void-cheque-detail">
                                    <div className="document-name">{bankVoidCheque.name}</div>
                                    <div className="document-view" onClick={() => this.viewDocument(bankVoidCheque)}>
                                      <ViewIcon width="25" height="25" />
                                    </div>
                                  </div>
                                }
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="button-set ">
                        <Button className="inversed-btn" title={"Go Back"} uppercase onClick={() => this.handleGoBack(modalTransition)} />
                        <Button
                          className="submit-details"
                          title={selectedAppStatus === "Share Banking Details" ? "Submit Details" : "Update Details"}
                          uppercase
                          onClick={() => this.handleConfirmBankDetails(modalTransition)}
                        />
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="request-eft-payment">
                        <p>
                          Your request for a EFT Transfer of the incentive amount for the application(ID: #ESB-{selectedAppData.appNo}) has
                          been successfully submitted.
                        </p>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              ) : (
                <Loader />
              )}
            </Modal>
            <Modal
              HeaderText={passwordChanged ? "PASSWORD CHANGED" : "KEEP YOUR ACCOUNT SECURE"}
              IsOpen={this.state.changePasswordModalOpen}
              handleCloseModal={() => {
                this.openChangePassword(false);
                this.clearChangePassword();
              }}
              loading={passwordChanging}
            >
              <p>{passwordChanged ? "Successfully changed your password" : "Change the password to access your account securely."}</p>
              {passwordChanged ? (
                <Button
                  title={"CLOSE"}
                  uppercase
                  onClick={() => {
                    this.openChangePassword(false);
                    this.clearChangePassword();
                  }}
                />
              ) : (
                <React.Fragment>
                  <div className="password-inputs">
                    <Input
                      label={"Current Password"}
                      value={oldPassword}
                      name="oldPassword"
                      type="password"
                      error={changePasswordError || errors["oldPassword"]}
                      onChange={this.onChangeHandler}
                      placeholder={"Type your current password"}
                    />
                    <Input
                      label={"New Password"}
                      value={newPassword}
                      type="password"
                      name="newPassword"
                      successMsg={passwordValid ? "This password passes the requirements" : null}
                      error={errors["newPassword"]}
                      onChange={this.onPasswordChangeHandler}
                      placeholder={"Type your new password"}
                      showExtra
                    />
                    <Input
                      label={"Confirm Password"}
                      value={confirmNewPassword}
                      type="password"
                      name="confirmNewPassword"
                      error={errors["confirmNewPassword"]}
                      onChange={this.onPasswordChangeHandler}
                      placeholder={"Type your new password again"}
                    />
                  </div>
                  <Button title={"RESET PASSWORD"} uppercase onClick={this.submitPasswordChange} />
                </React.Fragment>
              )}
            </Modal>
            <Modal
              HeaderText={"START POST-PROJECT APPLICATION"}
              IsOpen={openPostApplicationModal}
              handleCloseModal={() => this.setOpenPOSTApplicationModal(false)}
            >
              <PostProjectReview history={this.props.history} closeModal={() => this.setOpenPOSTApplicationModal(false)} />
            </Modal>
            <Modal
              HeaderText={"START A NEW APPLICATION"}
              IsOpen={applicationModalOpen}
              handleCloseModal={() => this.toggleApplicationModal(false)}
            >
              <div>
                <div className="choose-program">
                  <p>Choose a program to apply</p>
                  <div className="program-button-set">
                    <div className={`programs-btn ${stream === "esb" ? "background-fill" : ""}`} onClick={() => this.setStream("esb")}>
                      <p>Energy Savings for Business</p>
                    </div>
                    {/* <div className={`programs-btn ${stream === "ces" ? "background-fill" : ""}`} onClick={() => this.setStream("ces")}>
                      <p>Comprehensive Energy Savings</p>
                    </div> */}
                  </div>
                  <div className="radio-options">
                    <div>
                      <input
                        className="radio"
                        type="radio"
                        id="esb"
                        name="stream"
                        value="esb"
                        checked={stream === "esb"}
                        onClick={(e) => this.handleRadioOption(e)}
                      />
                      <label for="esb">Energy Savings for Business</label>
                    </div>
                    <br />
                   {/*  <div>
                      <input
                        className="radio"
                        type="radio"
                        id="ces"
                        name="stream"
                        value="ces"
                        checked={stream === "ces"}
                        onClick={(e) => this.handleRadioOption(e)}
                      />
                      <label for="ces">Comprehensive Energy Savings</label>
                    </div> */}
                  </div>
                </div>
                <div className="button-set">
                  <Button title={"GO BACK"} uppercase className="inversed-btn" onClick={() => this.toggleApplicationModal(false)} />
                  <Button title={"START NEW APPLICATION"} uppercase onClick={() => this.startNewApplication(stream)} />
                </div>
              </div>
            </Modal>
            <p className="welcome-message">
              Welcome, <span>{user.displayName}</span>
            </p>
            {noticeConfigShown ? (
              <p className="subtitle-message flashing-message" dangerouslySetInnerHTML={{ __html: noticeConfig.message }} />
            ) : (
              ""
            )}
            {/* <p
              className="subtitle-message flashing-message"
              dangerouslySetInnerHTML={{ __html: "New measures for Waste Energy Recovery and small CHP projects are live." }}
            /> */}
            {this.showMessage()}
            {this.state.userInfo ? (
              <React.Fragment>
                <OverviewCard
                  onClickChangePassword={() => this.openChangePassword(true)}
                  AccountDetails={{ ...this.state.userInfo, email: user.email }}
                />
                {userInfo.accountCategory.toLowerCase() === "customer" ? (
                  (userInfo.status === "Participating" || userInfo.status === "Flagged for QA/QC") &&
                  userInfo.status.toLowerCase() !== "suspended" &&
                  userInfo.status.toLowerCase() !== "inactive" ? (
                    <FloatingAppButton onClick={() => this.toggleApplicationModal(true)} showButtons={preapprovedApps.length > 0}>
                      <Button title={"INITIATE CHANGE REQUEST"} uppercase onClick={() => this.setOpenChangeRequestModal(true)} />
                      <Button title={"START NEW APPLICATION"} uppercase onClick={() => this.toggleApplicationModal(true)} />
                      <Button title={"START POST-PROJECT"} uppercase onClick={() => this.setOpenPOSTApplicationModal(true)} />
                    </FloatingAppButton>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                <ApplicationOverviewCard
                  onRef={(ref) => (this.applicationOverviewCardChild = ref)}
                  setShowAppPaymentPinModalOpen={this.setShowAppPaymentPinModalOpen}
                  setBankingDetailsModalOpen={this.setBankingDetailsModalOpen}
                  loadingApplications={loadingApplications}
                  appStatuses={appStatuses}
                  statusConfig={configData && configData.appStatuses}
                  history={this.props.history}
                  updateApplication={this.props.updateApplication}
                  currentStage={this.state.currentStage}
                  applicationList={applicationList}
                  applicationListByStatus={applicationListByStatus}
                  AccountDetails={{ ...this.state.userInfo, email: user.email }}
                  onStageChange={this.onStageChange}
                />
              </React.Fragment>
            ) : (
              <Loader />
            )}
          </div>
        ) : (
          <Loader />
        )}
      </InternalLayout>
    );
  }
}

function mapState(state) {
  const {
    configData,
    passwordChanging,
    passwordChanged,
    changePasswordError,
    resendingEmailError,
    emailResent,
    resendingEmail,
    userEmailVerified,
    userInfo,
  } = state.authentication;
  const {
    applicationList,
    loadingApplications,
    applicationListByStatus,
    loadingApplicationsByStatus,
    preapprovedApps,
    loadingPreapprovedApps,
    paymentPinDetails,
    documentList,
  } = state.application;
  return {
    applicationListByStatus,
    configData,
    applicationList,
    passwordChanging,
    loadingApplications,
    passwordChanged,
    changePasswordError,
    resendingEmailError,
    emailResent,
    resendingEmail,
    userEmailVerified,
    userInfo,
    loadingApplicationsByStatus,
    preapprovedApps,
    loadingPreapprovedApps,
    paymentPinDetails,
    documentList,
  };
}

const actionCreators = {
  loadConfig: userActions.getAdminConfig,
  loadUserDetails: userActions.getUserDetails,
  changePassword: userActions.changePassword,
  verifyEmail: userActions.verifyEmail,
  updateUserDetails: userActions.updateUserDetails,
  clearChangePassword: userActions.clearChangePassword,
  logout: userActions.logout,
  loadApplications: appActions.getApplicationsForParticipant,
  loadApplicationsByStatus: appActions.getApplicationsForParticipantByStatus,
  updateApplication: appActions.updateApplication,
  getPreapprovedApps: appActions.getPreapprovedApplications,
  createDocument: appActions.createAppDocument,
  deleteDocument: appActions.deleteAppDocument,
  getAppDocuments: appActions.getAppDocuments,
  getPaymentPin: userActions.getPaymentPin,
};

export default connect(mapState, actionCreators)(AccountOverview);
