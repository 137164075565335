import React from 'react';
import { connect } from 'react-redux';
import Button from '../../Common/Button/Button';
import { Link, Redirect } from 'react-router-dom';
import { AuthContext } from '../../../firebaseAuthContext';
import Firebase from '../../../helpers/Firebase';
import {utils} from '../../../helpers/utils';
import Input from '../../Common/Input/Input';
import Loader from '../../../images/Loader.svg';
import VerifiedTick from '../../../images/VerifiedTick.svg';
import './AccountManagement.css';
import queryString from 'query-string';
import {userService} from '../../../services/userService';

class AccountManagement extends React.Component{
  static contextType = AuthContext;

  constructor(props){
      super(props);
      let qParams = queryString.parse(this.props.location.search);
      this.state = {
          contentLoaded : false,
          email:'',
          title:'',
          subtitle:'',
          expired : false,
          completed:false,
          loggedIn : false,
          qParams,
          passwordValid: false,
          newPassword: '',
          confirmNewPassword: '',
          errors: {}
      }
  }

  setLoggedIn = ()=>{
      this.setState({loggedIn : true});
  }

  handleResetPassword = (actionCode, continueUrl, lang)=>{
    const mainThis = this;
    const urlSearch = continueUrl.split("?")[1];
    const {emailId, firstName } = queryString.parse(urlSearch);

    var accountEmail;
    let newPassword = '';
    // Verify the password reset code is valid.
    Firebase.auth.verifyPasswordResetCode(actionCode).then(async function(email) {
      var accountEmail = email;
      mainThis.setState({
          contentLoaded:true,
          email,
          title:`KEEP YOUR ACCOUNT SECURE`,
          subtitle:'Reset your password to access your account securely',
          completed:false
      });
      // TODO: Show the reset screen with the user's email and ask the user for
      // the new password.
  
  
    }).catch((error) => {
      mainThis.setState({
          contentLoaded:true, 
          completed:false,
          expired:true, 
          title : 'OOPS! THIS LINK HAS EXPIRED',
          subtitle: 'The email id verification or password reset links expire within 24 hours for security reasons. Please login to your account to resend a new verification link.'
        });
    // Some error occurred, you can inspect the code: error.code
    // Common errors could be invalid email and invalid or expired OTPs.
    });
  }

  handleVerifyEmail = async (actionCode, continueUrl, lang)=>{
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    // Try to apply the email verification code.
    const mainThis = this;
    const urlSearch = continueUrl.split("?")[1];
    const {emailId, firstName } = queryString.parse(urlSearch);
    Firebase.auth.applyActionCode(actionCode).then(async function(_) {
    // Email address has been verified.
    // Clear email from storage.
    window.localStorage.removeItem('emailForVerification');
    if(Firebase.auth.currentUser){
      await Firebase.auth.currentUser.reload();
    }

    await userService.checkEmailVerification(emailId).then(()=>{

    }).catch((error)=>{
      console.log('Checking email verification error', error);
    });

    mainThis.setState({
        contentLoaded:true,
        email:emailId,
        title:`${firstName},`,
        subtitle:'Please wait for a moment as we re-direct you to your account page.',
        completed:true
    },()=>{
        setTimeout(mainThis.setLoggedIn, 5000);
    });

    // TODO: Display a confirmation message to the user.
    // You could also provide the user with a link back to the app.

    // TODO: If a continue URL is available, display a button which on
    // click redirects the user back to the app via continueUrl with
    // additional state determined from that URL's parameters.
    }).catch((error) => {
      mainThis.setState({
          contentLoaded:true, 
          completed:false,
          expired:true, 
          title : 'OOPS! THIS LINK HAS EXPIRED',
          subtitle: 'The email id verification links expire within 24 hours for security reasons. Please login to your account to resend a new verification link.'
        });
    // Some error occurred, you can inspect the code: error.code
    // Common errors could be invalid email and invalid or expired OTPs.
  });
  }

  componentDidMount(){
    const {qParams} = this.state;
    const {mode,oobCode,continueUrl,lang} = qParams;
    // Confirm the link is a sign-in with email link.
    
    // Handle the user management action.
  switch (mode) {
    case 'resetPassword':
      // Display reset password handler and UI.
      this.handleResetPassword(oobCode, continueUrl, lang);
      break;
    // case 'recoverEmail':
    //   // Display email recovery handler and UI.
    //   handleRecoverEmail(Firebase.auth, actionCode, lang);
    //   break;
    case 'verifyEmail':
      // Display email verification handler and UI.
      this.handleVerifyEmail(oobCode, continueUrl, lang);
      break;
    default:
      // Error: invalid mode.
  }
  }


  onPasswordChangeHandler = (name, value) => {
    const { newPassword, confirmNewPassword, errors } = this.state;
    delete errors[name];
    if(utils.isNullOrEmpty(value)){
      let obj = {
            [name]: value
       };
       if(name === 'newPassword'){
            obj.passwordValid = false;
       }
       obj.errors = errors;
      this.setState(obj);
    } else {
        if(name === 'newPassword'){
            let passwordValid = false;
            if(value === confirmNewPassword){
                delete errors.confirmNewPassword;
            } else {
                if(!utils.isNullOrEmpty(confirmNewPassword)){
                  errors.confirmNewPassword= 'Password does not match';
                }
            }
            if(utils.validatePassword(value)){
                passwordValid = true;
            } else {
                errors.newPassword = 'Password does not meet requirements.';
            }
            this.setState({
              [name]: value,
              errors,
              passwordValid
          });
        } else {
            if(value === newPassword){
            } else {
                errors.confirmNewPassword= 'Password does not match';
            }
            this.setState({
              [name]: value,
              errors
          });
        }
    }
  }

  validatePasswordForm = () => {
    const { newPassword, confirmNewPassword, errors } = this.state;
    let errorCount = 0;

    if (!utils.isNullOrEmpty(newPassword)) {
      if (!utils.validatePassword(newPassword)) {
        errors.newPassword = 'Password does not meet requirements.';
        errorCount++;
      }
    } else {
      errors.newPassword = 'Password is required.'
    }

    if (!utils.isNullOrEmpty(confirmNewPassword)) {
      if (newPassword !== confirmNewPassword) {
        errors.confirmNewPassword = 'Passwords do not match.';
        errorCount++;
      }
    } else {
      errors.confirmNewPassword = 'Please confirm password.';
      errorCount++;
    }

    if (errorCount > 0) {
      this.setState({ errors });
    }

    return errorCount <= 0;
  }

  submitPasswordReset=()=>{
      const {newPassword,email} = this.state;
      const {oobCode} = this.state.qParams;
      const mainThis = this;
      if(this.validatePasswordForm()){
        // Save the new password.
        Firebase.auth.confirmPasswordReset(oobCode, newPassword).then(async function(resp) {
          // Password reset has been confirmed and new password updated.
          await userService.checkEmailVerification(email).then(()=>{
          }).catch((error)=>{
            console.log('Checking email verification error', error);
          });
          if(Firebase.auth.currentUser){
            await Firebase.auth.currentUser.reload();
          }
          //Firebase Unblocking User
          await userService.unblockUserAccount({email: email, program: "esb"});
          mainThis.setState({
            contentLoaded:true,
            email:'',
            title:`DONE!`,
            subtitle:`Your password has been set successfully. Now you can access your account securely using your new password.<br/><br/>Please give us a moment to finish setting up your account and redirect you to your account page.`,
            completed:true
        },()=>{
            setTimeout(mainThis.setLoggedIn, 5000);
        });
          // TODO: Display a link back to the app, or sign-in the user directly
          // if the page belongs to the same domain as the app:
          // auth.signInWithEmailAndPassword(accountEmail, newPassword);
    
          // TODO: If a continue URL is available, display a button which on
          // click redirects the user back to the app via continueUrl with
          // additional state determined from that URL's parameters.
        }).catch(function(error) {
          // Error occurred during confirmation. The code might have expired or the
          // password is too weak.
        });
      }
  }

  renderPasswordResetForm(){
    const { newPassword, confirmNewPassword, errors, passwordValid } = this.state;
    return  <React.Fragment><div className="password-inputs">
    <Input
      label={'Enter Password'}
      value={newPassword}
      type='password'
      name='newPassword'
      successMsg={passwordValid ? 'This password passes the requirements' : null}
      error={errors['newPassword']}
      onChange={this.onPasswordChangeHandler}
      placeholder={'Set your new Password'}
      showExtra
    />
    <Input
      label={'Confirm Password'}
      value={confirmNewPassword}
      type='password'
      name='confirmNewPassword'
      error={errors['confirmNewPassword']}
      onChange={this.onPasswordChangeHandler}
      placeholder={'Type your password again'}
    />
    <div className="button-set">
    <Button
      title={'Set Password'}
      uppercase
      onClick={this.submitPasswordReset}
    />
    </div>
  </div>

</React.Fragment>;
}

  renderVerified(){
      return <div className='button-container'>
      <p className='btn-label'>Auto-redirect taking too long?</p>
      <Link to='/'>
      <Button
      title={'Go to Login Page'}
      uppercase
      />
      </Link>
      </div>
  }

  renderExpired(){
    return <Link to='/'>
        <Button
        title={'Log In'}
        uppercase
        />
    </Link>
}


  render(){
  const { contentLoaded, title, subtitle, loggedIn, completed, expired } = this.state;
  const {mode} = this.state.qParams;
  if(loggedIn){
      return <Redirect to='/'/>;
  }
  return <div className="verified-email-container">
            <div className="verified-box">
            {contentLoaded ? <div className="verified-content" style={{minHeight : '300px'}}>
                {mode === 'verifyEmail' ? <React.Fragment>
                {completed ? <img alt='checkmark' src={VerifiedTick}/> : ''}
                </React.Fragment> : ''}
                <p className="title-label">{title}</p>
                {(completed && mode === 'verifyEmail') ? <p className="subtitle-message">Your email id has been successfully verified</p> : ''}
                <p className="basic-message" dangerouslySetInnerHTML={{__html:subtitle}}/>
                {mode === 'verifyEmail' ? <React.Fragment>
                {completed ? this.renderVerified() : ''}
                {expired ? this.renderExpired() : ''}
                </React.Fragment> : ''}
                {(mode === 'resetPassword' && !completed) ? <React.Fragment>
                {expired ? this.renderExpired() : this.renderPasswordResetForm()}
                </React.Fragment> : ''}
                {(mode === 'resetPassword' && completed) ? <React.Fragment>
                {this.renderVerified()}
                </React.Fragment> : ''}
                </div>
                : <div className='loading-container'><img src={Loader} alt='loader'/></div>}
            </div>
    </div>
}

}

function mapState(state) {
  return state;
}

const actionCreators = {
}

export default connect(mapState, actionCreators)(AccountManagement);