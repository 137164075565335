import React from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../firebaseAuthContext';
import InternalLayout from '../../Common/InternalLayout';
import Button from '../../Common/Button/Button';
import Loader from '../../Common/Loader/Loader';
import './ApplicationSentForReview.css';
import { applicationService } from '../../../services/applicationService';
import { userService } from '../../../services/userService';
class ApplicationSentForReview extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      applicationId: props.match.params.appid,
      appData : null,
      loaded : false    
    }
  }

  componentDidMount(){
    applicationService.getApplication(this.state.applicationId).then((appData)=>{
      this.setState({
        appData,
        loaded : true
      });
    });
  }

  render() {
    const { appData } = this.state;
    return <InternalLayout showHeader={true}>
      <div className="app-confirmation-wrapper">
      {this.state.loaded ? <React.Fragment>
        <h3>{appData.stage === 'Post-Project' ? 'Post-Project Application Submitted for Review' : appData.status === "Application Draft Under Review" ? 'Application Draft Submitted for Review' : 'Application Waitlisted'}</h3>
        {appData.stage === 'Post-Project' ? <p>Your application has been submitted for review.</p> : appData.status === "Application Draft Under Review" ? <p>A draft of your application has been submitted for review and pre-approval.</p> : <p>Due to high demand, all current funding made available through ERA’s Energy Savings for Business (ESB) program has been awarded.</p>}
        <p>Your application ID# is <span>{appData.stream === "ces" ? "CES" : "ESB"}-{appData.appNo}</span>.</p>
        {appData.stage === 'Post-Project' ? <p>You can view the status of your application at any time. You should expect an update from our team within the next two business days.</p> : appData.status === "Application Draft Under Review" ? <p>You can view the status of your application at any time. You should expect an update from our team within the next two business days.</p> : <p>Your application will be added to a waitlist and reviewed in the order of receipt. We will contact you if funding becomes available.</p>}
        {appData.stage === 'Pre-Project' && appData.status !== "Application Draft Under Review" ? <p><span>Note:</span> Any projects submitted to the waitlist are not guaranteed funding. You can view the status of your application at any time.</p> : ""}
        <div className="button-set">
          <Button
            title={'LOG OUT'}
            uppercase
            className="inversed-btn"
            onClick={userService.logout}
          />
          <Link to="/account">
            <Button
              title={'Go to Account Page'}
              uppercase
            />
          </Link>
        </div>
        </React.Fragment> : <Loader/>}
      </div>
    </InternalLayout>
  }
}

export default ApplicationSentForReview;