import {appActionTypes} from './appActionTypes';
import { utils } from '../../helpers/utils';
import {applicationService} from '../../services/applicationService';

export const appActions = {
    getActiveContractors,
    createApplication,
    updateApplication,
    getFacilitiesConfig,
    createFacility,
    getFacilitiesForCustomer,
    getApplicationsForParticipant,
    getApplicationsForParticipantByStatus,
    getMeasureCategories,
    getMeasureTypes,
    getMeasures,
    getMeasureConfig,
    setMeasureConfig,
    createAppMeasure,
    updateAppMeasure,
    reloadMeasure,
    getAppMeasures,
    deleteAppMeasure,
    setMeasureCategory,
    setMeasureType,
    setMeasure,
    clearMeasureDrawer,
    createAppDocument,
    deleteAppDocument,
    updateAppDocument,
    getAppDocuments,
    getApplicationsChangeRequestLogs,
    getPreapprovedApplications,
    getMeasureCategoriesCES,
    setEditMeasureDetails
};

function mapErrors(firebaseError){
    let errors = {};
    let { code, message } = firebaseError;
    if(code === 'auth/wrong-password'){
        errors['password'] = 'You have entered an invalid username or password';
    } else if(code === 'auth/user-not-found'){
        errors['password'] = 'You have entered an invalid username or password';
    } else {
        errors['password'] = message;
    }
     return errors;
}

function setEditMeasureDetails( editMeasureDetails ){
    console.log("In app",editMeasureDetails)
    return { type: appActionTypes.SET_EDIT_MEASURE_DETAILS, editMeasureDetails };
}

function setMeasureCategory( measureCategory ){
    return { type: appActionTypes.SET_MEASURE_CATEGORY, measureCategory };
}

function setMeasureType( measureType ){
    return { type: appActionTypes.SET_MEASURE_TYPE, measureType };
}
function setMeasure( measureCode ){
    return { type: appActionTypes.SET_MEASURE, measureCode };
}

function extractErrMessage(firebaseError){
    return firebaseError.message;
}

function setMeasureConfig(config){
    return { type: appActionTypes.SET_MEASURE_CONFIG, config };
}

function getMeasureCategories(){
    return dispatch => {
        dispatch(request());
        applicationService.getMeasureCategories()
            .then((data) => { 
                    dispatch(success(data.categories));
                }).catch((error) => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: appActionTypes.GET_MEASURE_CATEGORIES_REQUEST } }
    function success(data) { return { type: appActionTypes.GET_MEASURE_CATEGORIES_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.GET_MEASURE_CATEGORIES_FAILURE, error: extractErrMessage(error) } }
}

function getMeasureCategoriesCES(){
    return dispatch => {
        dispatch(request());
        applicationService.getMeasureCategoriesCES()
            .then((data) => { 
                    dispatch(success(data.categories));
                }).catch((error) => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: appActionTypes.GET_MEASURE_CATEGORIES_CES_REQUEST } }
    function success(data) { return { type: appActionTypes.GET_MEASURE_CATEGORIES_CES_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.GET_MEASURE_CATEGORIES_CES_FAILURE, error: extractErrMessage(error) } }
}

function getMeasureTypes(measureCategory, stream){
    return dispatch => {
        dispatch(request());
        applicationService.getMeasureTypes(measureCategory, stream)
            .then((data) => { 
                    dispatch(success(data));
                }).catch((error) => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: appActionTypes.GET_MEASURE_TYPES_REQUEST } }
    function success(data) { return { type: appActionTypes.GET_MEASURE_TYPES_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.GET_MEASURE_TYPES_FAILURE, error: extractErrMessage(error) } }
}

function getMeasures(measureType, stream){
    return dispatch => {
        dispatch(request());
        applicationService.getMeasures(measureType, stream)
            .then((data) => { 
                    dispatch(success(data));
                }).catch((error) => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: appActionTypes.GET_MEASURES_REQUEST } }
    function success(data) { return { type: appActionTypes.GET_MEASURES_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.GET_MEASURES_FAILURE, error: extractErrMessage(error) } }
}

function getMeasureConfig(stage, measureCode){
    return dispatch => {
        dispatch(request());
        applicationService.getMeasureConfig(stage, measureCode)
            .then((data) => { 
                    dispatch(success(data));
                }).catch((error) => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: appActionTypes.GET_MEASURE_CONFIG_REQUEST } }
    function success(data) { return { type: appActionTypes.GET_MEASURE_CONFIG_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.GET_MEASURE_CONFIG_FAILURE, error: extractErrMessage(error) } }
}

function getActiveContractors(){
    return dispatch => {
        dispatch(request());
        applicationService.getActiveContractors()
            .then((data) => { 
                    dispatch(success(data));
                }).catch((error) => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: appActionTypes.GET_ACTIVE_CONTRACTORS_REQUEST } }
    function success(data) { return { type: appActionTypes.GET_ACTIVE_CONTRACTORS_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.GET_ACTIVE_CONTRACTORS_FAILURE, error: extractErrMessage(error) } }
}

function getApplicationsChangeRequestLogs(appId) {
    return dispatch => {
        dispatch(request());
        applicationService.getAppChangeRequestLogs(appId).then((logs)=>{
            dispatch(success(logs));
        }).catch((error)=>{
            dispatch(failure("Error"));
        });
    };

    function request() { return { type: appActionTypes.GET_APPLICATION_CHANGE_REQ_LOGS_REQUEST } }
    function success(logs) { return { type: appActionTypes.GET_APPLICATION_CHANGE_REQ_LOGS_SUCCESS, logs } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATION_CHANGE_REQ_LOGS_FAILURE, error } }
}

function getApplicationsForParticipant(participantType, participantId, stage){
    return dispatch => {
        dispatch(request());
        applicationService.getApplicationsForParticipant(participantType, participantId, stage)
            .then((data) => { 
                    dispatch(success(data));
                }).catch((error) => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: appActionTypes.GET_APPLICATIONS_FOR_PARTICIPANT_REQUEST } }
    function success(apps) { return { type: appActionTypes.GET_APPLICATIONS_FOR_PARTICIPANT_SUCCESS, apps } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATIONS_FOR_PARTICIPANT_FAILURE, error: extractErrMessage(error) } }
}

function getPreapprovedApplications(uid){
    return dispatch => {
        dispatch(request());
        applicationService.getApplicationsForParticipantbyStatus("customer", uid, "Pre-Approval", "Application Submitted")
            .then((data) => { 
                    dispatch(success(data));
                }).catch((error) => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: appActionTypes.GET_PREAPPROVED_APPLICATIONS_REQUEST } }
    function success(apps) { return { type: appActionTypes.GET_PREAPPROVED_APPLICATIONS_SUCCESS, apps } }
    function failure(error) { return { type: appActionTypes.GET_PREAPPROVED_APPLICATIONS_FAILURE, error: extractErrMessage(error) } }
}

function getApplicationsForParticipantByStatus(participantType, participantId, stage,status){
    return dispatch => {
        dispatch(request());
        applicationService.getApplicationsForParticipantbyStatus(participantType, participantId, stage,status)
            .then((data) => { 
                    dispatch(success(data));
                }).catch((error) => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: appActionTypes.GET_APPLICATIONS_FOR_PARTICIPANT_BY_STATUS_REQUEST } }
    function success(apps) { return { type: appActionTypes.GET_APPLICATIONS_FOR_PARTICIPANT_BY_STATUS_SUCCESS, apps } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATIONS_FOR_PARTICIPANT_BY_STATUS_FAILURE, error: extractErrMessage(error) } }
}

function createFacility(data){
    const customerId = data.customerId;
    return dispatch => {
        dispatch(request());
        applicationService.createFacility(data)
            .then((result) => { 
                applicationService.getFacilitiesForCustomer(customerId)
                .then((facilities) => { 
                        dispatch(success(facilities));
                    }).catch((error) => {
                        dispatch(failure(error));
                    }
                );
                }).catch((error) => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: appActionTypes.CREATE_FACILITY_REQUEST } }
    function success(facilities) { return { type: appActionTypes.CREATE_FACILITY_SUCCESS, facilities} }
    function failure(error) { return { type: appActionTypes.CREATE_FACILITY_FAILURE, error: extractErrMessage(error) } }
}

function getFacilitiesForCustomer(customerId){
    return dispatch => {
        dispatch(request());
        applicationService.getFacilitiesForCustomer(customerId)
            .then((facilities) => { 
                    dispatch(success(facilities));
                }).catch((error) => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: appActionTypes.GET_FACILITIES_FOR_CUSTOMER_REQUEST } }
    function success(facilities) { return { type: appActionTypes.GET_FACILITIES_FOR_CUSTOMER_SUCCESS, facilities } }
    function failure(error) { return { type: appActionTypes.GET_FACILITIES_FOR_CUSTOMER_FAILURE, error: extractErrMessage(error) } }
}

function createApplication(data){
    return dispatch => {
        dispatch(request());
        applicationService.createApplication(data)
            .then((data) => { 
                    dispatch(success(data));
                }).catch((error) => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: appActionTypes.CREATE_APPLICATION_REQUEST } }
    function success(data) { return { type: appActionTypes.CREATE_APPLICATION_SUCCESS, applicationId : data.id } }
    function failure(error) { return { type: appActionTypes.CREATE_APPLICATION_FAILURE, error: extractErrMessage(error) } }
}

function updateApplication(appId, data){
    return dispatch => {
        dispatch(request());
        applicationService.updateApplication(appId, data)
            .then((setData) => { 
                applicationService.getApplication(appId).then((updatedData) => {
                    dispatch(success(updatedData));
                }).catch((error)=> {
                    dispatch(failure(error));
                })
                }).catch((error) => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: appActionTypes.UPDATE_APPLICATION_REQUEST } }
    function success(updatedData) { return { type: appActionTypes.UPDATE_APPLICATION_SUCCESS, updatedData } }
    function failure(error) { return { type: appActionTypes.UPDATE_APPLICATION_FAILURE, error: extractErrMessage(error) } }
}

function getFacilitiesConfig(){
    return dispatch => {
        dispatch(request());
        applicationService.getFacilitiesConfig()
            .then((data) => { 
                    dispatch(success(data));
                }).catch((error) => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: appActionTypes.GET_FACILITY_CONFIG_REQUEST } }
    function success(data) { return { type: appActionTypes.GET_FACILITY_CONFIG_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.GET_FACILITY_CONFIG_FAILURE, error: extractErrMessage(error) } }
}


function clearMeasureDrawer(drawerState = false){
    return { type : appActionTypes.CLEAR_MEASURE_DRAWER, drawerState };
}

function createAppMeasure(data){
    const { applicationId, applicationIdCES } = data;
    let appId = "";
    if (applicationId) {
        appId = applicationId;
    } else {
        appId = applicationIdCES;
    }
    return dispatch => {
        dispatch(request());
        applicationService.createAppMeasure(data)
            .then((data) => { 
                    applicationService.getAppMeasures(appId).then((measures)=>{
                        dispatch(success(measures));    
                    })
                }).catch((error) => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: appActionTypes.CREATE_APPLICATION_MEASURE_REQUEST } }
    function success(data) { return { type: appActionTypes.CREATE_APPLICATION_MEASURE_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.CREATE_APPLICATION_MEASURE_FAILURE, error: extractErrMessage(error) } }
}

function updateAppMeasure(measureId, data, stream){
    const { applicationId, applicationIdCES } = data;
    let appId = "";
    if (applicationId) {
        appId = applicationId;
    } else {
        appId = applicationIdCES;
    }
    let appIdToGet = stream === "ces" ? applicationIdCES : applicationId;
    return dispatch => {
        dispatch(request());
        applicationService.updateAppMeasure(measureId, data)
            .then((data) => { 
                    applicationService.getAppMeasures(appIdToGet).then((measures)=>{
                        dispatch(success(measures));    
                    })
                }).catch((error) => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: appActionTypes.UPDATE_APPLICATION_MEASURE_REQUEST } }
    function success(data) { return { type: appActionTypes.UPDATE_APPLICATION_MEASURE_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.UPDATE_APPLICATION_MEASURE_FAILURE, error: extractErrMessage(error) } }
}

function deleteAppMeasure(measureId, applicationId, program){
    return dispatch => {
        dispatch(request());
        applicationService.deleteAppMeasure(measureId, program)
            .then((data) => { 
                    applicationService.getAppMeasures(applicationId).then((measures)=>{
                        dispatch(success(measures));    
                    })
                }).catch((error) => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: appActionTypes.DELETE_APPLICATION_MEASURE_REQUEST } }
    function success(data) { return { type: appActionTypes.DELETE_APPLICATION_MEASURE_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.DELETE_APPLICATION_MEASURE_FAILURE, error: extractErrMessage(error) } }
}

function getAppMeasures(applicationId){
    if(utils.isNullOrEmpty(applicationId)){
        return dispatch => {
            dispatch(request());
            dispatch(success([]));
        };
    } else {
        return dispatch => {
            dispatch(request());
            applicationService.getAppMeasures(applicationId)
                .then((data) => { 
                        dispatch(success(data));
                    }).catch((error) => {
                        dispatch(failure(error));
                    }
                );
        };    
    }

    function request() { return { type: appActionTypes.GET_APPLICATION_MEASURES_REQUEST } }
    function success(data) { return { type: appActionTypes.GET_APPLICATION_MEASURES_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATION_MEASURES_FAILURE, error: extractErrMessage(error) } }
}

function reloadMeasure(stage, measureId){
    return dispatch => {
        dispatch(request());
        applicationService.getAppMeasure(measureId)
            .then((measureDoc) => { 
                    applicationService.getMeasureCategories().then((measureCategories)=>{
                        applicationService.getMeasureTypes(measureDoc.measureCategory.value).then((measureTypes)=>{
                            applicationService.getMeasures(measureDoc.measureType.value).then((measures)=>{
                                applicationService.getMeasureConfig(stage, measureDoc.measure.value).then((measureConfig)=>{
                                    measureConfig.fields = measureConfig.fields.map((field)=>{
                                        field.value = measureDoc[field.name];
                                        return field;
                                    });
                                    dispatch(success(measureDoc,measureCategories.categories,measureTypes,measures,measureConfig)); 
                                });
                            });
                        });
                    });
                }).catch((error) => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: appActionTypes.RELOAD_APPLICATION_MEASURE_REQUEST } }
    function success(measureDoc,measureCategories,measureTypes,measures,measureConfig) { return { type: appActionTypes.RELOAD_APPLICATION_MEASURE_SUCCESS, measureDoc,measureCategories,measureTypes,measures,measureConfig } }
    function failure(error) { return { type: appActionTypes.RELOAD_APPLICATION_MEASURE_FAILURE, error: extractErrMessage(error) } }
}


function createAppDocument(data){
    const { applicationId,stage} = data;
    return dispatch => {
        dispatch(request());
        applicationService.createAppDocument(data) 
            .then((data) => { 
                    applicationService.getAppDocuments(applicationId).then((documentList)=>{
                        dispatch(success(documentList));    
                    })
                }).catch((error) => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: appActionTypes.CREATE_APPLICATION_DOCUMENT_REQUEST } }
    function success(documentList) { return { type: appActionTypes.CREATE_APPLICATION_DOCUMENT_SUCCESS, documentList } }
    function failure(error) { return { type: appActionTypes.CREATE_APPLICATION_DOCUMENT_FAILURE, error: extractErrMessage(error) } }
}

function updateAppDocument(documentId, data){
    const { applicationId } = data;
    return dispatch => {
        dispatch(request());
        applicationService.updateAppDocument(documentId, data)
            .then((data) => { 
                applicationService.getAppDocuments(applicationId).then((documentList)=>{
                    dispatch(success(documentList));    
                })                }).catch((error) => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: appActionTypes.UPDATE_APPLICATION_DOCUMENT_REQUEST } }
    function success(documentList) { return { type: appActionTypes.UPDATE_APPLICATION_DOCUMENT_SUCCESS, documentList } }
    function failure(error) { return { type: appActionTypes.UPDATE_APPLICATION_DOCUMENT_FAILURE, error: extractErrMessage(error) } }
}

function deleteAppDocument(documentId, applicationId){
    return dispatch => {
        dispatch(request());
        applicationService.deleteAppDocument(documentId)
            .then((data) => { 
                    applicationService.getAppDocuments(applicationId).then((documentList)=>{
                        dispatch(success(documentList));    
                    })
                }).catch((error) => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: appActionTypes.DELETE_APPLICATION_DOCUMENT_REQUEST } }
    function success(documentList) { return { type: appActionTypes.DELETE_APPLICATION_DOCUMENT_SUCCESS, documentList } }
    function failure(error) { return { type: appActionTypes.DELETE_APPLICATION_DOCUMENT_FAILURE, error: extractErrMessage(error) } }
}

function getAppDocuments(applicationId){
    if(utils.isNullOrEmpty(applicationId)){
        return dispatch => {
            dispatch(request());
            dispatch(success([]));
        };
    } else {
        return dispatch => {
            dispatch(request());
            applicationService.getAppDocuments(applicationId)
                .then((data) => { 
                        dispatch(success(data));
                    }).catch((error) => {
                        dispatch(failure(error));
                    }
                );
        };
    }
    function request() { return { type: appActionTypes.GET_APPLICATION_DOCUMENTS_REQUEST } }
    function success(data) { return { type: appActionTypes.GET_APPLICATION_DOCUMENTS_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATION_DOCUMENTS_FAILURE, error: extractErrMessage(error) } }
}