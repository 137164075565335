
import Header from './Header/Header';
import Footer from './Footer/Footer';

function FrontendLayout(props) {
    let showFooter = true,showHeader = true;
    if(typeof props.showFooter !== 'undefined'){ showFooter = props.showFooter; }
    if(typeof props.showHeader !== 'undefined'){ showHeader = props.showHeader; }
    return(
       <div>
          {showHeader ? <Header history={props.history}/> : null }
             <div className={`inner-content${props.wrapperClass ? ` `+props.wrapperClass : '' }`}>{ props.children }</div>
          {showFooter ? <Footer /> : null }
       </div>
    );
}

export default FrontendLayout;