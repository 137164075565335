export const userActionTypes = {
    GET_ADMIN_CONFIG_REQUEST : 'GET_ADMIN_CONFIG_REQUEST',
    GET_ADMIN_CONFIG_SUCCESS : 'GET_ADMIN_CONFIG_SUCCESS',
    GET_ADMIN_CONFIG_FAILURE : 'GET_ADMIN_CONFIG_FAILURE',
    SHOW_REGISTER_MODAL : 'SHOW_REGISTER_MODAL',
    SHOW_SUBMIT_SUCCESS_MODAL : 'SHOW_SUBMIT_SUCCESS_MODAL',
    SHOW_SUBMIT_PROFILE_MODAL : 'SHOW_SUBMIT_PROFILE_MODAL',
    SHOW_LOGOUT_CONFIRMATION_MODAL : 'SHOW_LOGOUT_CONFIRMATION_MODAL',
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',
    UPDATE_USER_REQUEST: 'USERS_UPDATE_USER_REQUEST',
    UPDATE_USER_SUCCESS: 'USERS_UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILURE: 'USERS_UPDATE_USER_FAILURE',
    SUBMIT_PROFILE_REQUEST: 'USERS_SUBMIT_PROFILE_REQUEST',
    SUBMIT_PROFILE_SUCCESS: 'USERS_SUBMIT_PROFILE_SUCCESS',
    SUBMIT_PROFILE_FAILURE: 'USERS_SUBMIT_PROFILE_FAILURE',
    CHECK_USER_EXISTS: 'USERS_CHECK_USER_EXISTS',
    CHANGE_PASSWORD_REQUEST : 'USERS_CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS : 'USERS_CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE : 'USERS_CHANGE_PASSWORD_FAILURE',
    CHANGE_PASSWORD_CLEAR : 'USERS_CHANGE_PASSWORD_CLEAR',
    LOGIN_FORM_RESET_CLEAR:'USERS_LOGIN_FORM_RESET_CLEAR',
    EMAIL_VERIFICATION_REQUEST: 'USERS_EMAIL_VERIFICATION_REQUEST',
    EMAIL_VERIFICATION_SUCCESS: 'USERS_EMAIL_VERIFICATION_SUCCESS',
    EMAIL_VERIFICATION_FAILURE: 'USERS_EMAIL_VERIFICATION_FAILURE',
    PASSWORD_RESET_REQUEST: 'USERS_PASSWORD_RESET_REQUEST',
    PASSWORD_RESET_SUCCESS: 'USERS_PASSWORD_RESET_SUCCESS',
    PASSWORD_RESET_FAILURE: 'USERS_PASSWORD_RESET_FAILURE',
    PASSWORD_RESET_CLEAR: 'USERS_PASSWORD_RESET_CLEAR',
    LOGIN_SET_ERROR : 'USERS_LOGIN_SET_ERROR',
    LOGIN_SET_FIELD : 'USERS_LOGIN_SET_FIELD',
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    LOGOUT: 'USERS_LOGOUT',
    GET_USER_DETAILS_REQUEST: 'USERS_GET_USER_DETAILS_REQUEST',
    GET_USER_DETAILS_SUCCESS: 'USERS_GET_USER_DETAILS_SUCCESS',
    GET_USER_DETAILS_FAILURE: 'USERS_GET_USER_DETAILS_FAILURE',
    // GET_PAYMENT_PIN_REQUEST: 'USERS_GET_PAYMENT_PIN_REQUEST',
    // GET_PAYMENT_PIN_SUCCESS: 'USERS_GET_PAYMENT_PIN_SUCCESS',
    // GET_PAYMENT_PIN_FAILURE: 'USERS_GET_PAYMENT_PIN_FAILURE', 
    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',
    SET_EMAIL_VERIFIED : 'USERS_SET_EMAIL_VERIFIED',
    SHOW_ADD_PARTICIPANT_MODAL: 'SHOW_ADD_PARTICIPANT_MODAL'
};