import React from "react";
import './TooltipInfo.css';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import InfoVector from '../../../images/InfoVector.svg';
import { withStyles } from '@material-ui/core/styles';

const HtmlTooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: '#048041',
        color: '#FFF',
        maxWidth: 230
    },
    arrow: {
        color: '#048041',
    }
}))(Tooltip);

const TooltipInfo = (props) => {
    const [open, setOpen] = React.useState(false);
    const handleTooltipClose = () => {
        setOpen(false);
    };
    
    const handleTooltipOpen = () => {
        setOpen(true);
    };
    let newPosition = props.position;
    if (props.dynamicPosition) {
        newPosition = window.innerWidth < 1025 ? "bottom" : "right";
    }
    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
        <HtmlTooltip
            title={<React.Fragment>
                {props.helpText}
            </React.Fragment>}
            open={open}
            placement={newPosition || 'right'} arrow>
            <img
            className={props.className || ''} 
            src={props.customIcon || InfoVector} 
            alt='Info' 
            onClick={handleTooltipOpen} 
            onMouseEnter={handleTooltipOpen}
            onMouseLeave={handleTooltipClose}
            />
        </HtmlTooltip>
        </ClickAwayListener>
    );
}

export default TooltipInfo;