import React from "react";
import Button from "../../Common/Button/Button";
import Input from "../../Common/Input/Input";
import "./ViewAppDetails.css";
import { connect } from "react-redux";
import { userActions } from "../../../redux/actions/userActions";
import { appActions } from "../../../redux/actions/appActions";
import { utils } from "../../../helpers/utils";
import LeftArrow from "../../../images/leftArrow.svg";
import { AuthContext } from "../../../firebaseAuthContext";
import InternalLayout from "../../Common/InternalLayout";
import TooltipInfo from "../../Common/TooltipInfo/TooltipInfo";
import Loader from "../../Common/Loader/Loader";
import ResponsiveTable from "../../Common/ResponsiveTable/ResponsiveTable";
import { ReactComponent as ViewIcon } from "../../../images/ViewIcon.svg";
import { userService } from "../../../services/userService";
import { applicationService } from "../../../services/applicationService";
import firebase from "../../../helpers/Firebase";
import Modal from "../../Common/Modal/Modal";
import ChangeReqLogs from "../../Common/ChangeReqLogs/ChangeReqLogs";
import ErrorNotFound from "../ErrorNotFound/ErrorNotFound";
import { Route, Redirect } from "react-router-dom";
import LegaleseContainer from "../../Common/LegaleseContainer/LegaleseContainer";
import RedInfoVector from "../../../images/RedInfoVector.svg";

class ViewAppDetails extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);

    this.state = {
      applicationId: props.match.params.id,
      submitAppModalOpen: false,
      agreementText: "",
      appReviewData: {},
      userInfo: null,
      editing: false,
      cancelModalOpen: false,
      deleteMeasureModalOpen: false,
      deleteDocumentModalOpen: false,
      submitForReviewModalOpen: false,
      unauthorisedAccess: false,
      linkedAppsToCES: [],
      addNoteModalOpen: false,
      slideIndex: 0,
      slideTotal: 6,
      dataLoaded: false,
      errors: {},
      documentsList: [],
      selectedFacility: {},
      selectedMeasure: {},
      openDrawer: false,
      opeModal: false,
      confirmingAppNote: false,
      appNoteSubmitting: false,
      appNoteSuccessfullySubmitted: false,
      payeeDetails: undefined,
      appNote: "",
      applicationObj: {
        stage: "Pre-Project",
        status: "Application Draft",
        facilityId: "",
        contractorId: "",
        contractor: "",
        contractorCanEdit: false,
        projectName: "",
        projectStartDate: "",
        projectCompletionDate: "",
        isReceivingOtherFunding: "",
        sourcesOfAllOtherFunding: "",
        totalAmountOfAllOtherFunding: "",
        amountOfEligibleExpenses: "",
        agreeToApplicationTerms: false,
      },
      participantAcceptanceCheckList: {
        participantAcceptance1: false,
        participantAcceptance2: false,
        participantAcceptance3: false,
        participantAcceptance4: false,
        participantAcceptance5: false,
        participantAcceptance6: false,
        participantAcceptance7: false,
        participantAcceptance8: false,
      },
    };
  }

  editApplication = () => {
    this.props.history.push(`/application/${this.props.match.params.id}`);
  };

  addNoteToApp = async () => {
    let { applicationId, appNote } = this.state;
    let data = {
      applicationId,
      customerNote: appNote,
    };
    this.setState(
      {
        appNoteSubmitting: true,
      },
      async () => {
        await applicationService.addCustomerNoteToApp(data);
        this.setState({
          appNoteSubmitting: false,
          appNoteSuccessfullySubmitted: true,
        });
      }
    );
  };

  verifyParticipantAcceptance = () => {
    const { participantAcceptanceCheckList, agreementText } = this.state;
    let errorCount = 0;
    let errors = {};
    for (let key in participantAcceptanceCheckList) {
      if (!participantAcceptanceCheckList[key]) {
        errors[key] = true;
        errorCount++;
      }
    }
    if (utils.isNullOrEmpty(agreementText)) {
      errors["agreementText"] = "You are required to type the above sentence to proceed";
      errorCount++;
    } else {
      if (
        agreementText.toLowerCase() !==
        "i represent and warrant to emissions reduction alberta that i have authority to bind the participant"
      ) {
        errors["agreementText"] = "You are required to type the exact sentence mentioned above to proceed";
        errorCount++;
      }
    }
    if (errorCount > 0) {
      this.setState({ errors }, () => {
        let allErrorRefs = document.querySelectorAll(".input-box-error,.error-box");
        if (allErrorRefs.length > 0) {
          let firstErrorRef = allErrorRefs[0];
          firstErrorRef.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
      });
    }
    return errorCount === 0;
  };

  submitApplication = () => {
    if (this.verifyParticipantAcceptance()) {
      this.setState({
        submitAppModalOpen: true,
      });
    }
  };

  finallySubmitApplication = () => {
    const { userInfo, applicationId, participantAcceptanceCheckList } = this.state;
    this.props.history.push(`/applicationsubmitted/${applicationId}`);
    let updateObj = {
      status: "Application Submitted",
      participantAcceptanceCheckList,
      actionTaker: `${userInfo.firstName} ${userInfo.lastName}`,
      actionNote: "",
    };
    this.props.updateApplication(applicationId, updateObj);
  };

  componentDidMount() {
    const appIdToLoad = this.props.match.params.id;
    userService.getUserDetails(firebase.auth.currentUser.uid, (success, userInfo) => {
      this.props.loadContractors();
      this.props.loadFacilityConfig();
      this.props.loadUserDetails();
      if (!utils.isNullOrEmpty(appIdToLoad)) {
        applicationService.getApplication(appIdToLoad).then(async (data) => {
          if (userInfo.uid === data.customerId || userInfo.uid === data.contractorId) {
            let appReviewData = null;
            let postProjectAppReviewData = null;
            try {
              appReviewData = await applicationService.getApplicationReview(appIdToLoad);
            } catch (err) {}
            if (data.stage === "Post-Project" || data.stage === "Payment") {
              try {
                postProjectAppReviewData = await applicationService.getPostProjectApplicationReview(appIdToLoad);
              } catch (err) {}
            }
            userService.getUserDetails(data.customerId, (success, customerData) => {
              this.props.loadFacilities(data.customerId);
              this.props.loadAppMeasures(appIdToLoad);
              this.props.getAppDocuments(appIdToLoad);
              this.props.getChangeReqLogs(appIdToLoad);
              let applicationObj = { ...data };
              let linkedAppsToCES =
                data.stream === "ces" && !utils.isNullOrEmpty(data.linkedESBApplications)
                  ? Object.keys(data.linkedESBApplications).map((ele) => {
                      return {
                        label: `ESB-${data.linkedESBApplications[ele]["appNo"]}`,
                        value: ele,
                      };
                    })
                  : [];
              // console.log("app object for payee", applicationObj);
              if (applicationObj.payeeAssigned === true) {
                userService.getUserDetails(applicationObj.payeeInfo.uid, (success, payeeDetails) => {
                  //  console.log("Payee data pulled===", payeeDetails);
                  this.setState({
                    payeeDetails: payeeDetails,
                  });
                });
              }
              let slideIndex = 0;
              if (userInfo.accountCategory === "contractor") {
                slideIndex = 2;
              }
              this.setState(
                {
                  appReviewData: appReviewData,
                  postProjectAppReviewData,
                  customerId: data.customerId,
                  customerData,
                  userInfo,
                  editing: true,
                  applicationObj,
                  slideIndex,
                  linkedAppsToCES,
                  dataLoaded: true,
                },
                () => {
                  window.scrollTo(0, 0);
                }
              );
            });
          } else {
            this.setState({ unauthorisedAccess: true });
          }
        });
      }
    });
  }

  handleChangeSlide = (slideIndex) => {
    if (slideIndex === 2) {
      this.props.loadFacilities(this.state.customerId);
    }
    this.setState({ slideIndex: slideIndex++, errors: {} });
  };

  onContractorChange = (contractor) => {
    const { applicationObj, errors } = this.state;
    delete errors["contractor"];
    this.setState({
      applicationObj: {
        ...applicationObj,
        contractor,
        contractorId: contractor.value,
      },
      errors,
    });
  };

  onContractorAccessChange = (val) => {
    const { applicationObj, errors } = this.state;
    delete errors["contractorCanEdit"];
    this.setState({
      applicationObj: {
        ...applicationObj,
        contractorCanEdit: val.value,
      },
      errors,
    });
  };

  onChangeHandler = (name, value) => {
    const { applicationObj, errors } = this.state;
    delete errors[name];
    this.setState({
      applicationObj: {
        ...applicationObj,
        [name]: value,
      },
      errors,
    });
  };

  viewDocument = (document) => {
    window.open(document.url);
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  onChecklistChangeHandler = (name, value) => {
    let { participantAcceptanceCheckList, errors } = this.state;
    participantAcceptanceCheckList[name] = value;
    delete errors[name];
    this.setState({
      participantAcceptanceCheckList: {
        ...participantAcceptanceCheckList,
      },
      errors,
    });
  };

  onAgreementChangeText = (name, value) => {
    let { errors } = this.state;
    delete errors["agreementText"];
    this.setState({
      agreementText: value,
      errors,
    });
  };

  confirmAppNote = () => {
    this.setState({
      confirmingAppNote: !this.state.confirmingAppNote,
    });
  };

  getTotalProjectCost = (list) => {
    const { applicationObj } = this.state;
    let cost = 0;
    list.map((measure) => {
      let measureTotalCost = 0;
      if (applicationObj.stage === "Post-Project") {
        measureTotalCost = +(measure["Actual Total Cost"] ? measure["Actual Total Cost"] : measure["Total Cost"]);
      } else {
        measureTotalCost = +measure["Total Cost"];
      }
      cost = cost + measureTotalCost;
    });
    return cost;
  };

  getESBAppDetails = (id, detail) => {
    const { linkedAppsToCES } = this.state;
    if (detail === "appNo") {
      let appNo = linkedAppsToCES && linkedAppsToCES.length && linkedAppsToCES.filter((ele) => ele.value === id);
      return appNo && appNo[0] ? `#${appNo[0].label}` : "";
    }
    if (detail === "projectCost") {
      let appNo = linkedAppsToCES && linkedAppsToCES.length && linkedAppsToCES.filter((ele) => ele.value === id);
      return appNo && appNo[0] ? appNo[0].projectCost : "";
    }
  };

  applicationSummary = () => {
    const { applicationObj, appReviewData, postProjectAppReviewData, linkedAppsToCES, payeeDetails, userInfo } = this.state;
    const { measureList, documentList, facilityList } = this.props;
    let showSummaries = false;
    let chosenFacility = null;
    let draftApproved = applicationObj.status === "Application Draft Approved" && applicationObj.stage === "Pre-Approval";
    let appSubmitted = applicationObj.status === "Application Submitted" && applicationObj.stage === "Pre-Approval";
    if (userInfo) {
      showSummaries = userInfo.accountCategory === "customer" || userInfo.accountCategory === "contractor";
    }

    facilityList.forEach((f) => {
      if (f.id === applicationObj.facilityId) {
        chosenFacility = f;
      }
    });
    let approvalNote = "";
    if (appReviewData && appReviewData.informationRequest) {
      approvalNote = appReviewData.informationRequest;
    }
    let maxVal = 250000.0;
    if (applicationObj && applicationObj["Reviewer Estimated Incentive"]) {
      maxVal = parseFloat(applicationObj["Reviewer Estimated Incentive"]);
      maxVal = parseFloat((maxVal * 1.1).toFixed(2)); // Set to 110% of original
    }

    //SET UP FACILITY TABLE
    let facilityObj = {
      headings: [
        { title: "Facility Name", value: "Facility Name" },
        { title: "Region", value: "Region" },
        { title: "Facility Address", value: "Facility Address" },
        { title: "Facility Ownership", value: "Facility Ownership" },
        { title: "Facility Type", value: "Facility Type" },
        { title: "Facility Owner Approval Obtained", value: "Facility Owner Approval Obtained" },
      ],
      rows: [
        {
          "Facility Name": chosenFacility ? chosenFacility.facilityName : "",
          Region: chosenFacility ? chosenFacility.facilityApplicableRegion.value : "",
          "Facility Address": chosenFacility
            ? `${chosenFacility.facilityAddressLine1.label}, ${
                chosenFacility.facilityAddressLine2 ? ", " + chosenFacility.facilityAddressLine2 : ""
              } ${chosenFacility.facilityCity} - ${chosenFacility.facilityPostalCode}`
            : "",
          "Facility Ownership": chosenFacility ? chosenFacility.facilityOwnership : "",
          "Facility Type": chosenFacility ? chosenFacility.facilityType.label : "",
          "Facility Owner Approval Obtained": chosenFacility ? chosenFacility.hasLandlordApprovedProject.label : "",
        },
      ],
    };

    //SET UP MEASURES TABLE
    let measureHeadings = [
      { title: "Measure", value: "measure" },
      { title: "Quantity", value: "quantity" },
      { title: "Estimated Emissions Reduction (Lifetime)", value: "estimatedEmissionsReduction" },
      {
        title:
          applicationObj.stage === "Post-Project" || applicationObj.stage === "Payment"
            ? "Pre-Approved Incentive ($)"
            : "Estimated Incentive",
        value:
          applicationObj.stage === "Post-Project" || applicationObj.stage === "Payment" ? "preApprovedIncentive" : "estimatedIncentive",
      },
    ];
    if (applicationObj.stream === "ces") {
      measureHeadings = [
        { title: "Measure", value: "measure" },
        { title: "Application ID", value: "applicationId" },
        { title: "Quantity", value: "quantity" },
        { title: "Estimated Emissions Reduction (Lifetime)", value: "estimatedEmissionsReduction" },
        {
          title:
            applicationObj.stage === "Post-Project" || applicationObj.stage === "Payment"
              ? "Pre-Approved Incentive ($)"
              : "Estimated Incentive",
          value:
            applicationObj.stage === "Post-Project" || applicationObj.stage === "Payment" ? "preApprovedIncentive" : "estimatedIncentive",
        },
        { title: "Project Cost", value: "projectCost" },
      ];
    }
    if (draftApproved || appSubmitted) {
      measureHeadings.push({ title: "Pre-Approved Incentive", value: "preApprovedIncentive" });
    }
    if (applicationObj.stage === "Post-Project" || applicationObj.stage === "Payment") {
      measureHeadings.push({ title: "Post-Project Applicable Incentive ($)", value: "postProjectApplicableIncentive" });
    }
    if (applicationObj.stage === "Payment") {
      measureHeadings.push({ title: "Recommended Incentive ($)", value: "postProjectApprovedIncentive" });
    }

    let measureRows = measureList.map((measure) => {
      return {
        measure: measure.measure.label,
        quantity: measure.Quantity,
        estimatedEmissionsReduction: `${utils.formatNumberWithCommas(utils.getTEM(measure))} tonnes`,
        estimatedIncentive: utils.formatAsCAD(utils.getTEMI(measure)),
        preApprovedIncentive:
          appReviewData && appReviewData.measuresReviewerEstimatedIncentive
            ? utils.formatAsCAD(appReviewData.measuresReviewerEstimatedIncentive[measure.id])
            : "",
        postProjectApplicableIncentive: utils.formatAsCAD(utils.getApplicableTEMI(measure)),
        postProjectApprovedIncentive:
          postProjectAppReviewData && postProjectAppReviewData.measuresPostProjectReviewerApprovedIncentive
            ? utils.formatAsCAD(postProjectAppReviewData.measuresPostProjectReviewerApprovedIncentive[measure.id])
            : "0.00",
        view: <ViewIcon className="view-eye-icon" width="24" height="15" onClick={() => this.viewMeasureDetails(measure)} />,
        projectCost: measure["Actual Total Cost"]
          ? utils.formatAsCAD(measure["Actual Total Cost"])
          : utils.formatAsCAD(measure["Total Cost"]),
        applicationId: utils.isNullOrEmpty(measure.applicationId)
          ? `#CES-${applicationObj.appNo}`
          : this.getESBAppDetails(measure.applicationId, "appNo"),
      };
    });

    let measureFooter = (
      <div className="responsive-table-row">
        <div className="responsive-table-details">Total</div>
        {applicationObj.stream === "ces" ? <div className="responsive-table-details"></div> : ""}
        <div className="responsive-table-details"></div>
        <div className="responsive-table-details">
          <label>Estimated Emissions Reduction (Lifetime)</label>
          <span>{utils.formatNumberWithCommas(utils.sumOfTEMs(measureList))} tonnes</span>
        </div>
        {applicationObj.stage === "Post-Project" || applicationObj.stage === "Payment" ? (
          <React.Fragment>
            <div className="responsive-table-details">
              <label>Pre-Approved Incentive ($)</label>
              <span>{utils.formatAsCAD(applicationObj["Reviewer Estimated Incentive"] || 0.0)}</span>
            </div>
            <div className="responsive-table-details">
              <label>Post-Project Applicable Incentive ($)</label>
              <span>
                {applicationObj.stream === "ces" ? "" : utils.formatAsCAD(utils.sumOfApplicableTEMIs(measureList, applicationObj))}
              </span>
              {applicationObj.stream === "esb" && parseFloat(utils.sumOfApplicableTEMIs(measureList, applicationObj)) >= maxVal ? (
                <div className="info-icon">
                  <TooltipInfo helpText={"The maximum applicable incentive is capped at 110% of the pre-approved incentive"} />
                </div>
              ) : (
                ""
              )}
            </div>
          </React.Fragment>
        ) : (
          <div className="responsive-table-details">
            <label>Estimated Incentive ($)</label>
            <span>{applicationObj.stream === "ces" ? "" : utils.formatAsCAD(utils.sumOfTEMIs(measureList, applicationObj.stream))}</span>
            {applicationObj.stream === "ces" ? (
              ""
            ) : parseFloat(utils.sumOfTEMIs(measureList, applicationObj.stream)) >= 250000 ? (
              <div className="info-icon">
                <TooltipInfo helpText={"Please note that the project incentive is capped at $250,000"} />
              </div>
            ) : (
              ""
            )}
          </div>
        )}
        {applicationObj.stream === "ces" ? (
          <div className="responsive-table-details">
            <label>Total Project Cost ($)</label> <span>{utils.formatAsCAD(this.getTotalProjectCost(measureList))}</span>
          </div>
        ) : null}
        {draftApproved || appSubmitted ? (
          <div className="responsive-table-details">
            <label>Estimated Incentive ($)</label>
            <span>{utils.formatAsCAD(applicationObj["Reviewer Estimated Incentive"] || 0.0)}</span>
          </div>
        ) : (
          ""
        )}
        {applicationObj.stage === "Payment" ? (
          <React.Fragment>
            <div className="responsive-table-details">
              <label>Recommended Incentive ($)</label>
              <span>{utils.formatAsCAD(applicationObj["PostProjectReviewerApprovedIncentive"] || 0.0)}</span>
            </div>
          </React.Fragment>
        ) : (
          ""
        )}
      </div>
    );

    //SET UP DOCUMENTS TABLE
    let documentHeadings = [
      { title: "Document Name", value: "docName" },
      { title: "Date of Upload", value: "dateOfUpload" },
      { title: "View", value: "view" },
    ];

    let documentRows = documentList.map((document) => {
      return {
        docName: document.name,
        dateOfUpload: utils.formatAmericanDate(document.createdAt),
        view: <ViewIcon className="view-eye-icon" width="24" height="15" onClick={() => this.viewDocument(document)} />,
      };
    });
    let nonBonusEligibileMeasureCategory = utils.isNullOrEmpty(applicationObj["Estimated Category CES Bonus"])
      ? {}
      : Object.values(applicationObj["Estimated Category CES Bonus"]).filter((F) => F["isMaxCategoryIncentive"])[0];
    let preApprovalCategoryBonus = {};
    if (applicationObj.stage === "Pre-Approval" && !utils.isNullOrEmpty(applicationObj["Pre-Approval Category Bonus"])) {
      preApprovalCategoryBonus = applicationObj["Pre-Approval Category Bonus"];
      nonBonusEligibileMeasureCategory = utils.isNullOrEmpty(preApprovalCategoryBonus)
        ? {}
        : Object.values(preApprovalCategoryBonus).filter((F) => F["isMaxCategoryIncentive"])[0];
    }

    let applicableCategoryBonus = {};
    if (applicationObj.stage === "Post-Project" && !utils.isNullOrEmpty(applicationObj["Post-Project Applicable Category CES Bonus"])) {
      applicableCategoryBonus = applicationObj["Post-Project Applicable Category CES Bonus"];
      nonBonusEligibileMeasureCategory = utils.isNullOrEmpty(applicableCategoryBonus)
        ? {}
        : Object.values(applicableCategoryBonus).filter((F) => F["isMaxCategoryIncentive"])[0];
    }

    let approvedCategoryBonus = {};
    if (applicationObj.stage === "Payment" && !utils.isNullOrEmpty(applicationObj["Approved Category Bonus"])) {
      approvedCategoryBonus = applicationObj["Approved Category Bonus"];
      nonBonusEligibileMeasureCategory = utils.isNullOrEmpty(approvedCategoryBonus)
        ? {}
        : Object.values(approvedCategoryBonus).filter((F) => F["isMaxCategoryIncentive"])[0];
    }

    return (
      <div className="form-sections application-summary">
        <div className="step-header-container">
          <div className="step-header-container-left">
            <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Application Summary" }}></h2>
          </div>
        </div>
        <div className="form-inner-section">
          <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Facility Details" }}></h2>
          {chosenFacility ? <ResponsiveTable headings={facilityObj.headings} rows={facilityObj.rows} /> : ""}
        </div>
        <div className="horizontal-bar"></div>
        <div className="form-inner-section">
          <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Measures Details" }}></h2>
          {measureList.length > 0 ? (
            <>
              <ResponsiveTable headings={measureHeadings} rows={measureRows} customFooter={measureFooter} />
              {applicationObj.stream === "ces" ? (
                applicationObj.stage === "Payment" ? (
                  <>
                    <div className="summary-header">Incentive Bonus</div>
                    <div className="responsive-table-container">
                      <div className="responsive-table-row responsive-table-header">
                        <div className="responsive-table-details">Category Name</div>
                        <div className="responsive-table-details">{"Approved Category Incentive ($)"}</div>
                        <div className="responsive-table-details">{"Final Bonus Eligibility"}</div>
                        <div className="responsive-table-details">{"Final Eligible Category Incentive ($)"}</div>
                        <div className="responsive-table-details">{"Approved Category Bonus ($)"}</div>
                      </div>
                      {Object.values(approvedCategoryBonus).map((bonusEle, index) => (
                        <div className={`responsive-table-row`} key={index}>
                          <div className="responsive-table-details">
                            <label>Category Name</label>
                            <span>{bonusEle.category}</span>
                          </div>
                          <div
                            className={`responsive-table-details ${
                              bonusEle.isOnlyPaidOutIncentive
                                ? "not-eligible"
                                : !utils.isNullOrEmpty(nonBonusEligibileMeasureCategory) &&
                                  nonBonusEligibileMeasureCategory["category"] === bonusEle.category
                                ? "not-eligible"
                                : bonusEle.sumOfSolarPVIncentive > 0
                                ? ""
                                : bonusEle["Final Bonus Eligibility"]
                                ? bonusEle["Final Eligible Category Incentive"] === 0 &&
                                  bonusEle["Approved Category Incentive"] > 0 &&
                                  bonusEle.sumOfSolarPVIncentive === 0
                                  ? "not-eligible"
                                  : ""
                                : "not-eligible"
                            }`}
                          >
                            <label>{"Approved Category Incentive ($)"}</label>
                            <span>
                              {!bonusEle["Approved Category Incentive"]
                                ? 0
                                : `${utils.formatNumberWithOnlyCommas(bonusEle["Approved Category Incentive"])}`}
                            </span>
                            {bonusEle.isOnlyPaidOutIncentive ? (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`The measures within this category are all paid out via ESB Program and are not eligible for an Incentive or Bonus.`}
                                />
                              </div>
                            ) : !utils.isNullOrEmpty(nonBonusEligibileMeasureCategory) &&
                              nonBonusEligibileMeasureCategory["category"] === bonusEle.category ? (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`${bonusEle.category} category has the highest incentive amongst all categories within this application and hence, is eligible to only receive the incentive without a bonus.`}
                                />
                              </div>
                            ) : bonusEle.sumOfSolarPVIncentive > 0 ? (
                              ""
                            ) : bonusEle["Final Bonus Eligibility"] ? (
                              ""
                            ) : (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`This ${bonusEle.category} category is currently not eligible because it does not meet the CES minimum incentive requirements.`}
                                />
                              </div>
                            )}
                          </div>
                          <div className="responsive-table-details">
                            <label>Final Bonus Eligibility</label>
                            <span>{`${bonusEle["Final Bonus Eligibility"] ? "Yes" : "No"}`}</span>
                          </div>
                          <div
                            className={`responsive-table-details ${
                              bonusEle.category === "On-Site Generation and Renewables" && bonusEle.sumOfSolarPVIncentive > 0
                                ? "not-eligible"
                                : ""
                            }`}
                          >
                            <label>{"Final Eligible Category Incentive ($)"}</label>
                            <span>
                              {!bonusEle["Final Eligible Category Incentive"]
                                ? 0
                                : `${utils.formatNumberWithOnlyCommas(bonusEle["Final Eligible Category Incentive"])}`}
                            </span>
                            {bonusEle.category === "On-Site Generation and Renewables" && bonusEle.sumOfSolarPVIncentive > 0 ? (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`Solar PV measures are not eligible for a bonus within CES.`}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="responsive-table-details">
                            <label>{"Approved Category Bonus ($)"}</label>
                            <span>
                              {!bonusEle["Approved Category Bonus"]
                                ? 0
                                : `${utils.formatNumberWithOnlyCommas(bonusEle["Approved Category Bonus"])}`}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="summary-header">Total Incentive Bonus</div>
                    <div className="responsive-table-container">
                      <div className="responsive-table-row responsive-table-header">
                        <div className="responsive-table-details">{"Gross Approved Incentive ($)"}</div>
                        <div className="responsive-table-details">{"Total Approved Bonus ($)"}</div>
                        <div className="responsive-table-details">{"Total Approved Incentive ($)"}</div>
                      </div>
                      <div className={`responsive-table-row`}>
                        <div className="responsive-table-details">
                          <label>{"Gross Approved Incentive ($)"}</label>
                          <span>
                            {!applicationObj["Gross Approved Incentive"]
                              ? 0
                              : `${utils.formatNumberWithOnlyCommas(applicationObj["Gross Approved Incentive"])}`}
                          </span>
                        </div>
                        <div className="responsive-table-details">
                          <label>{"Total Approved Bonus ($)"}</label>
                          <span>
                            {!applicationObj["Total Approved Bonus"]
                              ? 0
                              : `${utils.formatNumberWithOnlyCommas(applicationObj["Total Approved Bonus"])}`}
                          </span>
                        </div>
                        <div className="responsive-table-details">
                          <label>{"Total Approved Incentive ($)"}</label>
                          <span>{`${utils.formatNumberWithOnlyCommas(applicationObj.PostProjectReviewerApprovedIncentive)}`}</span>
                          {applicationObj["Post-Project Approved Cap Notice"] ? (
                            <div className="info-icon custom-info-height">
                              <TooltipInfo dynamicPosition={true} helpText={applicationObj["Post-Project Approved Cap Notice"]} />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : applicationObj.stage === "Post-Project" ? (
                  <>
                    <div className="summary-header">Incentive Bonus</div>
                    <div className="responsive-table-container">
                      <div className="responsive-table-row responsive-table-header">
                        <div className="responsive-table-details">Category Name</div>
                        <div className="responsive-table-details">{"Applicable Category Incentive ($)"}</div>
                        <div className="responsive-table-details">{"Applicable Bonus Eligibility"}</div>
                        <div className="responsive-table-details">{"Applicable Eligible Category Incentive ($)"}</div>
                        <div className="responsive-table-details">{"Applicable Category Bonus ($)"}</div>
                      </div>
                      {Object.values(applicableCategoryBonus).map((bonusEle, index) => (
                        <div className={`responsive-table-row`} key={index}>
                          <div className="responsive-table-details">
                            <label>Category Name</label>
                            <span>{bonusEle.category}</span>
                          </div>
                          <div
                            className={`responsive-table-details ${
                              bonusEle.isOnlyPaidOutIncentive
                                ? "not-eligible"
                                : !utils.isNullOrEmpty(nonBonusEligibileMeasureCategory) &&
                                  nonBonusEligibileMeasureCategory["category"] === bonusEle.category
                                ? "not-eligible"
                                : bonusEle.sumOfSolarPVIncentive > 0
                                ? ""
                                : bonusEle.bonusEligibilityCES
                                ? bonusEle.finalCESCategoryBonusEligibleSum === 0 &&
                                  bonusEle.categoryIncentiveCES > 0 &&
                                  bonusEle.sumOfSolarPVIncentive === 0
                                  ? "not-eligible"
                                  : ""
                                : "not-eligible"
                            }`}
                          >
                            <label>{"Applicable Category Incentive ($)"}</label>
                            <span>
                              {!bonusEle.categoryIncentiveCES ? 0 : `${utils.formatNumberWithOnlyCommas(bonusEle.categoryIncentiveCES)}`}
                            </span>
                            {bonusEle.isOnlyPaidOutIncentive ? (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`The measures within this category are all paid out via ESB Program and are not eligible for an Incentive or Bonus.`}
                                />
                              </div>
                            ) : !utils.isNullOrEmpty(nonBonusEligibileMeasureCategory) &&
                              nonBonusEligibileMeasureCategory["category"] === bonusEle.category ? (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`${bonusEle.category} category has the highest incentive amongst all categories within this application and hence, is eligible to only receive the incentive without a bonus.`}
                                />
                              </div>
                            ) : bonusEle.sumOfSolarPVIncentive > 0 ? (
                              ""
                            ) : bonusEle.bonusEligibilityCES ? (
                              ""
                            ) : (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`This ${bonusEle.category} category is currently not eligible because it does not meet the CES minimum incentive requirements.`}
                                />
                              </div>
                            )}
                          </div>
                          <div className="responsive-table-details">
                            <label>Applicable Bonus Eligibility</label>
                            <span>{`${bonusEle.bonusEligibilityCES ? "Yes" : "No"}`}</span>
                          </div>
                          <div
                            className={`responsive-table-details ${
                              bonusEle.category === "On-Site Generation and Renewables" && bonusEle.sumOfSolarPVIncentive > 0
                                ? "not-eligible"
                                : ""
                            }`}
                          >
                            <label>{"Applicable Eligible Category Incentive ($)"}</label>
                            <span>
                              {!bonusEle["Applicable Eligible Category Incentive"]
                                ? 0
                                : `${utils.formatNumberWithOnlyCommas(bonusEle.finalCESCategoryBonusEligibleSum)}`}
                            </span>
                            {bonusEle.category === "On-Site Generation and Renewables" && bonusEle.sumOfSolarPVIncentive > 0 ? (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`Solar PV measures are not eligible for a bonus within CES.`}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="responsive-table-details">
                            <label>{"Applicable Category Bonus ($)"}</label>
                            <span>
                              {!bonusEle.applicableCategoryCESBonus
                                ? 0
                                : `${utils.formatNumberWithOnlyCommas(bonusEle.applicableCategoryCESBonus)}`}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="summary-header">Total Incentive Bonus</div>
                    <div className="responsive-table-container">
                      <div className="responsive-table-row responsive-table-header">
                        <div className="responsive-table-details">{"Gross Applicable Incentive ($)"}</div>
                        <div className="responsive-table-details">{"Total Applicable Bonus ($)"}</div>
                        <div className="responsive-table-details">{"Total Applicable Incentive ($)"}</div>
                      </div>
                      <div className={`responsive-table-row`}>
                        <div className="responsive-table-details">
                          <label>{"Gross Applicable Incentive ($)"}</label>
                          <span>
                            {!applicationObj["Post-Project Gross Applicable Incentive"]
                              ? 0
                              : `${utils.formatNumberWithOnlyCommas(applicationObj["Post-Project Gross Applicable Incentive"])}`}
                          </span>
                        </div>
                        <div className="responsive-table-details">
                          <label>{"Total Applicable Bonus ($)"}</label>
                          <span>
                            {!applicationObj["Post-Project Applicable CES Bonus"]
                              ? 0
                              : `${utils.formatNumberWithOnlyCommas(applicationObj["Post-Project Applicable CES Bonus"])}`}
                          </span>
                        </div>
                        <div className="responsive-table-details">
                          <label>{"Total Applicable Incentive ($)"}</label>
                          <span>{`${utils.formatNumberWithOnlyCommas(applicationObj["Post-Project Applicable Incentive"])}`}</span>
                          {applicationObj["Post-Project Cap Notice"] ? (
                            <div className="info-icon custom-info-height">
                              <TooltipInfo dynamicPosition={true} helpText={applicationObj["Post-Project Cap Notice"]} />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : draftApproved || appSubmitted ? (
                  <>
                    <div className="summary-header">Incentive Bonus</div>
                    <div className="responsive-table-container">
                      <div className="responsive-table-row responsive-table-header">
                        <div className="responsive-table-details">Category Name</div>
                        <div className="responsive-table-details">{"Pre-Approved Category Incentive ($)"}</div>
                        <div className="responsive-table-details">{"Pre-Approved Bonus Eligibility"}</div>
                        <div className="responsive-table-details">{"Pre-Approved Eligible Category Incentive ($)"}</div>
                        <div className="responsive-table-details">{"Pre-Approved Category Bonus ($)"}</div>
                      </div>
                      {Object.values(preApprovalCategoryBonus).map((bonusEle, index) => (
                        <div className={`responsive-table-row`} key={index}>
                          <div className="responsive-table-details">
                            <label>Category Name</label>
                            <span>{bonusEle.category}</span>
                          </div>
                          <div
                            className={`responsive-table-details ${
                              bonusEle.isOnlyPaidOutIncentive
                                ? "not-eligible"
                                : !utils.isNullOrEmpty(nonBonusEligibileMeasureCategory) &&
                                  nonBonusEligibileMeasureCategory["category"] === bonusEle.category
                                ? "not-eligible"
                                : bonusEle.sumOfSolarPVIncentive > 0
                                ? ""
                                : bonusEle["Pre-Approval Bonus Eligibility"]
                                ? bonusEle["Pre-Approval Eligible Category Incentive"] === 0 &&
                                  bonusEle["Pre-Approval Category Incentive"] > 0 &&
                                  bonusEle.sumOfSolarPVIncentive === 0
                                  ? "not-eligible"
                                  : ""
                                : "not-eligible"
                            }`}
                          >
                            <label>{"Pre-Approved Category Incentive ($)"}</label>
                            <span>
                              {!bonusEle["Pre-Approval Category Incentive"]
                                ? 0
                                : `${utils.formatNumberWithOnlyCommas(bonusEle["Pre-Approval Category Incentive"])}`}
                            </span>
                            {bonusEle.isOnlyPaidOutIncentive ? (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`The measures within this category are all paid out via ESB Program and are not eligible for an Incentive or Bonus.`}
                                />
                              </div>
                            ) : !utils.isNullOrEmpty(nonBonusEligibileMeasureCategory) &&
                              nonBonusEligibileMeasureCategory["category"] === bonusEle.category ? (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`${bonusEle.category} category has the highest incentive amongst all categories within this application and hence, is eligible to only receive the incentive without a bonus.`}
                                />
                              </div>
                            ) : bonusEle.sumOfSolarPVIncentive > 0 ? (
                              ""
                            ) : bonusEle["Pre-Approval Bonus Eligibility"] ? (
                              ""
                            ) : (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`This ${bonusEle.category} category is currently not eligible because it does not meet the CES minimum incentive requirements.`}
                                />
                              </div>
                            )}
                          </div>
                          <div className="responsive-table-details">
                            <label>Pre-Approved Bonus Eligibility</label>
                            <span>{`${bonusEle["Pre-Approval Bonus Eligibility"] ? "Yes" : "No"}`}</span>
                          </div>
                          <div
                            className={`responsive-table-details ${
                              bonusEle.category === "On-Site Generation and Renewables" && bonusEle.sumOfSolarPVIncentive > 0
                                ? "not-eligible"
                                : ""
                            }`}
                          >
                            <label>{"Pre-Approved Eligible Category Incentive ($)"}</label>
                            <span>
                              {!bonusEle["Pre-Approval Eligible Category Incentive"]
                                ? 0
                                : `${utils.formatNumberWithOnlyCommas(bonusEle["Pre-Approval Eligible Category Incentive"])}`}
                            </span>
                            {bonusEle.category === "On-Site Generation and Renewables" && bonusEle.sumOfSolarPVIncentive > 0 ? (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`Solar PV measures are not eligible for a bonus within CES.`}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="responsive-table-details">
                            <label>{"Pre-Approved Category Bonus ($)"}</label>
                            <span>
                              {!bonusEle["Pre-Approval Category Bonus"]
                                ? 0
                                : `${utils.formatNumberWithOnlyCommas(bonusEle["Pre-Approval Category Bonus"])}`}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="summary-header">Total Incentive Bonus</div>
                    <div className="responsive-table-container">
                      <div className="responsive-table-row responsive-table-header">
                        <div className="responsive-table-details">{"Gross Pre-Approved Incentive ($)"}</div>
                        <div className="responsive-table-details">{"Total Pre-Approved Bonus ($)"}</div>
                        <div className="responsive-table-details">{"Total Pre-Approved Incentive ($)"}</div>
                      </div>
                      <div className={`responsive-table-row`}>
                        <div className="responsive-table-details">
                          <label>{"Gross Pre-Approved Incentive ($)"}</label>
                          <span>
                            {!applicationObj["Gross Pre-Approved Incentive"]
                              ? 0
                              : `${utils.formatNumberWithOnlyCommas(applicationObj["Gross Pre-Approved Incentive"])}`}
                          </span>
                        </div>
                        <div className="responsive-table-details">
                          <label>{"Total Pre-Approved Bonus ($)"}</label>
                          <span>
                            {!applicationObj["Total Pre-Approved Bonus"]
                              ? 0
                              : `${utils.formatNumberWithOnlyCommas(applicationObj["Total Pre-Approved Bonus"])}`}
                          </span>
                        </div>
                        <div className="responsive-table-details">
                          <label>{"Total Pre-Approved Incentive ($)"}</label>
                          <span>
                            {`${utils.formatNumberWithOnlyCommas(applicationObj["Reviewer Estimated Incentive"])}`}
                            {applicationObj["Pre-Project Approved Cap Notice"] ? (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo dynamicPosition={true} helpText={applicationObj["Pre-Project Approved Cap Notice"]} />
                              </div>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="summary-header">Incentive Bonus</div>
                    <div className="responsive-table-container">
                      <div className="responsive-table-row responsive-table-header">
                        <div className="responsive-table-details">Category Name</div>
                        {/* <div className="responsive-table-details">No. of Measures in Category</div> */}
                        <div className="responsive-table-details">Category Incentive</div>
                        <div className="responsive-table-details">Bonus Eligibility</div>
                        <div className="responsive-table-details">Eligible Category Incentive</div>
                        <div className="responsive-table-details">Category Bonus</div>
                      </div>
                      {Object.values(applicationObj["Estimated Category CES Bonus"]).map((bonusEle, index) => (
                        <div className={`responsive-table-row`} key={index}>
                          <div className="responsive-table-details">
                            <label>Category Name</label>
                            <span>{bonusEle.category}</span>
                          </div>
                          {/* <div className="responsive-table-details">
                              <label>No. of Measures in Category</label>
                              <span>{bonusEle.measureCount}</span>
                            </div> */}
                          <div className={`responsive-table-details`}>
                            <label>Category Incentive</label>
                            <span>{utils.formatAsCAD(bonusEle.categoryIncentiveCES)}</span>
                            {bonusEle.isOnlyPaidOutIncentive ? (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`The measures within this category are all paid out via ESB Program and are not eligible for an Incentive or Bonus.`}
                                />
                              </div>
                            ) : !utils.isNullOrEmpty(nonBonusEligibileMeasureCategory) &&
                              nonBonusEligibileMeasureCategory["category"] === bonusEle.category ? (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`${bonusEle.category} category has the highest incentive amongst all categories within this application and hence, is eligible to only receive the incentive without a bonus.`}
                                />
                              </div>
                            ) : bonusEle.sumOfSolarPVIncentive > 0 ? (
                              ""
                            ) : bonusEle.bonusEligibilityCES ? (
                              ""
                            ) : (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`This ${bonusEle.category} category is currently not eligible because it does not meet the CES minimum incentive requirements.`}
                                />
                              </div>
                            )}
                          </div>
                          <div className="responsive-table-details">
                            <label>Bonus Eligibility</label>
                            <span>{bonusEle.bonusEligibilityCES ? "Yes" : "No"}</span>
                          </div>
                          <div className="responsive-table-details">
                            <label>Bonus Eligible Category Incentive</label>
                            <span>{utils.formatAsCAD(bonusEle.finalCESCategoryBonusEligibleSum)}</span>
                            {bonusEle.category === "On-Site Generation and Renewables" && bonusEle.sumOfSolarPVIncentive > 0 ? (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`Solar PV measures are not eligible for a bonus within CES.`}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="responsive-table-details">
                            <label>Category Bonus</label>
                            <span>{utils.formatAsCAD(bonusEle.estimatedCategoryCESBonus)}</span>
                          </div>
                        </div>
                      ))}
                      <div className="summary-items-row for-small-screens">
                        <div className="summary-item-details">
                          <label>Gross Estimated Incentive</label>
                          <span>{utils.formatAsCAD(applicationObj["Gross Estimated Incentive"])}</span>
                        </div>
                        <div className="summary-item-details">
                          <label>Total Bonus</label>
                          <span>{utils.formatAsCAD(applicationObj["Estimated CES Bonus"])}</span>
                        </div>
                        <div className="summary-item-details">
                          <label>Total Estimated Incentive</label>
                          <span>{utils.formatAsCAD(applicationObj["Estimated Incentive"])}</span>
                        </div>
                      </div>
                      <div className="bonus-totals">
                        <div className="bonus-row">
                          <div className="row-cell row-head">Gross Estimated Incentive</div>
                          <div className="row-cell">{utils.formatAsCAD(applicationObj["Gross Estimated Incentive"])}</div>
                        </div>
                        <div className="bonus-row">
                          <div className="row-cell row-head">Total Bonus</div>
                          <div className="row-cell">{utils.formatAsCAD(applicationObj["Estimated CES Bonus"])}</div>
                        </div>
                        <div className="bonus-row">
                          <div className="row-cell row-head">Total Estimated Incentive</div>
                          <div className="row-cell">
                            {utils.formatAsCAD(applicationObj["Estimated Incentive"])}
                            {applicationObj["Pre-Project Cap Notice"] ? (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo dynamicPosition={true} helpText={applicationObj["Pre-Project Cap Notice"]} />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              ) : null}
            </>
          ) : (
            <div className="empty-summary-container">There are no measures added to this application</div>
          )}
        </div>
        <div className="horizontal-bar"></div>
        <div className="form-inner-section">
          <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Documents Summary" }}></h2>
          {documentList.length ? (
            <ResponsiveTable headings={documentHeadings} rows={documentRows} />
          ) : (
            <div className="empty-summary-container">There are no documents uploaded currently.</div>
          )}
        </div>
        <div className="horizontal-bar"></div>
        {applicationObj && showSummaries ? (
          <div className="form-inner-section">
            <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Project Summary" }}></h2>
            <div className="form-inner-elements">
              <div className="application-form-label">Contractor Name</div>
              <div className="application-form-element">{applicationObj.contractor ? applicationObj.contractor.label : ""}</div>
            </div>
            <div className="form-inner-elements">
              <div className="application-form-label">Application Name</div>
              <div className="application-form-element">{applicationObj.projectName || `ESB-${applicationObj.appNo}`}</div>
            </div>
            <div className="form-inner-elements">
              <div className="application-form-label">Estimated Project Start Date</div>
              <div className="application-form-element">{utils.formatAmericanDate(applicationObj.projectStartDate)}</div>
            </div>
            <div className="form-inner-elements">
              <div className="application-form-label">Estimated Date of Project Completion</div>
              <div className="application-form-element">{utils.formatAmericanDate(applicationObj.projectCompletionDate)}</div>
            </div>
            {applicationObj.stage === "Post-Project" || applicationObj.stage === "Payment" ? (
              <div className="form-inner-elements">
                <div className="application-form-label">Actual Project Completion Date</div>
                <div className="application-form-element">{utils.formatAmericanDate(applicationObj.actualProjectEndDate)}</div>
              </div>
            ) : (
              ""
            )}
            {draftApproved || appSubmitted ? (
              <div className="form-inner-elements">
                <div className="application-form-label">Project Completion Deadline</div>
                <div className="application-form-element">{applicationObj["Project Completion Deadline"]}</div>
              </div>
            ) : (
              ""
            )}
            <div className="form-inner-elements">
              <div className="application-form-label">
                Is the project receiving other funding, financial incentives, grants or subsidies?
              </div>
              <div className="application-form-element">{applicationObj.isReceivingOtherFunding}</div>
            </div>
            {applicationObj.isReceivingOtherFunding.toLowerCase() === "yes" ? (
              <React.Fragment>
                <div className="form-inner-elements">
                  <div className="application-form-label">Sources of all other funding, financial incentives, grants and subsidies</div>
                  <div className="application-form-element">{applicationObj.sourcesOfAllOtherFunding}</div>
                </div>
                <div className="form-inner-elements">
                  <div className="application-form-label">
                    Total amount of all other funding, financial incentives, grants and subsidies
                  </div>
                  <div className="application-form-element">
                    {utils.formatAsCAD(applicationObj.totalAmountOfAllOtherFunding ? applicationObj.totalAmountOfAllOtherFunding : "0.0")}
                  </div>
                </div>
              </React.Fragment>
            ) : (
              ""
            )}
            <div className="form-inner-elements">
              <div className="application-form-label">Amount of eligible expenses incurred Prior to Application Submission</div>
              <div className="application-form-element">
                {utils.formatAsCAD(applicationObj.amountOfEligibleExpenses ? applicationObj.amountOfEligibleExpenses : "0.0")}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {payeeDetails && applicationObj.payeeAssigned === true && showSummaries ? (
          <React.Fragment>
            <div className="horizontal-bar"></div>
            <div className="form-inner-section">
              <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Payee Summary" }}></h2>
              <div className="summary-list-container">
                <div className="company-details payee-block">
                  <div className="form-inner-elements">
                    <div className="application-form-label">Payee Full Legal Name</div>
                    <div className="application-form-element">{payeeDetails.companyDetails.name}</div>
                  </div>
                  <div className="form-inner-elements">
                    <div className="application-form-label">Payee Address</div>
                    <div className="application-form-element">{`${payeeDetails.companyDetails.address1}, ${
                      payeeDetails.companyDetails.address2 ? payeeDetails.companyDetails.address2 + ", " : ""
                    }${payeeDetails.companyDetails.city}, ${payeeDetails.companyDetails.province} - ${
                      payeeDetails.companyDetails.postalCode
                    }`}</div>
                  </div>
                  <div className="form-inner-elements">
                    <div className="application-form-label">Payee Contact Name</div>
                    <div className="application-form-element">
                      {payeeDetails.firstName} {payeeDetails.lastName}
                    </div>
                  </div>
                  <div className="form-inner-elements">
                    <div className="application-form-label">Phone</div>
                    <div className="application-form-element">{utils.formatPhoneNumber(payeeDetails.phoneNum)}</div>
                  </div>
                </div>
              </div>
              <div className="horizontal-bar"></div>
            </div>
          </React.Fragment>
        ) : (
          ""
        )}
        {this.props.activeChangeReqLogs.length ? (
          <div className="form-inner-section">
            <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Change Requests" }}></h2>
            <ChangeReqLogs activeChangeReqLogs={this.props.activeChangeReqLogs} />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  sumIt = (list, key) => {
    let ans = 0.0;
    for (let item of list) {
      ans += parseFloat(item[key]);
    }
    return ans;
  };

  goToAccount = () => {
    this.props.history.push("/account");
  };

  saveAndExit = () => {
    const { slideIndex, slideTotal, applicationObj, userInfo, editing, applicationId } = this.state;
    const newSlideIndex = slideIndex + 1;
    if (!editing && slideIndex === 0) {
      applicationObj.companyName = userInfo.companyDetails.name;
      if (userInfo.accountCategory === "customer") {
        applicationObj.customerId = userInfo.uid;
      }
      applicationService.createApplication(applicationObj).then((app) => {
        this.setState({
          applicationId: app.id,
        });
        this.goToAccount();
      });
    } else {
      if (this.verifyForm(applicationObj)) {
        if (newSlideIndex === slideTotal) {
          this.props.history.push("/applicationforreview");
        } else {
          delete applicationObj.createdAt;
          this.props.updateApplication(applicationId, applicationObj);
          this.goToAccount();
        }
      }
    }
  };

  nextStep = () => {
    const { slideIndex, slideTotal, applicationObj, userInfo, editing, applicationId } = this.state;
    const isContractor = userInfo ? userInfo.accountCategory.toLowerCase() === "contractor" : false;
    const finalStageForContractor = slideIndex > 3 && isContractor;
    if (finalStageForContractor) {
      //TODO: Save the data and trigger email to Customer from Contractor
      //TODO: Trigger Email and take to shared view
      this.props.history.push(`/applicationshared/${applicationId}`);
      return;
    }
    const newSlideIndex = slideIndex + 1;
    if (!editing && slideIndex === 0) {
      applicationObj.companyName = userInfo.companyDetails.name;
      if (userInfo.accountCategory === "customer") {
        applicationObj.customerId = userInfo.uid;
      }
      applicationService.createApplication(applicationObj).then((app) => {
        this.setState({
          applicationId: app.id,
          slideIndex: newSlideIndex,
        });
      });
    } else {
      if (this.verifyForm(applicationObj)) {
        if (newSlideIndex === slideTotal) {
          this.setState({
            submitForReviewModalOpen: true,
          });
        } else {
          delete applicationObj.createdAt;
          this.props.updateApplication(applicationId, applicationObj);
          this.setState({ slideIndex: newSlideIndex });
        }
      }
    }
  };

  toggleSubmitAppModal = (modalOpen) => {
    this.setState({ submitAppModalOpen: modalOpen });
  };

  toggleAddNoteModal = (modalOpen) => {
    let updateObj = {
      addNoteModalOpen: modalOpen,
    };
    if (modalOpen) {
      updateObj.appNoteSuccessfullySubmitted = false;
      updateObj.appNoteSubmitting = false;
      updateObj.appNote = "";
      updateObj.confirmAppNote = false;
    }
    this.setState(updateObj);
  };

  verifyForm = (applicationObj) => {
    let errors = {};
    let errorCount = 0;
    let { slideIndex } = this.state;
    if (slideIndex === 0) {
      if (utils.isNullOrEmpty(applicationObj.contractor.value)) {
        errors.contractor = "Contractor is required.";
        errorCount++;
      }
      if (utils.isNullOrEmpty(applicationObj.contractorCanEdit)) {
        errors.contractorCanEdit = "Contractor Access Option is required.";
        errorCount++;
      }
    } else if (slideIndex === 1) {
      if (utils.isNullOrEmpty(applicationObj.projectName)) {
        errors.projectName = "Application (or) Project Name is required.";
        errorCount++;
      }
      if (utils.isNullOrEmpty(applicationObj.projectStartDate)) {
        errors.projectStartDate = "Estimated Project Start Date is required.";
        errorCount++;
      }
      if (utils.isNullOrEmpty(applicationObj.projectCompletionDate)) {
        errors.projectCompletionDate = "Estimated Project Completion Date is required.";
        errorCount++;
      }
      if (utils.isNullOrEmpty(applicationObj.isReceivingOtherFunding)) {
        errors.isReceivingOtherFunding = "Please select an option.";
        errorCount++;
      }
      if (utils.isNullOrEmpty(applicationObj.amountOfEligibleExpenses)) {
        errors.amountOfEligibleExpenses = "Please enter amount.";
        errorCount++;
      }
    }
    if (errorCount > 0) {
      this.setState({ errors }, () => {
        let allErrorRefs = document.querySelectorAll(".input-box-error");
        if (allErrorRefs.length > 0) {
          let firstErrorRef = allErrorRefs[0];
          firstErrorRef.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
      });
    }
    return errorCount === 0;
  };

  handleAppNoteChange = (name, value) => {
    this.setState({
      appNote: value,
    });
  };
  showAppPaymentPin = () => {};
  render() {
    const {
      applicationObj,
      editing,
      dataLoaded,
      submitAppModalOpen,
      addNoteModalOpen,
      confirmingAppNote,
      appNote,
      appNoteSuccessfullySubmitted,
      appNoteSubmitting,
      unauthorisedAccess,
    } = this.state;
    if (unauthorisedAccess) {
      return <Redirect to="/error404" />;
    }
    const { applicationProcessing } = this.props;
    let draftApproved = applicationObj.status === "Application Draft Approved" && applicationObj.stage === "Pre-Approval";
    let title =
      applicationObj.status === "Application Information Requested"
        ? "Information Requested"
        : applicationObj.status === "Application Draft Approved"
        ? "Pre-Approval Notice"
        : "Application Rejected";
    let message = "";
    if (applicationObj.status === "Application Information Requested") {
      message =
        "Your pre-project application has been reviewed by the ESB Program team and the following additional clarifications/information regarding your application has been requested:";
    } else if (applicationObj.status === "Application Rejected") {
      message =
        "Your pre-project application has been reviewed by the ESB Program team and it has been rejected. The reason your application has been rejected is as follows:";
    } else if (applicationObj.status === "Application Draft Approved") {
      message =
        "Your pre-project application has been approved by the Energy Savings for Business Program. Please review the Application Summary and complete the Participant Acceptance below.";
    }
    let maxVal = 250000.0;
    if (applicationObj && applicationObj["Reviewer Estimated Incentive"]) {
      maxVal = parseFloat(applicationObj["Reviewer Estimated Incentive"]);
      maxVal = parseFloat((maxVal * 1.1).toFixed(2)); // Set to 110% of original
    }
    return (
      <InternalLayout history={this.props.history}>
        <div className="view-application-container">
          <button
            onClick={() => {
              this.props.history.length > 2 ? this.props.history.goBack() : this.props.history.push("/");
            }}
            className="back-button"
          >
            <img src={LeftArrow} alt="arrow" />
            Back
          </button>
          {dataLoaded ? (
            <div className="section-header">
              <h2 className="section-title">{`Application ID: ${applicationObj.stream === "ces" ? "CES" : "ESB"}-${
                applicationObj.appNo
              }`}</h2>
            </div>
          ) : (
            ""
          )}
          <Modal HeaderText={"Confirmation"} IsOpen={submitAppModalOpen} handleCloseModal={() => this.toggleSubmitAppModal(false)}>
            <p>You are about to submit the application. Are you sure?</p>
            <div className="button-set">
              <Button
                onClick={() => {
                  this.finallySubmitApplication();
                }}
                className="inversed-btn"
                title={"Yes"}
                uppercase
              />
              <Button title={"No"} uppercase onClick={() => this.toggleSubmitAppModal(false)} />
            </div>
          </Modal>
          <Modal
            HeaderText={"Add Note"}
            IsOpen={addNoteModalOpen}
            loading={appNoteSubmitting}
            handleCloseModal={() => this.toggleAddNoteModal(false)}
          >
            {appNoteSuccessfullySubmitted ? (
              <p>Note submitted successfully!</p>
            ) : (
              <React.Fragment>
                <p>Add a note in response to the information requested.</p>
                <Input disabled={confirmingAppNote} type={"richtext"} value={appNote} onChange={this.handleAppNoteChange} name="appNote" />
                {confirmingAppNote ? (
                  <Button
                    className="signup-btn"
                    title={"Submit Note"}
                    uppercase
                    onClick={this.addNoteToApp}
                    disabled={!utils.cleanHTMLText(appNote)}
                  />
                ) : (
                  <Button
                    className="signup-btn"
                    title={"Confirm Note"}
                    uppercase
                    onClick={this.confirmAppNote}
                    disabled={!utils.cleanHTMLText(appNote)}
                  />
                )}
                {confirmingAppNote ? (
                  <Button className="inversed-btn" title={"EDIT"} uppercase onClick={this.confirmAppNote} />
                ) : (
                  <Button className="inversed-btn" title={"CANCEL"} uppercase onClick={() => this.toggleAddNoteModal(false)} />
                )}
              </React.Fragment>
            )}
          </Modal>
          {dataLoaded && !applicationProcessing ? (
            <React.Fragment>
              <div className="container-box">{this.applicationSummary()}</div>
            </React.Fragment>
          ) : (
            <Loader />
          )}
        </div>
      </InternalLayout>
    );
  }
}

function mapState(state) {
  const {
    facilityConfig,
    facilityList,
    loadingFacilityConfig,
    loadingActiveContractors,
    activeContractors,
    loadingActiveContractorsError,
    applicationProcessing,
    applicationCreated,
    applicationId,
    measureCategories,
    measureCategoriesLoading,
    measureTypesLoading,
    measureTypes,
    measures,
    measuresLoading,
    measureConfig,
    measureConfigLoading,
    measureList,
    measureCategory,
    measureType,
    measureCode,
    measureDrawerConfig,
    documentList,
    editingMeasureId,
    activeChangeReqLogs,
  } = state.application;
  return {
    facilityConfig,
    facilityList,
    loadingFacilityConfig,
    loadingActiveContractors,
    activeContractors,
    loadingActiveContractorsError,
    applicationProcessing,
    applicationCreated,
    applicationId,
    measureCategories,
    measureCategoriesLoading,
    measureTypesLoading,
    measureTypes,
    measures,
    measuresLoading,
    measureConfig,
    measureConfigLoading,
    measureList,
    measureCategory,
    measureType,
    measureCode,
    measureDrawerConfig,
    documentList,
    editingMeasureId,
    activeChangeReqLogs,
  };
}

const actionCreators = {
  loadFacilityConfig: appActions.getFacilitiesConfig,
  loadContractors: appActions.getActiveContractors,
  loadUserDetails: userActions.getUserDetails,
  loadConfig: userActions.getAdminConfig,
  loadFacilities: appActions.getFacilitiesForCustomer,
  updateUserDetails: userActions.updateUserDetails,
  createFacility: appActions.createFacility,
  createApplication: appActions.createApplication,
  updateApplication: appActions.updateApplication,
  setMeasureConfig: appActions.setMeasureConfig,
  createAppMeasure: appActions.createAppMeasure,
  updateAppMeasure: appActions.updateAppMeasure,
  loadAppMeasures: appActions.getAppMeasures,
  deleteAppMeasure: appActions.deleteAppMeasure,
  logout: userActions.logout,
  setMeasureCategory: appActions.setMeasureCategory,
  setMeasureType: appActions.setMeasureType,
  setMeasure: appActions.setMeasure,
  clearMeasureDrawer: appActions.clearMeasureDrawer,
  createDocument: appActions.createAppDocument,
  updateDocument: appActions.updateAppDocument,
  deleteDocument: appActions.deleteAppDocument,
  getAppDocuments: appActions.getAppDocuments,
  getChangeReqLogs: appActions.getApplicationsChangeRequestLogs,
};

export default connect(mapState, actionCreators)(ViewAppDetails);

{
  /* <div className="container-box payment-pin">
<div className="form-sections application-summary">
  <div className="step-header-container">
    <div className="step-header-container-left">
      <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Payment PIN" }}></h2>
    </div>
  </div>
  <div className="form-inner-section">
    <div className="info">
      <p> Your application has been Successfully Processed by the ESB Program team </p>
      <p>Please note this Payment Pin and follow the instructions sent to your mail to complete the payment procedure.</p>
    </div>
    <div className="view-pin">
      <div className="label">View Payment Pin</div>
      <div className="action">
      
        <ViewIcon className="view-eye-icon" width="24" height="15" title="View"  onClick={() => this.setShowAppPaymentPinModalOpen(true)} />

      </div>
    </div>
  </div>
</div>
</div> */
}
