import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './Accordion.css';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

  const theme = createMuiTheme({
    palette: {
      primary: { 
          main : '#048041'
      },
      secondary : {
        main : '#048041'
      },
      text:{
          secondary: '#535765'
      }
    }
  });


const useStyles = makeStyles({
  root: {
    width: '100%'
  },
});

export default function CustomAccordion(props) {
  const {items} = props;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <ThemeProvider theme={theme}>
    <div className={`${classes.root} accordion-list-container`}>
     {items.map((item, index)=>
        <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
        <AccordionSummary
            className={`accordion-summary`}
            expandIcon={item.extraInfo? <ExpandMoreIcon className="checkbox-arrow"/> : null}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            id="additional-actions1-header"
        >
          <p>{item.title}</p>
        </AccordionSummary>
        {item.extraInfo? 
        <AccordionDetails>
            <Typography color="textSecondary">
            {item.extraInfo}
            </Typography>
        </AccordionDetails> : ''
        }
        </Accordion>
     )}
    </div>
    </ThemeProvider>
  );
}