import React from "react";
import EnervaLogo from '../../../images/EnervaIcon.svg';
import './Footer.css'
import { ReferenceData } from '../../../contentFile'

const FooterComponent = () => {
    return (
        <footer className="frontend-footer">
            <div>
            <img src={EnervaLogo} alt="EnervaLogo" /> {ReferenceData.FooterText}
            </div>
        </footer>
    )
}

export default FooterComponent;