import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import './MatTabs.css';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

  const theme = createMuiTheme({
    palette: {
      primary: { 
          main : '#048041'
      },
      secondary : {
        main : '#048041'
      },
      text:{
          secondary: '#535765'
      }
    }
  });

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function MatTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.options.indexOf(props.value));

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.onChange(props.options[newValue]);
  };

  return ( <ThemeProvider theme={theme}>
    <div className={`mat-tabs-container ${classes.root}${props.className ? ` ${props.className}` : ''}`}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {props.options.map((option,index)=><Tab key={`mat-tab-${index}`} label={option} {...a11yProps(index)} />)}
        </Tabs>
      </AppBar>
      </div></ThemeProvider>
  );
}