import React from "react";
import {
    Link
} from "react-router-dom";
import './Header.css';
import Modal from '../Modal/Modal';
import MailIcon from '../../../images/MailVector.svg';
import PhoneIcon from '../../../images/CallIcon.svg';
import ESBLogo from '../../../images/ESBOnlyLogo.svg';
import CESLogo from '../../../images/CESLogo.svg';
class HeaderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userMenuExpand: false,
            menuOpen: false,
            contactModalOpen:false,
            programModalOpen: false
        }
    }
    
    setMenuOpen = () => {
        this.setState({
            menuOpen: !this.state.menuOpen
        })
    }

    toggleContactModal = (open)=>{
        this.setState({
            contactModalOpen : open
        })
    }
    toggleProgramModal = (open) => {
        this.setState({
            programModalOpen : open
        })
    }

    render(){
    const { menuOpen, contactModalOpen, programModalOpen } = this.state;

    return (
      <React.Fragment>
        <header className="header">
          <Modal HeaderText={"CONTACT US"} IsOpen={contactModalOpen} handleCloseModal={() => this.toggleContactModal(false)}>
            <div>
              <div className="flexHorizontal modal-section">
                <img src={MailIcon} />
                <div>
                  <p>
                    Energy Savings for Business - <a href="mailto:support@esbprogram.ca">support@esbprogram.ca</a>
                  </p>
                  <p>
                    Comprehensive Energy Savings - <a href="mailto:ces@esbprogram.ca">ces@esbprogram.ca</a>
                  </p>
                </div>
              </div>
              <div className="flexHorizontal modal-section">
                <img src={PhoneIcon} />
                <div className="extra">
                  <p>
                    Toll-Free Number - <a href="tel:+18444070025">844-407-0025</a>
                    <br />
                    Local (403) number - <a href="tel:+14037789467">403-778-9467</a>
                    <br />
                    Local (780) number - <a href="tel:+17803068376">780-306-8376</a>
                    <br />
                    Local (587) number - <a href="tel:+15879431688">587-943-1688</a>
                  </p>
                </div>
              </div>
            </div>
          </Modal>
          <Modal HeaderText={"PROGRAM LINKS"} IsOpen={programModalOpen} handleCloseModal={() => this.toggleProgramModal(false)}>
            <div className="programs">
              <a className="program-link" rel="noreferrer" target="_blank" href="https://eralberta.ca/energy-savings-for-business/">
                <img src={ESBLogo} height="60px" />
                <span className="open-program">Energy Savings For Business</span>
              </a>
              <a
                className="program-link"
                rel="noreferrer"
                target="_blank"
                href="https://www.eralberta.ca/energy-savings-for-business/comprehensive-energy-savings/"
              >
                <img src={CESLogo} height="60px" />
                <span className="open-program">Comprehensive Energy Savings</span>
              </a>
            </div>
          </Modal>
          <div className="header-inner-container">
            <nav>
              <Link to="/" className="logo"></Link>
              <div className={`burger-menu`} onClick={this.setMenuOpen}>
                <div className={`burger-icon ${menuOpen ? "open-menu" : "close-menu"}`} />
                <div className={`burger-icon ${menuOpen ? "open-menu" : "close-menu"}`} />
                <div className={`burger-icon ${menuOpen ? "open-menu" : "close-menu"}`} />
              </div>
              <ul className={`nav-ul ${menuOpen ? "menu-expand" : ""}`}>
                <li onClick={() => this.toggleProgramModal(true)} className={`menuItem`}>
                  Programs
                </li>
                <li onClick={() => this.toggleContactModal(true)} className={`menuItem`}>
                  Contact Us
                </li>
              </ul>
            </nav>
          </div>
        </header>
      </React.Fragment>
    );
    }
}

export default (HeaderComponent);