import React from 'react';
import './LegaleseContainer.css';
import FrontendLayout from '../FrontendLayout';
import {utils} from '../../../helpers/utils';
import PDFBox from '../PDFBox/PDFBox.js';
class LegaleseContainer extends React.Component{
  constructor(props){
      super(props);
      this.state = {
      }
    }
  render(){
    const { title, htmlMarkup, updatedAt } = this.props;
    return <FrontendLayout><div className='legalese-container'>
        <h3>{title}</h3>
        <div className={'container-box'} dangerouslySetInnerHTML={{ __html: htmlMarkup }}/>
        {/* <PDFBox url={this.props.pdfUrl || ''}   /> */}
        <p className='update-date'><span>Last Updated : </span>{utils.formatDate(updatedAt)}</p>
    </div>
    </FrontendLayout>
  }
}
     
export default LegaleseContainer;