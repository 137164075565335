import React from 'react';
import './DrawerWithDynamicForm.css';
import Drawer from "@material-ui/core/Drawer";
import Button from '../Button/Button';
import FormElement from './FormElement';
import CloseIcon from '../../../images/CloseIcon.svg';
import { utils } from '../../../helpers/utils';
import Loader from '../Loader/Loader';
class DrawerWithDynamicForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            errors: {},
            calculatingValues : false,
            confirm: false
        }
        this.updateFormData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.formJSON !== this.props.formJSON) {
            this.updateFormData()
        }
    }

    scrollToShowErrors = ()=>{
        let allErrorRefs = document.querySelectorAll('.error-message');
        if(allErrorRefs.length > 0){
            let firstErrorRef = allErrorRefs[0];
            firstErrorRef.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
        }
    }

    // TODO: Come back to this
    // preventNumberInputClickwheelScroll=()=>{
    //     var allNumberInputs = document.querySelectorAll('input[type=number]')
    //     for(let i of allNumberInputs){
    //         i.addEventListener('wheel',function(e){ e.preventDefault();})
    //     }
    // }

    updateFormData = () => {
        let formData = {};
        this.props.formJSON && this.props.formJSON.fields && this.props.formJSON.fields.map(ele => {
            return formData[ele.name] = ele.value || '';
        })
        this.setState({
            formData: this.props.formJSON && this.props.formJSON.data ? this.props.formJSON.data : formData,
            confirm: false,
            errors: {}
        })
    }

    onChange = (name, value) => {
        //console.log('name, value)>>>>>>>>>>>>>>>', name, value)
        const { formData, errors } = this.state;

        if (name === 'facilityAddressLine1') {
            delete errors['facilityAddressLine1'];
            delete errors['facilityCity'];
            this.setState({
                formData: {
                    ...formData,
                    ['facilityAddressLine1']: value.location_data,
                    ['facilityPostalCode']: value.postalCode,
                    ['facilityCity']: value.city
                },
                errors
            },()=>{
                if(this.props.onChange){
                    this.props.onChange(name, value);
                }
            });
        } else {
            delete errors[name];
            this.setState({
                formData: {
                    ...formData,
                    [name]: value
                },
                errors
            },()=>{
                if(this.props.onChange){
                    this.props.onChange(name, value);
                }
            });
        }

    }

    submitForm = (trueSubmit = false) => {
        const { formData } = this.state;
        if (trueSubmit) {
            this.toggleDrawer();
            this.props.submitForm(this.state.formData);
        } else {
            if (this.verifyForm(formData)) {
                this.setState({
                    confirm: !this.state.confirm,
                    calculatingValues : true,
                },async()=>{
                    if(this.props.doDynamicCalculations){
                        let apiResult = await this.props.doDynamicCalculations(this.state.formData);
                        let updateState = {
                            formData : apiResult.formData,
                            errors : apiResult.errors,
                            calculatingValues : false
                        };
                        if(!utils.isNullOrEmpty(apiResult.errors)){
                            updateState.confirm = false;
                        }
                        this.setState(updateState, ()=>{
                            this.scrollToShowErrors();
                        });
                    } else {
                        this.setState({
                            calculatingValues : false
                        });
                    }
                })
            }
        }
    }

    verifyForm = (formData) => {
        let { fields } = this.props.formJSON;
        let errors = {};
        let errorCount = 0;
        fields.map(ele => {
            if (ele['mandatory']) {
                if (utils.isNullOrEmpty(formData[ele.name])) {
                    errors[ele.name] = `${ele.label} is required.`;
                    errorCount++;
                }
            }
        })
        if (errorCount > 0) {
            this.setState({errors},()=>{
                this.scrollToShowErrors();
            });
            this.setState({ errors });
        }
        return errorCount === 0;
    }

    toggleDrawer = () => {
        this.props.toggleDrawer(!this.props.openDrawer)
        if(this.props.openDrawer){
            if(this.props.onClose){
                this.props.onClose();
            }
        }
    }

    render() {
        const { formData, errors, confirm, calculatingValues } = this.state
        const { formJSON, loading } = this.props;
        return (<div className={`drawer-component ${this.props.classname ? this.props.classname : ''}`}>
            <div>
                <React.Fragment key={"right"}>
                    <Drawer
                        anchor={"right"}
                        open={this.props.openDrawer}
                        onClose={() => {
                            if(this.props.disableClose){
                                return null;
                            } else {
                                this.toggleDrawer()
                            }
                        }}
                        className={this.props.className}
                    >
                        <div className={`drawer-container ${confirm ? 'confirm-container' : ''}`}>
                            {loading ? <Loader/> : <React.Fragment>
                            <img className='close-icon' onClick={this.toggleDrawer} src={CloseIcon} alt='close drawer'/>
                            <div className="drawer-heading">{confirm ? 'CONFIRM' : this.props.formJSON.title}</div>
                            <div className="drawer-form-container">
                                {this.props.formJSON && this.props.formJSON.fields && this.props.formJSON.fields.map((element, i) => (
                                    <div className="drawer-input-container" key={`element-${i}`}>
                                        <FormElement
                                            element={element}
                                            formData={formData}
                                            errors={errors}
                                            onChange={this.onChange}
                                            confirm={confirm}
                                        />
                                    </div>
                                ))}
                            </div>
                            {confirm ?
                                <React.Fragment>
                                    <Button
                                        title={'EDIT DETAILS'}
                                        className='inversed-btn drawer-submit-btn'
                                        uppercase
                                        disabled={this.state.calculatingValues}
                                        onClick={() => { this.submitForm(false); }}
                                    />
                                    <Button
                                        title={(formJSON.finishButton && formJSON.finishButton.title) || 'SAVE'}
                                        className='drawer-submit-btn'
                                        uppercase
                                        disabled={this.state.calculatingValues}
                                        onClick={() => { this.submitForm(true); }}
                                    />
                                </React.Fragment>
                                :
                                <Button
                                    title={formJSON.actionButton && formJSON.actionButton.title}
                                    className='drawer-submit-btn'
                                    uppercase
                                    onClick={() => { this.submitForm(false); }}
                                />
                            }
                            </React.Fragment>}
                        </div>
                    </Drawer>
                </React.Fragment>
            </div>
        </div>
        )
    }
}

export default DrawerWithDynamicForm;