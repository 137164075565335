import React from "react";
import './InternalHeader.css'
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../firebaseAuthContext';
import RegisterLoginButton from '../RegisterLoginButton/RegisterLoginButton';

export default class HeaderComponent extends React.Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.state = {
            userMenuExpand: false,
            menuOpen: false,
            contactModalOpen:false
        }
    }

    setUserMenuExpand = () => {
        this.setState({
            userMenuExpand: !this.state.userMenuExpand
        })
    }

    setMenuOpen = () => {
        this.setState({
            menuOpen: !this.state.menuOpen
        })
    }

    logoutUser = () => {
        this.setMenuOpen();
        this.props.logout()
    }

    render() {
        const { user } = this.context;
        const { userMenuExpand, menuOpen,contactModalOpen} = this.state
        return (
            <header className="internal-header">
                <div className="header-inner-container">
                    <nav>
                        <Link to='/' className="logo">
                        </Link>
                        <RegisterLoginButton history={this.props.history}/>
                </nav>
            </div>
        </header>
    )
    }
}