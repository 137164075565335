import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Popover from '@material-ui/core/Popover';
import './FloatingAppButton.css';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    color: '#FFFFFF',
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#048041',
    '&:hover': {
      opacity:0.7
    },
  }
}));

export default function FloatingAppButton(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const handleClick = (event) => {
    if(props.showButtons){
      setAnchorEl(event.currentTarget);
    } else {
      props.onClick();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return ( 
    <div className={`floating-app-button-container ${classes.root}${props.className ? ` ${props.className}` : ''}`}>
          <Fab aria-label={'Add'} className={clsx(classes.fabGreen)} color='inherit' onClick={handleClick}>
            <AddIcon/>
          </Fab>
          {props.showButtons ? 
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
        <div className="popover-buttons" onClick={handleClose}>
          {props.children}
        </div>
      </Popover> : ''}
      </div>
  );
}