import React from 'react';
import Button from '../../Common/Button/Button';
import Input from '../../Common/Input/Input';
import Modal from '../../Common/Modal/Modal';
import './EditDetails.css';
import { EditDetailsFormData } from '../../../contentFile';
import { connect } from 'react-redux'
import {userActions} from '../../../redux/actions/userActions';
import {userService} from '../../../services/userService';
import {utils} from '../../../helpers/utils';
import firebase from '../../../helpers/Firebase';
import LeftArrow from '../../../images/leftArrow.svg';
import {Link} from 'react-router-dom';
import CheckBox from '../../Common/CheckBox/CheckBox';
import { AuthContext } from '../../../firebaseAuthContext';
import InternalLayout from '../../Common/InternalLayout';
import Loader from '../../Common/Loader/Loader';
import FormStep from '../../Common/FormStep/FormStep';
import Switch from '../../Common/Switch/Switch';
import PDFBox from '../../Common/PDFBox/PDFBox';
import TooltipInfo from'../../Common/TooltipInfo/TooltipInfo'

class EditDetails extends React.Component{
  static contextType = AuthContext;
  constructor(props){
      super(props);
      this.state = {
          accountCategory : "contractor",
          createAccountModalOpen:false,
          contractorDocCodeOfConductURL : '',
          postalCodeEditable : false,
          cancelModalOpen:false,
          slideIndex:0,
          slideTotal:4,
          passwordValid : false,
          dataLoaded:false,
          formSteps:[],
          user:{
            status:'',
            firstName : '',
            lastName : '',
            phoneNum : '',
            workNum : '',
            workPhoneExtension : '',
            email : '',
            accountCategory : '',
            companyName : '',
            companyWebsite: '',
            locationInfo : null,
            address2 : '',
            naicsCode : '',
            roleInCompany:'',
            supplyChainRole:'',
            technologyFocusForCompany:'',
            marketsServedByCompany:'',
            regionsOfOperation:'',
            manualPostalCode : null,
        // isIndigenousOwned: '',
        isOwnedBySomeoneFromURG : '',
            terms: {},
        licenseReqs: {

                meetInsuranceRequirements : false,
                meetWCBRequirements : false,
                registeredForGST : false,
                codeOfConductLicensesAndCertifications : false,
                havePEngOnStaff : false,
                haveCEMOnStaff : false,
                memberOfSESA : false,
                memberOfCanREA : false,
                accreditedInstallerofIGSHPA : false
            }
          },
          errors : {}
      }
  }

  componentDidMount(){
    const { user } = this.context;
    userService.getUserDetails(user.uid, (successful, userDetails)=>{
        if(userDetails.licenseReqs){
        } else {
          userDetails.licenseReqs = {};
        }
        if(userDetails.terms) {}else{
          userDetails.terms = {};
        }
        let userObj = {
          firstName : userDetails.firstName || '',
          // isIndigenousOwned: userDetails.isIndigenousOwned || '',
          isOwnedBySomeoneFromURG: userDetails.isOwnedBySomeoneFromURG || '',
          lastName: userDetails.lastName || '',
          phoneNum: userDetails.phoneNum || '',
          workNum: userDetails.workNum || '',
          workPhoneExtension: userDetails.workPhoneExtension || '',
          email: user.email,
          status: userDetails.status || '',
          accountCategory : userDetails.accountCategory,
          companyName : userDetails.companyDetails.name || '',
          companyWebsite : userDetails.companyDetails.website || '',
          address2 : userDetails.companyDetails.address2 || '',
          location : userDetails.companyDetails.location,
          roleInCompany:userDetails.companyDetails.roleInCompany || '',
          supplyChainRole:userDetails.companyDetails.supplyChainRole || '',
          technologyFocusForCompany:userDetails.companyDetails.technologyFocusForCompany || '',
          marketsServedByCompany:userDetails.companyDetails.marketsServedByCompany || '',
          regionsOfOperation:userDetails.companyDetails.regionsOfOperation || '',
          naicsCode : userDetails.companyDetails.naicsCode || '',
          companyIndustry : userDetails.companyDetails.companyIndustry || '',
          locationInfo : {
            location_data : userDetails.companyDetails.location || null,
            address : userDetails.companyDetails.address1 || '',
            postalCode : userDetails.companyDetails.postalCode || '',
            city : userDetails.companyDetails.city || '',
            province : userDetails.companyDetails.province || ''
          },
          licenseReqs: {
            meetInsuranceRequirements : userDetails.licenseReqs.meetInsuranceRequirements || false,
            meetWCBRequirements : userDetails.licenseReqs.meetWCBRequirements || false,
            registeredForGST : userDetails.licenseReqs.registeredForGST || false,
            codeOfConductLicensesAndCertifications : userDetails.licenseReqs.codeOfConductLicensesAndCertifications || false,
            havePEngOnStaff : userDetails.licenseReqs.havePEngOnStaff || false,
            haveCEMOnStaff : userDetails.licenseReqs.haveCEMOnStaff || false,
            memberOfSESA : userDetails.licenseReqs.memberOfSESA || false,
            memberOfCanREA : userDetails.licenseReqs.memberOfCanREA || false,
            accreditedInstallerofIGSHPA : userDetails.licenseReqs.accreditedInstallerofIGSHPA || false
        },
        terms : {
          agreeToCodeOfConduct:userDetails.terms.agreeToCodeOfConduct || false,
          companyLegalNameNotice:userDetails.terms.companyLegalNameNotice || false,
          caslConsent:userDetails.terms.caslConsent || false,
          useOfContractorInfo:userDetails.terms.useOfContractorInfo || false,
          useOfCustomerInfo:userDetails.terms.useOfCustomerInfo || false,
          programPortalTermsOfUse:userDetails.terms.programPortalTermsOfUse || false,
          contractorInformationIsBusinessInfo : userDetails.terms.contractorInformationIsBusinessInfo || false,
          customerInformationIsBusinessInfo : userDetails.terms.customerInformationIsBusinessInfo || false
        },
        termTimeStamps : userDetails.termTimeStamps || {}
        }

        if(successful){
            let formSteps = ["Account Details", "Company Details"];
            let accountCategory = userDetails.accountCategory.toLowerCase();
            if(accountCategory === "contractor"){
              formSteps = ["Account Details", "Company Details","Code of Conduct", "License Requirements"];
            }
            this.setState({dataLoaded:true, slideTotal : accountCategory === "contractor" ? 4 : 2, user : userObj, formSteps, accountCategory});
        }
    });

    userService.getLegalDoc("contractorCodeOfConduct").then((docs)=>{
      if(docs.length>0){
        this.setState({
          contractorDocCodeOfConductURL:docs[0].pdfUrl
        })
      }
    })

    this.props.loadConfig();
  }

  handleToggleChange = (name, value)=>{
    const { user } = this.state;
    const licenseReqs = user.licenseReqs;
    this.setState({
        user: {
          ...user,
          licenseReqs : {
            ...licenseReqs,
            [name] : value
          }
        }
    });
  }


  onChangeHandler = (name, value) => {
    const { user, errors } = this.state;
    delete errors[name];
    this.setState({
        user: {
            ...user,
            [name]: value
        },
        errors
    });
  }

  onNAICSChange = (name, arg) => {
    const { user,errors } = this.state;
    delete errors['naicsCode'];
    this.setState({
        user: {
            ...user,
            naicsCode: arg,
        },
        errors
    });
  }

  onPasswordChangeHandler = (name, value) => {
    const { user, errors } = this.state;
    delete errors[name];
    let passwordValid = false;
    if(!utils.isNullOrEmpty(value)){
        if(name === 'passwordOne'){
            if(value === user.passwordTwo){
                delete errors.passwordTwo;
            } else {
                errors.passwordTwo= 'Password does not match';
            }
            if(utils.validatePassword(value)){
                passwordValid = true;
            } else {
                errors.passwordOne = 'Password does not meet requirements.';
            }
        } else {
            if(value === user.passwordOne){
                passwordValid = true;
            } else {
                errors.passwordTwo= 'Password does not match';
            }
        }
    }
    this.setState({
        user: {
            ...user,
            [name]: value
        },
        errors,
        passwordValid
    });
  }

  onPhoneChange = (value, c, e) => {
    const { name } = e.target;
    const { user, errors } = this.state;
    delete errors[name];
    this.setState({
        user: {
            ...user,
            [name]: value
        },
        errors
    });
  }

  onCatChange = (cat) => {
    const { user,errors } = this.state;
    delete errors['accountCategory'];
    this.setState({
        user: {
            ...user,
            accountCategory: cat.value
        },
        errors
    });
  }
  // onindeginiousChange = (name,ind) => {
  //   const { user,errors } = this.state;
  //   let isIndigenousOwned = ind.value;
  //   delete errors['isIndigenousOwned'];
  //   this.setState({
  //       user: {
  //           ...user,
  //           isIndigenousOwned,
  //       },
  //       errors
  //   });
  // }

  onBusinessOwnerChange = (name,value) => {
    const { user,errors } = this.state;
    delete errors['isOwnedBySomeoneFromURG'];
    this.setState({
        user: {
            ...user,
            [name]: value,
        },
        errors
    });
  }

  onLocationChange = (locationInfo) => {
    const { user, errors, postalCodeEditable } = this.state;
    delete errors['companyAddress'];
    if(!postalCodeEditable){
      delete errors['manualPostalCode'];
    }
    let obj = {
      user: {
          ...user,
          locationInfo
      },
      errors
    };
    if((!(locationInfo ? true : false) || (locationInfo.postalCode === "")) && !postalCodeEditable){
      obj.user.manualPostalCode = '';
    }
    this.setState(obj);
  }

  verifyForm = (user)=>{
    let errors = {};
    let errorCount = 0;
    let { slideIndex, accountCategory, postalCodeEditable } = this.state;
    console.log('Verify Form Called with Slide Index => ', slideIndex);
    if(slideIndex === 0){
      if(utils.isNullOrEmpty(user.firstName)){
          errors.firstName = 'First Name is required.'
          errorCount++;
      }
      if(utils.isNullOrEmpty(user.lastName)){
          errors.lastName = 'Last Name is required.'
          errorCount++;
      }
      if(utils.isNullOrEmpty(user.companyName)){
          errors.companyName = 'Company Name is required.'
          errorCount++;
      }
      // if(utils.isNullOrEmpty(user.companyWebsite)){
      //   errors.companyWebsite = 'Company website is required.'
      //   errorCount++;
      // }
      if(utils.isNullOrEmpty(user.locationInfo)){
          errors.companyAddress = 'Company Address is required.'
          errorCount++;
      }
      if(utils.isNullOrEmpty(user.accountCategory)){
          errors.accountCategory = 'Please select Account Category.'
          errorCount++;
      }
    //   if(utils.isNullOrEmpty(user.isIndigenousOwned)){
    //     errors.isIndigenousOwned = 'Please select an answer.'
    //     errorCount++;
    // }
    if(utils.isNullOrEmpty(user.isOwnedBySomeoneFromURG)){
      errors.isOwnedBySomeoneFromURG = 'Please select an answer.'
      errorCount++;
  }
      if(!utils.isNullOrEmpty(user.phoneNum) && (user.phoneNum.length > 1 && user.phoneNum.length < 11)){
          errors.phoneNum = 'Business Mobile Number is invalid.'
          errorCount++;
      } else if(utils.isNullOrEmpty(user.phoneNum) || (user.phoneNum.length <= 1)){
          errors.phoneNum = 'Business Mobile Number is required.'
          errorCount++;
      }

      let postalCode = '';
      if(!utils.isNullOrEmpty(user.manualPostalCode)){
        postalCode = user.manualPostalCode;
      } 
      else if(!postalCodeEditable){
        postalCode = user.locationInfo ? user.locationInfo.postalCode : '';
      }
  
      if(utils.isNullOrEmpty(postalCode)){
        errors['manualPostalCode'] = 'Postal Code required.';
        errorCount++;
      }
      else if(!utils.validateCAPostalCode(postalCode)){
        errors['manualPostalCode'] = 'Invalid Postal code.';
        errorCount++;
      }

      // if(!utils.isNullOrEmpty(user.workNum) && (user.workNum.length > 1 && user.workNum.length < 11)){
      //   errors.workNum = 'Work number is invalid.'
      //   errorCount++;
      // } else if(utils.isNullOrEmpty(user.workNum) || (user.workNum.length <= 1)){
      //   errors.workNum = 'Work number is required.'
      //   errorCount++;
      // }
      
      if(!utils.isNullOrEmpty(user.email)){
          if(!utils.validateEmail(user.email)){
              errors.email = 'Email is invalid.'
              errorCount++;
          }
      } else {
          errors.email = 'Email is required.'
          errorCount++;
      }
    }
    else if(slideIndex === 1 && accountCategory === "contractor"){
      if(utils.isNullOrEmpty(user.roleInCompany)){
        errors.roleInCompany = 'Role in company is required.'
        errorCount++;
      } else {
        if(utils.isNullOrEmpty(user.roleInCompany.value)){
          errors.roleInCompany = 'Role in company is required.'
          errorCount++;
        }
      }
      if(utils.isNullOrEmpty(user.supplyChainRole)){
        errors.supplyChainRole = 'Role in Supply Chain is required.'
        errorCount++;
      }
      if(utils.isNullOrEmpty(user.technologyFocusForCompany)){
        errors.technologyFocusForCompany = 'Technology Focus for Company is required.'
        errorCount++;
      }
      if(utils.isNullOrEmpty(user.marketsServedByCompany)){
        errors.marketsServedByCompany = 'Markets Served by Company are required.'
        errorCount++;
      }
      if(utils.isNullOrEmpty(user.regionsOfOperation)){
        errors.regionsOfOperation = 'Regions of Operation are required.'
        errorCount++;
      }
    }
    else if(slideIndex === 1 && accountCategory === "customer"){
      if(utils.isNullOrEmpty(user.roleInCompany)){
        errors.roleInCompany = 'Role in company is required.'
        errorCount++;
      } else {
        if(utils.isNullOrEmpty(user.roleInCompany.value)){
          errors.roleInCompany = 'Role in company is required.'
          errorCount++;
        }
      }
      
      if(utils.isNullOrEmpty(user.naicsCode)){
        errors.naicsCode = 'NAICS Code is required.'
        errorCount++;
      }
      if(utils.isNullOrEmpty(user.companyIndustry)){
        errors.companyIndustry = 'Industry of the company is required.'
        errorCount++;
      }
    }
    if(errorCount > 0){
        this.setState({errors},()=>{
          let allErrorRefs = document.querySelectorAll('.input-box-error');
          if(allErrorRefs.length > 0){
            let firstErrorRef = allErrorRefs[0];
            firstErrorRef.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
          }
        });
    }
    return errorCount === 0;
  }

  nextStep = async ()=>{
    const { user, slideIndex, slideTotal } = this.state;
    const action = user.status.toLowerCase() === 'details submission pending' ? 'completing' : 'editing';
    const uid = this.context.user.uid;
    let postalCode = '';
    if(!utils.isNullOrEmpty(user.manualPostalCode)){
      postalCode = utils.formatCAPostalCode(user.manualPostalCode);
    } 
    else{
      postalCode = user.locationInfo ? user.locationInfo.postalCode : '';
    }
    if(this.verifyForm(user)){
      const userInfo = {
        accountCategory : user.accountCategory,
        firstName : user.firstName,
        lastName : user.lastName,
        isOwnedBySomeoneFromURG:user.isOwnedBySomeoneFromURG,
          companyDetails : {
            name : user.companyName,
            website : user.companyWebsite,
            address1 : user.locationInfo.address,
            address2 : user.address2,
            city : user.locationInfo.city,
            province : user.locationInfo.province,
            postalCode : postalCode,
            location : user.locationInfo.location_data,
            roleInCompany:user.roleInCompany,
            supplyChainRole:user.supplyChainRole,
            technologyFocusForCompany:user.technologyFocusForCompany,
            marketsServedByCompany:user.marketsServedByCompany,
            regionsOfOperation:user.regionsOfOperation,
            naicsCode : user.naicsCode,
            companyIndustry:user.companyIndustry
        },
        terms : {
          agreeToCodeOfConduct:user.terms.agreeToCodeOfConduct || false,
          companyLegalNameNotice:user.terms.companyLegalNameNotice || false,
          caslConsent:user.terms.caslConsent || false,
          useOfContractorInfo:user.terms.useOfContractorInfo || false,
          useOfCustomerInfo:user.terms.useOfCustomerInfo || false,
          programPortalTermsOfUse:user.terms.programPortalTermsOfUse || false,
          contractorInformationIsBusinessInfo : user.terms.contractorInformationIsBusinessInfo || false,
          customerInformationIsBusinessInfo : user.terms.customerInformationIsBusinessInfo || false
        },
        licenseReqs: {
          meetInsuranceRequirements : user.licenseReqs.meetInsuranceRequirements || false,
          meetWCBRequirements : user.licenseReqs.meetWCBRequirements || false,
          registeredForGST : user.licenseReqs.registeredForGST || false,
          codeOfConductLicensesAndCertifications : user.licenseReqs.codeOfConductLicensesAndCertifications || false,
          havePEngOnStaff : user.licenseReqs.havePEngOnStaff || false,
          haveCEMOnStaff : user.licenseReqs.haveCEMOnStaff || false,
          memberOfSESA : user.licenseReqs.memberOfSESA || false,
          memberOfCanREA : user.licenseReqs.memberOfCanREA || false,
          accreditedInstallerofIGSHPA : user.licenseReqs.accreditedInstallerofIGSHPA || false
        },
        phoneNum : user.phoneNum,
        workNum : user.workNum,
        workPhoneExtension : user.workPhoneExtension
     }

    await userService.updateUserDetails(uid, userInfo);
    const newSlideIndex = slideIndex + 1;
    if((newSlideIndex) === slideTotal){
      if(action === 'completing'){
        this.props.showSubmitProfileModal(true);
      } else {
        this.props.history.push('/account');
      }
    } else {
      this.setState({slideIndex : newSlideIndex});
    }
  }
  }

  submitForm = ()=>{
    const { user, term1, term2, term3 } = this.state;
    if(this.verifyForm(user)){
        this.props.register({...user, term1, term2, term3});
    }
  }

  toggleSignupModal = (modalOpen)=>{
    this.setState({createAccountModalOpen : modalOpen });
  }

  toggleCancelModal = (modalOpen)=>{
    this.setState({cancelModalOpen : modalOpen });
  }
  
  checkTerm = (term)=>{
      this.setState({[`term${term}`]: !this.state[`term${term}`]});
  }

  handleChangeSlide = (slideIndex)=>{
    console.log(slideIndex);
    this.setState({slideIndex});
  }

  handlePostalCodeEdit = (editable)=>{
    const { user, errors } = this.state;
    let postalCode = user.manualPostalCode;
    if(editable && utils.isNullOrEmpty(user.manualPostalCode)){
      postalCode = user.locationInfo ? user.locationInfo.postalCode : '';
    }
    if(!editable){
      delete errors['manualPostalCode'];
      postalCode = user.locationInfo ? user.locationInfo.postalCode : '';
    }
    this.setState({
      postalCodeEditable : editable,
      user : {
        ...user,
        manualPostalCode : postalCode
      },
      errors
    })
  }

  accountDetailsForm = ()=>{
    const isIndigenousOwnedOptions =  [
      "Yes","No","Prefer not to Answer"
    ];
    const { user, errors, accountCategory, postalCodeEditable } = this.state;
    let postalCode = '';
    if(!utils.isNullOrEmpty(user.manualPostalCode)){
      postalCode = user.manualPostalCode;
    } 
    else if(!postalCodeEditable){
      postalCode = user.locationInfo ? user.locationInfo.postalCode : '';
    }
    return <div>
    <div className="form-sections">
    <div className="form-section">
    <Input
      label={'First Name'}
      name='firstName'
      value={user.firstName}
      error={errors['firstName']}
      onChange={this.onChangeHandler}
      placeholder={'Contact First Name'}
    />
    <Input
      label={'Last Name'}
      name='lastName'
      value={user.lastName}
      error={errors['lastName']}
      onChange={this.onChangeHandler}
      placeholder={'Contact Last Name'}
    />
    <Input
      type='phone'
      label={'Business Mobile Number'}
      name='phoneNum'
      value={user.phoneNum}
      error={errors['phoneNum']}
      onChange={this.onPhoneChange}
      placeholder={''}
    />
    <Input
      type='phone'
      label={'Work Number (Optional)'}
      name='workNum'
      value={user.workNum}
      error={errors['workNum']}
      onChange={this.onPhoneChange}
      placeholder={''}
    />     
   <Input
      type='number'
      label={'Extension (Optional)'}
      name='workPhoneExtension'
      value={user.workPhoneExtension}
      error={errors['workPhoneExtension']}
      className={'shortField'}
      onChange={this.onChangeHandler}
      placeholder={'X X - X X X'}
    />          
    <Input
      label={'Email'}
      type='email'
      name='email'
      value={user.email}
      error={errors['email']}
      placeholder={'Business Email'}
      disabled
    />
  <Input 
      label={'Account Category'}
      type='dropdown'
      name='accountCategory'
      error={errors['accountCategory']}
      onChange={this.onCatChange}
      disabled
      value={accountCategory === 'contractor' ? {value:'contractor', label :'I am a Contractor'} : {value:'customer', label : 'I am a Customer'}}
      options={[{value:'contractor', label :'I am a Contractor'},{value:'customer', label : 'I am a Customer'}]}
  />
  </div>
  <div className='vertical-separator-box'>
      <span className='separator'></span>
  </div>
  <div className="form-section">
    <Input
      label={'Company Name'}
      name='companyName'
      value={user.companyName}
      error={errors['companyName']}
      onChange={this.onChangeHandler}
      placeholder={"Company Legal Name"}
    />

    {/* <Input
      label={'Is the company indigenous owned?'}
      type='dropdown'
      name='isIndigenousOwned'
      error={errors['isIndigenousOwned']}
      onChange={this.onindeginiousChange}
      value={user.isIndigenousOwned ? { value: user.isIndigenousOwned, label: user.isIndigenousOwned } : null}
      options={isIndigenousOwnedOptions.map((item) => {
        return { label: item, value: item };
      })}
    /> */}
    <div className="q-dropdown">
      <Input
        label={'Is your business owned by someone from an under-represented group?'}
        type='dropdown'
        name='isOwnedBySomeoneFromURG'
        error={errors['isOwnedBySomeoneFromURG']}
        onChange={this.onBusinessOwnerChange}
        value={user.isOwnedBySomeoneFromURG ? {value:user.isOwnedBySomeoneFromURG.value, label :user.isOwnedBySomeoneFromURG.label} : null}
        options={isIndigenousOwnedOptions.map((item) => {
          return { label: item, value: item };
        })}
      />
      <span className="info-icon">
        <TooltipInfo
          position={"right"}
          helpText={
            'Such as people of color, persons with disabilities, LGBTQ, Indigenous etc.'
          }
        />
      </span>
    </div>
    <Input
      label={'Company Website'}
      name='companyWebsite'
      value={user.companyWebsite}
      error={errors['companyWebsite']}
      onChange={this.onChangeHandler}
      placeholder={"Company Website"}
    />
    <Input
      type='location'
      name='companyAddress'
      label={'Company Address'}
      error={errors['companyAddress']}
      value={user.location}
      onChange={this.onLocationChange}
      placeholder={'Company HQ Address Line 1'}
    />
    <Input
      label={'Address Line 2'}
      name='address2'
      value={user.address2}
      onChange={this.onChangeHandler}
      placeholder={'Company HQ Address Line 2'}
    />
    <Input
    type='postalCode'
    label={'Postal Code'}
    name='manualPostalCode'
    value={postalCode}
    onChange={this.onChangeHandler}
    placeholder={'Enter Postal Code'}
    error={errors['manualPostalCode']}
    onEdit={this.handlePostalCodeEdit}
    editable={postalCodeEditable}
    disabled
    />
    <Input
      label={'City'}
      value={user.locationInfo ? user.locationInfo.city : ''}
      onChange={this.onChangeHandler}
      placeholder={''}
      disabled
    />
    <Input
      label={'Province'}
      value={user.locationInfo ? user.locationInfo.province : ''}
      onChange={this.onChangeHandler}
      placeholder={''}
      disabled
    />
  </div>
    </div>
    </div>;
  }

  handleCheckAgreement = ()=>{
    const { user } = this.state;
    this.setState({
      user: {
          ...user,
          terms : {
            ...user.terms,
            agreeToCodeOfConduct: !user.terms.agreeToCodeOfConduct
          }
      }
  });
  }

  codeOfConduct = ()=>{
    const { user, contractorDocCodeOfConductURL } = this.state;
    return <div>
      <p className="subtitle">Contractor Code of Conduct</p>
        { contractorDocCodeOfConductURL === '' ? <div className="container-box loading"><Loader/></div> : <PDFBox pdfUrl={contractorDocCodeOfConductURL}/>}
      <div className="agree-to-terms" onClick={()=>this.handleCheckAgreement()}>
        <CheckBox checked={user.terms.agreeToCodeOfConduct}/><p>I have read the above agreement and agree to the terms &amp; conditions.</p>
      </div>
    </div>;
  }

  licenseRequirements = ()=>{
    const {user} = this.state;
    const licenseReqs = user.licenseReqs;
    const techFocus = (user.technologyFocusForCompany || []).map((item)=>item.value);
    const includesCHP = techFocus.includes('CHP');
    const includesSolarPV = techFocus.includes('Solar PV');
    const includesGeothermal = techFocus.includes('Geothermal (GSHP)');
    return <React.Fragment>
      <Switch 
      name='meetInsuranceRequirements'
      onChange={this.handleToggleChange}
      checked={licenseReqs.meetInsuranceRequirements}
      label={`Do you meet the Insurance Requirements specified in the Code of Conduct?`}
      />
      <Switch
      name='meetWCBRequirements' 
      onChange={this.handleToggleChange}
      checked={licenseReqs.meetWCBRequirements}
      label={`Do you meet the WCB Requirements specified in the Code of Conduct?`}
      />
      <Switch
      name='registeredForGST'
      onChange={this.handleToggleChange}
      checked={licenseReqs.registeredForGST}
      label={`Are you registered for GST?`}
      />
      <Switch
      name='codeOfConductLicensesAndCertifications' 
      onChange={this.handleToggleChange}
      checked={licenseReqs.codeOfConductLicensesAndCertifications}
      label={`Do you have the required Licences and Certifications specified in the Code of Conduct?`}
      />
      {includesCHP ? <Switch
      name='havePEngOnStaff'
      onChange={this.handleToggleChange}
      checked={licenseReqs.havePEngOnStaff}
      label={`Do you have a P.Eng on Staff?`}
      /> : ''}
      {includesCHP ? <Switch 
      name='haveCEMOnStaff'
      onChange={this.handleToggleChange}
      checked={licenseReqs.haveCEMOnStaff}
      label={`Do you have a CEM on Staff?`}
      />:''}
      {includesSolarPV ? <Switch 
      name='memberOfSESA'
      onChange={this.handleToggleChange}
      checked={licenseReqs.memberOfSESA}
      label={`Are you a Member of SESA?`}
      /> : ''}
      {includesSolarPV ? <Switch 
      name='memberOfCanREA'
      onChange={this.handleToggleChange}
      checked={licenseReqs.memberOfCanREA}
      label={`Are you a Member of CanREA?`}
      />:''}
      {includesGeothermal ? <Switch 
      name='accreditedInstallerofIGSHPA'
      onChange={this.handleToggleChange}
      checked={licenseReqs.accreditedInstallerofIGSHPA}
      label={`Have your Ground Source Heat Pump installers completed the Accredited Installer course from the International Ground Source Heat Pump Association or equivalent?`}
      />:''}
    </React.Fragment>
  }

  companyDetailsForm = ()=>{
    const { user, errors, accountCategory } = this.state;
    const { configData } = this.props;

    const isContractor = accountCategory === "contractor";

    const roleInCompanyOptions = configData ? (isContractor ? configData.roleInCompanyForContractor.options : configData.roleInCompanyForCustomer.options) : [];

    return configData ? <div className="company-details-form">
    <div className="form-sections">
    <div className="form-section">
    <Input
      label={'Role in Company'}
      type={'radio'}
      options={roleInCompanyOptions}
      name='roleInCompany'
      value={user.roleInCompany}
      error={errors['roleInCompany']}
      onChange={this.onChangeHandler}
      placeholder={'Start Typing'}
    />
    {isContractor ? 
    <React.Fragment>
      <Input
        label={'Role in Supply Chain'}
        type={'multiselect'}
        options={configData.supplyChainRole.options.map((item)=>{
          return {label:item, value:item};
        })}
        name='supplyChainRole'
        value={user.supplyChainRole}
        error={errors['supplyChainRole']}
        onChange={this.onChangeHandler}
        placeholder={'Select all that apply'}
      /> 
      <Input
        label={'Technology Focus for Company'}
        type={'multiselect'}
        options={configData.technologyFocusForCompany.options.map((item)=>{
          return {label:item, value:item};
        })}
        name='technologyFocusForCompany'
        value={user.technologyFocusForCompany}
        error={errors['technologyFocusForCompany']}
        onChange={this.onChangeHandler}
        placeholder={'Select all that apply'}
      />
      <Input
      label={'Markets Served by Company'}
      type={'multiselect'}
      options={configData.marketsServedByCompany.options.map((item)=>{
        return {label:item, value:item};
      })}
      name='marketsServedByCompany'
      value={user.marketsServedByCompany}
      error={errors['marketsServedByCompany']}
      onChange={this.onChangeHandler}
      placeholder={'Select all that apply'}
      />
      <Input
      label={'Regions of Operation'}
      type={'multiselect'}
      options={configData.regionsOfOperation.options.map((item)=>{
        return {label:item, value:item};
      })}
      name='regionsOfOperation'
      value={user.regionsOfOperation}
      error={errors['regionsOfOperation']}
      onChange={this.onChangeHandler}
      placeholder={'Select all that apply'}
      /></React.Fragment> :<React.Fragment>
    <Input 
      label={'NAICs Code'}
      type='dropdown'
      name='naicsCode'
      error={errors['naicsCode']}
      onChange={this.onNAICSChange}
      value={user.naicsCode ? {value:user.naicsCode.value, label :user.naicsCode.label} : null}
      options={configData.naicsCodes.options.map((item)=>{
        return {label:item.display, value:item.code};
      })}
  />
  <Input
      label={'Industry of the Company'}
      type={'multiselect'}
      options={configData.companyIndustry.options.map((item)=>{
        return {label:item, value:item};
      })}
      name='companyIndustry'
      value={user.companyIndustry}
      error={errors['companyIndustry']}
      onChange={this.onChangeHandler}
      placeholder={'Select all that apply'}
    />
    </React.Fragment>}
    </div>
    </div>
    </div> : null;
  }

  handleSubmitProfile = ()=>{
    const {accountCategory} = this.state;
    const { submitProfile } = this.props;
    const isContractor = accountCategory.trim().toLowerCase() === "contractor";
    let termTimeStamps = this.state.user.termTimeStamps;
    termTimeStamps.agreeToCodeOfConduct = firebase.getTimestamp();
    submitProfile(
      this.context.user.uid,{
        termTimeStamps,
        status:isContractor ? "Pending Approval" : "Participating"
      }
    );
  }

  goToAccount = ()=>{
    this.props.history.push('/account');
  }

  componentWillUnmount(){
    this.props.showSubmitSuccessModal(false);
  } 

  render(){
    const { submitProfileModalShown, logout, submittingProfile,submittedProfileSuccessfully } = this.props;
  
  const { user, accountCategory, errors, dataLoaded, slideIndex, slideTotal, cancelModalOpen } = this.state;
  const action = user.status.toLowerCase() === 'details submission pending' ? 'completing' : 'editing';
   const thereAreErrors = !utils.isNullOrEmpty(errors) || (slideIndex === 2 && !user.terms.agreeToCodeOfConduct);
   const buttonText = ((slideIndex + 1) === slideTotal) ? (action === 'completing' ? 'Save and Submit' : 'Save and Finish') : 'Save and Proceed';
   const isContractor = accountCategory.trim().toLowerCase() === "contractor";
  return (
    <InternalLayout history={this.props.history}><div className="edit-details-container">
      <Modal
            HeaderText={'Are you sure?'}
            IsOpen={cancelModalOpen}
            handleCloseModal={()=>this.toggleCancelModal(false)}
        >
        <p>Any usaved changes you have made to the data till will be lost.</p>
        <div className='button-set'>
        <Link onClick={()=>{this.props.history.length > 2 ? this.props.history.goBack() : this.props.history.push('/')}}>
            <Button
            className='inversed-btn'
            title={'Yes, take me back'}
            uppercase
            />
        </Link>
        <Link to='/editdetails'>
            <Button
            title={'Continue Editing'}
            uppercase
            onClick={()=>this.toggleCancelModal(false)}
            />
        </Link>
        </div>
      </Modal>
      <Modal
            HeaderText={isContractor ? 'SUBMIT FOR REGISTRATION' : 'SAVE DETAILS?'}
            IsOpen={submitProfileModalShown}
            handleCloseModal={()=>this.toggleSignupModal(false)}
            loading={submittingProfile}
        >
        {
          isContractor ? 
        <React.Fragment>
          <p className='subtitle'>You are about to submit your profile for registration as a contractor.</p>
          <p className='subtitle'>Your profile will be reviewed by the ESB Program team and will have to be approved for you 
          to be able to participate in the Energy Savings for Business Program.</p>
        </React.Fragment>
        :
        <React.Fragment>
          <p className='subtitle'>You are about to complete your registration as a participant of the Energy Savings for Business Program.</p>
        </React.Fragment>
        }
        <p className='subtitle'>Please confirm that the details you have submitted are accurate.</p>
        <div className='button-set'>
            <Button
            className='inversed-btn'
            title={'Go Back & Review Details'}
            uppercase
            onClick={()=>this.props.showSubmitProfileModal(false)}
            />
            <Button
            title={isContractor ? 'Submit Profile' : 'Submit Details'}
            uppercase
            onClick={this.handleSubmitProfile}
            />
        </div>
      </Modal>
      <Modal
            HeaderText={'SUCCESSFULLY SUBMITTED!'}
            IsOpen={submittedProfileSuccessfully}
            handleCloseModal={()=>this.toggleSignupModal(false)}
        >
        {
          isContractor ? 
        <React.Fragment>
          <p className='subtitle'>Your account has been submitted for review to be registered as an <span>Eligible Contractor</span> under Energy Savings for Business program.</p>
          <p className='subtitle'>You will receive an email once the review is complete.</p>
        </React.Fragment>
        :
        <React.Fragment>
          <p className='subtitle'>Your account has been set up for the Energy Savings for Business Program.</p>
        </React.Fragment>
        }
        <div className='button-set'>
          <Button
          className='inversed-btn'
          title={'LOG OUT'}
          uppercase
          onClick={logout}
          />
          <Button
          title={'Account Page'}
          uppercase
          onClick={this.goToAccount}
          />
        </div>
      </Modal>
      <button onClick={()=>this.toggleCancelModal(true)} className="back-button"><img src={LeftArrow} alt='arrow'/>Back</button>
      <div className="section-header">
      {dataLoaded ? <h2 className="section-title" dangerouslySetInnerHTML={{__html: action === 'completing' ? EditDetailsFormData.incompleteTitle : action === 'editing' ? EditDetailsFormData.completeTitle : ''}}></h2> : ''}
      </div>
      <FormStep steps={this.state.formSteps} slideIndex={slideIndex} onChange={this.handleChangeSlide}/>
      <div className="container-box">
        {dataLoaded ? 
        <React.Fragment>
          {slideIndex === 0 ? this.accountDetailsForm():null}
          {slideIndex === 1 ? this.companyDetailsForm():null}
          {slideIndex === 2 ? this.codeOfConduct():null}
          {slideIndex === 3 ? this.licenseRequirements():null}
        </React.Fragment>
       : <Loader/>}
       </div>
      <div className="form-bottom">
      <Button 
                title={buttonText}
                className='save-btn'
                onClick={this.nextStep}
                uppercase
                disabled={thereAreErrors}
          />
      </div>
    </div></InternalLayout>
  );
    
  }
}

function mapState(state) {
    const { configData, submitProfileModalShown, submittingProfile,submittedProfileSuccessfully,submittingProfileError  } = state.authentication;
    return { configData,submitProfileModalShown,submittingProfile,submittedProfileSuccessfully,submittingProfileError };
}

const actionCreators = {
    submitProfile: userActions.submitProfile,
    loadUserDetails : userActions.getUserDetails,
    loadConfig : userActions.getAdminConfig,
    updateUserDetails : userActions.updateUserDetails,
    showSubmitProfileModal : userActions.showSubmitProfileModal,
    showSubmitSuccessModal : userActions.showSubmitSuccessModal,
    logout : userActions.logout,
}

export default connect(mapState, actionCreators)(EditDetails);