import React from 'react';
import Modal from 'react-modal';
import Loader from '../../../images/Loader.svg';
import './Modal.css';
import CloseIcon from '../../../images/CloseIcon.svg';

const ModalComponent = (props) => {

    let maxModalHeight = (window.innerHeight / 100) * 90;

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            margin: 'auto',
            height:props.modalSize === 'fullscreen' ? '100%' : 'auto',
            transform: 'translate(-50%, -50%)',
            width: props.modalSize === 'fullscreen' ? '90%' : '80%',
            maxWidth: props.modalSize === 'large' ? '1024px' : props.modalSize === 'fullscreen' ? '100%' : '660px',
            maxHeight: maxModalHeight,
            zIndex: '2',
            overflow: props.modalSize === 'fullscreen' ? "hidden" : "visible",
        }
    };

    return (
        <div className={`modal-container`}>
            <Modal 
                onAfterOpen={function(){ document.body.style.overflow = 'hidden'; }}
                onAfterClose={ function(){  document.body.style.overflow = 'unset'; }} 
                isOpen={props.IsOpen}
                style={customStyles}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={true}
                onRequestClose={props.handleCloseModal}
            >
                <img className='close-icon' onClick={props.handleCloseModal} src={CloseIcon} alt='close modal'/>
                {props.loading ? '' : props.HeaderText ? <h3 className={`${props.headerClass || ''}`}>{props.HeaderText}</h3> : null}
                {props.loading ? <div className='loading-container'><img src={Loader} alt='loader' /></div> : <div className="modal-content">{props.children}</div>}
            </Modal>
        </div>
    );
}
export default ModalComponent;