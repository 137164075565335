import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './CheckboxList.css';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

  const theme = createMuiTheme({
    palette: {
      primary: { 
          main : '#048041'
      },
      secondary : {
        main : '#048041'
      },
      text:{
          secondary: '#535765'
      }
    }
  });


const useStyles = makeStyles({
  root: {
    width: '100%'
  },
});

export default function CheckboxList(props) {
  const {items} = props;
  const classes = useStyles();

  function handleChange(name, checked){
    props.onChange(name, checked);
  }

  return (
    <ThemeProvider theme={theme}>
    <div className={`${classes.root} checkbox-list-container`}>
     {items.map((item, index)=>
        <Accordion key={`accordion-item-${index}`}>
        <AccordionSummary
            className={`accordion-summary${props.errors && props.errors[item.name] ? ' error-box' : ''}${item.checked ? ' success-box' : ''}`}
            expandIcon={item.extraInfo? <ExpandMoreIcon className="checkbox-arrow"/> : null}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            id="additional-actions1-header"
        >
            <FormControlLabel
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={<Checkbox defaultChecked={false} checked={item.checked ? true : false} className='term-checkbox' onChange={(e)=>{handleChange(item.name,e.target.checked)}} />}
            label={item.title}
            />
        </AccordionSummary>
        {item.extraInfo? 
        <AccordionDetails>
            <Typography color="textSecondary">
            {item.extraInfo}
            </Typography>
        </AccordionDetails> : ''
        }
        </Accordion>
     )}
    </div>
    </ThemeProvider>
  );
}