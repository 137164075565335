import React from 'react';
import {
  Router
} from "react-router-dom";
import Wrapper from './Wrapper'
import history from './services/history'
function App() {
  
  return (
      <Router history={history}>
        <Wrapper/>
      </Router>
  );
}

export default App;
