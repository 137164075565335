import React from "react";
import Tooltip from '@material-ui/core/Tooltip';
import InfoVector from '../../../images/InfoVector.svg';
import { withStyles } from '@material-ui/core/styles';

const HtmlTooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: '#DB1900',
        color: '#FFF',
        maxWidth: 220
    },
    arrow: {
        color: '#DB1900',
    }
}))(Tooltip);

const AlertInfo = (props) => {
    return (
        <HtmlTooltip
            title={<React.Fragment>
                {props.helpText}
            </React.Fragment>}
            placement={props.position || 'right'} arrow>
            <img src={props.customIcon || InfoVector} alt='Info' />
        </HtmlTooltip>
    );
}

export default AlertInfo;