import React from "react";
import './Button.css';

const Button = (props) => {
    return (
        <button
            className={`enerva-btn${(props.disabled || false) ? ' disabled-btn' : ''}${(props.large || false) ? ' large-btn' : ''}${(props.uppercase || false) ? ' uppercase-btn' : ''}${props.className ? ` ${props.className}` : ''}`}
            onClick={props.disabled ? null : props.onClick}>
            {props.title}
        </button>
    );
}

export default Button;