import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { utils } from '../../../helpers/utils';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  container: {
    maxHeight: 440,
  }
});

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function CustomTable(props) {
  const classes = useStyles();
  const {rows} = props;
  return (
    <TableContainer component={Paper} className={classes.container}>
      {rows ? 
      <Table className={classes.table} aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
              {props.headings.map((heading,index)=>{
                  return <TableCell key={`${heading.title}${index}`}>{heading.title}</TableCell>
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              {props.headings.map((heading)=>{
                  let val = '';
                  if(heading.value === 'createdAt'){
                    val = utils.formatLogTime(row[heading.value]);
                  } else {
                    val = row[heading.value];
                  }
                  return <TableCell dangerouslySetInnerHTML={{__html:val}}></TableCell>
              })}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table> : ''}
    </TableContainer>
  );
}