import React from 'react';
import './SearchInput.css';
import { ReactComponent as SearchVector } from '../../../images/SearchVector.svg'

class SearchInput extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return <div className={`search-box ${this.props.classname ? this.props.classname : ''}`}>
            <input
                onChange={(e) => {
                    const { value } = e.target;
                    this.props.onChange(value);
                }}
                className='search-input'
                placeholder={this.props.placeholder}
                type='text'
                value={this.props.value} />
            <button
                className='search-btn'
            // onClick={this.props.disabled ? null : () => this.props.onClick(this.state.InputValue)}
            >
                <SearchVector width="18px" height="20px" />
            </button>
        </div>
    }

}

export default SearchInput;