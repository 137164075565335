import { userActionTypes } from '../actions/userActionTypes';

export function registration(state = { registerModalShown: false, addParticipantModalShown: false }, action) {
  switch (action.type) {
    case userActionTypes.SHOW_REGISTER_MODAL:
      return { registerModalShown: action.show };
    case userActionTypes.SHOW_ADD_PARTICIPANT_MODAL:
      return { addParticipantModalShown: action.show };
    case userActionTypes.REGISTER_REQUEST:
      return { registering: true };
    case userActionTypes.REGISTER_SUCCESS:
      return { registeredSuccessfully: true };
    case userActionTypes.REGISTER_FAILURE:
      return { registrationError: action.error, registering: false };
    case userActionTypes.CHECK_USER_EXISTS:
      return {
        ...state,
        userExists: action.userExists
      };
    default:
      return state
  }
}