import React from "react";
import './OverviewCard.css';
import {utils} from '../../../helpers/utils';
import { Link } from 'react-router-dom';

const OverviewCard = (props) => {
    const { AccountDetails, onClickChangePassword } = props;
    const {companyDetails} = AccountDetails;
    let accountClass = "account-pending";
    let hideEditDetailsButton = false;
    let editdetailsBtnTitle = 'Complete Details';
    switch(AccountDetails.status) {
        case 'Pending':
          // code block
          accountClass = "account-pending";
          break;
        case 'Pending Approval':
          // code block
          editdetailsBtnTitle = 'Edit Details';
          accountClass = "account-pending-approval";
          break;
        case 'Flagged for QA/QC':
            editdetailsBtnTitle = 'Edit Details';
            accountClass = "account-flagged-for-qa";
            break;
        case 'Suspended':
            editdetailsBtnTitle = 'Edit Details';
            accountClass = "account-suspended";
            break;
        case 'Inactive':
            editdetailsBtnTitle = 'Edit Details';
            accountClass = "account-inactive";
            hideEditDetailsButton = true;
            break;
        case 'Participating':
            // code block
            editdetailsBtnTitle = 'Edit Details';
            accountClass = "account-participating";
            break;
        default:
            accountClass = "account-pending";
      }
    return (
        <div className={`overviewcard-container ${accountClass}`}>
            <div className="overviewcard-top">
                <h3>Account Overview</h3>
                <div className="separator"></div>
                <h4>{utils.formatTitle(AccountDetails.accountCategory)}</h4>
            </div>
            <div className="overviewcard-bottom">
                <div className="overview-details overview-details-left">
                    <div>
                        <label>Company Name :</label>
                        <span>{AccountDetails.companyDetails.name}</span>
                    </div>
                    <div>
                        <label>{utils.formatTitle(AccountDetails.accountCategory)} ID :</label>
                        <span>{`${AccountDetails.accountCategory.toLowerCase() === 'contractor' ? 'CON-' : 'CUS-'}${AccountDetails.userNo}`}</span>
                    </div>
                    <div>
                        <label>Contact Name :</label>
                        <span>{AccountDetails.firstName} {AccountDetails.lastName}</span>
                    </div>
                    <div>
                        <label>Phone Number :</label>
                        <span>{utils.formatPhoneNumber(AccountDetails.phoneNum)}</span>
                    </div>
                    <div>
                        <label>Email ID :</label>
                        <span>{AccountDetails.email}</span>
                    </div>
                </div>
                <div className="separator"></div>
                <div className="overview-details overview-details-right">
                    <div>
                        <label>Address :</label>
                        <span>
                            <span>{companyDetails.address1}</span>
                            {companyDetails.address2 ? <span>{companyDetails.address2}</span> : ''}
                            <span>{companyDetails.city}, {companyDetails.province}</span>
                            <span>{companyDetails.postalCode}</span>
                        </span>
                    </div>
                    <div>
                        <div onClick={onClickChangePassword} className="overview-links change-password-link">Change Password</div>
                    </div>
                    {hideEditDetailsButton ? '' : <div>
                        <Link to="/editdetails"><div className="overview-links edit-account-link">{editdetailsBtnTitle}</div></Link>
                    </div>}
                    
                </div>
            </div>
        </div>

    );
}

export default OverviewCard;